export const getMinDistanceByZoom = (zoom: number): number | undefined => {
  let distanceMin
  if (zoom !== 0) {
    if (zoom < 15) {
      distanceMin = 999
    } else if (zoom < 17) {
      distanceMin = 35
    } else if (zoom < 18) {
      distanceMin = 33
    } else if (zoom < 21) {
      distanceMin = 25
    } else {
      distanceMin = 0
    }
  }
  return distanceMin
}
