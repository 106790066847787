export enum TypePointSweepingEnum {
  PontoDeParada = 'Ponto de Parada',
  InicioDeDeslocamento = 'Ponto de Deslocamento'
}

export const getTypePointSweeping = (
  status: 'PontoDeParada' | 'InicioDeDeslocamento'
) => {
  return TypePointSweepingEnum[status]
}
