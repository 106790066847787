import { pallete } from './colorPallete'

const colorByIndex = (index: number) => {
  const colorPallete = pallete.flat()
  const colorsLenght = colorPallete.length
  let color = '#'

  const page = Math.floor(index / colorsLenght)
  const colorIndex = index > colorsLenght ? index - page * colorsLenght : index
  color += `${colorPallete[colorIndex]}`

  return color
}

export default colorByIndex
