import {
  Box,
  // IconButton,
  TextField,
  Typography
} from '@mui/material'
// import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { ptBR } from 'date-fns/locale'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Button } from '../../../../components'
import DateFnsUtils from '@date-io/date-fns'
import { ViewFilterDetails } from './styles'

interface FilterOtherExecutionI {
  showFilter: boolean
  setShowFilter: React.Dispatch<React.SetStateAction<boolean>>
  loadingShapes: boolean
  dateFilter: any
  setDateFilter: React.Dispatch<any>
  filterExecution: () => void
}

function FilterOtherExecution(props: FilterOtherExecutionI) {
  return (
    <ViewFilterDetails showFilter={props.showFilter}>
      {/* <Box width="100%" display="flex" justifyContent="center">
        <IconButton
          size="small"
          onClick={() => props.setShowFilter((prevShow: boolean) => !prevShow)}
          color="inherit"
        >
          {!props.showFilter ? <ExpandMore /> : <ExpandLess />}
        </IconButton>
      </Box> */}
      <Typography variant="subtitle1" fontWeight={600}>
        Pesquise outra execução
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        flexDirection={'column'}
        p={1}
        justifyContent="flex-end"
        gap={2}
      >
        <LocalizationProvider
          adapterLocale={ptBR}
          utils={DateFnsUtils}
          dateAdapter={AdapterDateFns}
        >
          <DesktopDatePicker
            label="Filtre a data"
            value={props.dateFilter}
            onChange={(date) =>
              props.setDateFilter(!!date ? new Date(date) : new Date())
            }
            shouldDisableDate={(day) => {
              if (props.dateFilter) {
                const dayFormat = new Date(day)
                const today = new Date()
                today.setHours(9, 0, 0)
                dayFormat.setHours(9, 0, 0)
                return dayFormat > today
              }

              return true
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                sx={{
                  width: '20rem'
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
          />
        </LocalizationProvider>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Button
          disabled={props.loadingShapes}
          color="info"
          onClick={props.filterExecution}
        >
          Filtrar
        </Button>
      </Box>
    </ViewFilterDetails>
  )
}

export default FilterOtherExecution
