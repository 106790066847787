import {
  BaseTextFieldProps,
  InputAdornment,
  IconButton,
  InputProps as StandardInputProps,
  Tooltip
} from '@mui/material'
import {
  forwardRef,
  ForwardRefRenderFunction,
  ReactElement,
  useState
} from 'react'
import { Eye, EyeOff } from 'react-feather'
import { FieldError } from 'react-hook-form'
import { StyledTextField } from './styles'

interface InputProps extends BaseTextFieldProps {
  errorContent?: FieldError
  isPassword?: boolean
  showPassword?: boolean
  endAdornment?: ReactElement
  maxLength?: number
  onChange?: StandardInputProps['onChange']
  hint?: string // hint prop added here
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  props: InputProps,
  ref
) => {
  const {
    errorContent,
    isPassword = false,
    endAdornment,
    maxLength,
    hint,
    ...rest
  } = props

  const [showPassword, setShowPassword] = useState(false)

  // Helper function to decide the type of input field.
  const determineType = () =>
    showPassword ? 'text' : isPassword ? 'password' : 'text'
  // Helper function to decide the end adornment.
  const determineEndAdornment = () => {
    if (isPassword) {
      return (
        <InputAdornment position="end">
          <IconButton
            size="small"
            sx={{ fontSize: '10px' }}
            aria-label="toggle password visibility"
            onClick={() => setShowPassword(!showPassword)}
            edge="end"
          >
            {!showPassword ? (
              <EyeOff width={'20px'} height={'20px'} />
            ) : (
              <Eye width={'20px'} height={'20px'} />
            )}
          </IconButton>
        </InputAdornment>
      )
    } else {
      return endAdornment
    }
  }

  // Helper function to decide the max length.
  const determineMaxLength = () =>
    maxLength ? maxLength : isPassword ? 64 : 256

  // Main render function.
  const renderTextField = () => {
    const textField = (
      <StyledTextField
        variant="outlined"
        error={!!errorContent}
        helperText={errorContent?.message}
        ref={ref}
        type={determineType()}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: determineEndAdornment()
        }}
        inputProps={{
          maxLength: determineMaxLength(),
          inputMode: props.type === 'number' ? 'text' : 'text',
          pattern: props.type === 'number' ? '[0-9]*' : undefined,
          step: props.type === 'number' ? 'any' : undefined
        }}
        {...rest}
      />
    )

    // Wrap with tooltip if hint is provided.
    return hint ? <Tooltip title={hint}>{textField}</Tooltip> : textField
  }

  return renderTextField()
}

export const Input = forwardRef(InputBase)
export default Input
