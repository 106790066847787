import {
  DataGrid,
  GridToolbarQuickFilter,
  GridToolbarContainer,
  GridColDef,
  DataGridProps,
  GridActionsCellItem,
  GridSortModel,
  GridFilterModel
} from '@mui/x-data-grid'
import TablePagination from '@mui/material/TablePagination'
import {
  IconButton,
  Radio,
  Tooltip,
  Button as ButtonMui,
  Box,
  Typography
} from '@mui/material'
import {
  Edit,
  Archive,
  Refresh,
  QrCodeScannerOutlined,
  AddCircleOutlineOutlined,
  ChangeCircle,
  ContentCopy,
  LocationOnOutlined,
  Close,
  Error,
  MoreOutlined,
  Print,
  ManageSearch
} from '@mui/icons-material'
import {
  StyledGridToolbarItemContainer,
  StyledSearchToolbar,
  Wrapper
} from './styles'
import LongMenu from './menuGrid'
import { PagePermissionProps } from '../../types/PagePermission'
import { Link, useLocation } from 'react-router-dom'
import { colors } from '../../assets/variables'
import { selectUser } from '../../app/slices/authSlice'
import { Button } from '../index'
import { selectCurrentContract } from '../../app/slices/contractSlice'
import { useAppSelector } from '../../app/hooks'
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity'

interface GridProps extends DataGridProps {
  columns: GridColDef[]
  rows: object[] | []
  totalRows?: number
  archive?: boolean
  onGridAction?: (action: string, row: object | null) => void
  handleRowPerPageChange?: (rows: number) => void
  rowsPerPage?: number
  handlePageChange?: (nextPage: number) => void
  page?: number
  onSortChange?: (model: GridSortModel) => void
  onFilterChange?: (filter: string) => void
  qrCodeFilter?: boolean
  valueFilter?: string
  searchPlaceHolder?: string
  toolTipCopy?: string
  toolTipUnarchive?: string
  toolTipArchive?: string
  toolTipEditRow?: string
  toolTipAddRow?: string
  isTracker?: boolean
  isUser?: boolean
  copy?: boolean
  permissions?: PagePermissionProps
  plotMap?: boolean
  deleteRow?: boolean
  onlyGrid?: boolean
  onlyPagination?: boolean
  exportCsv?: boolean
  archiveColumn?: boolean
  modelTrackerColumns?: boolean
  detailsErrorImport?: boolean
  detailsSweepOverview?: boolean
  buttonToReport?: boolean
  hideFooter?: boolean
  initialState?: GridInitialStateCommunity
  maxRowsPerPage?: number
}

const Grid = ({
  columns,
  rows,
  archive,
  onGridAction,
  onSortChange,
  onFilterChange,
  handleRowPerPageChange,
  handlePageChange,
  rowsPerPage = 10,
  page = 0,
  totalRows = 1,
  qrCodeFilter,
  valueFilter,
  searchPlaceHolder = 'Pesquisar...',
  toolTipCopy = 'Copiar linha',
  toolTipUnarchive = 'Desfazer arquivamento',
  toolTipArchive = 'Arquivar linha',
  toolTipEditRow = 'Editar linha',
  toolTipAddRow = 'Adicionar',
  isTracker = false,
  isUser = false,
  copy = false,
  permissions = {
    canAdd: false,
    canArchive: false,
    canEdit: false
  },
  plotMap = false,
  deleteRow = false,
  onlyGrid = false,
  onlyPagination = false,
  archiveColumn = true,
  modelTrackerColumns = false,
  detailsErrorImport = false,
  detailsSweepOverview = false,
  buttonToReport = false,
  initialState,
  hideFooter = false,
  maxRowsPerPage,
  ...rest
}: GridProps) => {
  const location = useLocation()
  const user = useAppSelector(selectUser)
  const currentContract = useAppSelector(selectCurrentContract)

  const canArchive = (row: any) => {
    return isUser ? Number(row.id) !== user?.codigo : permissions?.canArchive
  }

  const plotMapGrid = {
    field: `plotMap`,
    width: 80,
    disableColumnMenu: true,
    hideable: false,
    filterable: false,
    sortable: false,
    renderHeader: () => (
      <Tooltip title="Selecione para plotar no mapa" placement="top">
        <IconButton color="inherit">
          <LocationOnOutlined color={'inherit'} />
        </IconButton>
      </Tooltip>
    ),
    type: 'actions',
    getActions: (row: any) => [
      <IconButton
        key={row.id}
        onClick={() => onGridAction?.('plotMap', row)}
        color="inherit"
        sx={{
          backgroundColor: row.row.selected ? colors.primaryPastel : ''
        }}
      >
        <LocationOnOutlined color={row.row.selected ? 'info' : 'inherit'} />
      </IconButton>
    ]
  }

  const removeItemGrid = {
    field: `removeItem`,
    width: 80,
    disableColumnMenu: true,
    hideable: false,
    filterable: false,
    sortable: false,
    renderHeader: () => <div></div>,
    type: 'actions',
    getActions: (row: any) => [
      <Tooltip title={'Remover importação'} key={row.id} placement="top">
        <IconButton
          onClick={() => onGridAction?.('removeItem', row)}
          color="inherit"
        >
          <Close color={'error'} />
        </IconButton>
      </Tooltip>
    ]
  }

  const detailsErrorImportGrid = {
    field: `detailErrorImport`,
    width: 80,
    disableColumnMenu: true,
    hideable: false,
    filterable: false,
    sortable: false,
    renderHeader: () => <div></div>,
    type: 'actions',
    getActions: (config: any) => [
      <Tooltip title={'Detalhes da importação'} key={config.id} placement="top">
        {Number(config.row.quantidadeExcecao) > 0 ? (
          <IconButton
            onClick={() => onGridAction?.('detailErrorImport', config)}
            color="inherit"
          >
            <Error color={'error'} />
          </IconButton>
        ) : (
          <></>
        )}
      </Tooltip>
    ]
  }

  const buttonToOpenPdf = {
    field: `Ações do sinótico`,
    width: 80,
    disableColumnMenu: true,
    hideable: false,
    filterable: false,
    sortable: false,
    renderHeader: () => (
      <div>
        <Box>
          <Button
            fullWidth
            color="info"
            onClick={() => onGridAction?.('open-modal-report', null)}
          >
            <Tooltip
              title="Exportar dados"
              placement="left"
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: 'gray'
                  }
                }
              }}
            >
              <Print />
            </Tooltip>
          </Button>
        </Box>
      </div>
    ),
    type: 'actions',
    getActions: () => []
  }

  const detailsSweepOverviewGrid = {
    field: `Ações do sinótico`,
    width: 80,
    disableColumnMenu: true,
    hideable: false,
    filterable: false,
    sortable: false,
    type: 'actions',
    getActions: (row: any) => [
      <Tooltip
        title="Detalhes do sinótico"
        key={row.id}
        placement="left"
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: 'gray'
            }
          }
        }}
      >
        <ButtonMui
          component={Link}
          to={`/sweeping/details/${row.row?.id}`}
          target={'_blank'}
        >
          <MoreOutlined color={'info'} />
        </ButtonMui>
      </Tooltip>
    ]
  }

  const archivedGrid = {
    field: `archive`,
    width: 160,
    disableColumnMenu: true,
    hideable: false,
    filterable: false,
    sortable: false,
    renderHeader: () => (
      <Tooltip
        title={
          !currentContract ? 'Selecione um contrato' : 'Filtrar por arquivados'
        }
        placement="left"
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: !currentContract ? 'orange' : 'gray'
            }
          }
        }}
      >
        <Button
          id="archived"
          disabled={!currentContract}
          color="warning"
          onClick={() => onGridAction?.('archived', null)}
        >
          <Radio checked={archive} color="warning" />
          Arquivados
        </Button>
      </Tooltip>
    ),
    type: 'actions',
    getActions: (row: object) => [
      copy && permissions?.canAdd ? (
        <Tooltip
          title={toolTipCopy}
          placement="left"
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: !currentContract ? 'orange' : 'gray'
              }
            }
          }}
        >
          <GridActionsCellItem
            placeholder={''}
            id="copy"
            key="Copiar"
            icon={<ContentCopy color="inherit" />}
            label="Copiar"
            onClick={() => onGridAction?.('copy', row)}
          />
        </Tooltip>
      ) : (
        <></>
      ),
      archive ? (
        permissions?.canArchive ? (
          <Tooltip
            title={toolTipUnarchive}
            placement="left"
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: !currentContract ? 'orange' : 'gray'
                }
              }
            }}
          >
            <GridActionsCellItem
              placeholder={''}
              id="unarchive"
              disabled={!currentContract}
              key="Desfazer arquivamento"
              icon={<Refresh color="primary" />}
              label="Desfazer arquivamento"
              onClick={() => onGridAction?.('unarchive', row)}
            />
          </Tooltip>
        ) : (
          <></>
        )
      ) : permissions?.canEdit ? (
        <>
          <Tooltip
            title={!currentContract ? 'Selecione um contrato' : toolTipEditRow}
            placement={copy && permissions?.canAdd ? 'top' : 'left'}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: !currentContract ? 'orange' : 'gray'
                }
              }
            }}
          >
            <span>
              <GridActionsCellItem
                placeholder={''}
                id="edit"
                key="Editar"
                disabled={!currentContract}
                icon={
                  !isTracker ? (
                    <Edit color={!currentContract ? 'disabled' : 'primary'} />
                  ) : (
                    <ChangeCircle
                      color={!currentContract ? 'disabled' : 'primary'}
                    />
                  )
                }
                label="Editar"
                onClick={() => onGridAction?.('edit', row)}
              />
            </span>
          </Tooltip>
          {isTracker && (
            <Tooltip
              title={
                !currentContract
                  ? 'Selecione um contrato'
                  : 'Editar Modelo de rastreador'
              }
              placement={copy && permissions?.canAdd ? 'top' : 'left'}
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: !currentContract ? 'orange' : 'gray'
                  }
                }
              }}
            >
              <GridActionsCellItem
                placeholder={''}
                id="edit"
                key="ModelTracker"
                disabled={!currentContract}
                icon={
                  <ManageSearch
                    color={!currentContract ? 'disabled' : 'primary'}
                  />
                }
                label="Editar modelo de rastreador"
                onClick={() => onGridAction?.('modelTracker', row)}
              />
            </Tooltip>
          )}
        </>
      ) : (
        <></>
      ),
      archive ? (
        <></>
      ) : canArchive(row) ? (
        <Tooltip
          title={!currentContract ? 'Selecione um contrato' : toolTipArchive}
          placement="top"
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: !currentContract ? 'orange' : 'gray'
              }
            }
          }}
        >
          <span>
            <GridActionsCellItem
              placeholder={''}
              id="archive"
              key="Arquivar"
              disabled={!currentContract}
              icon={
                <Archive color={currentContract ? 'warning' : 'disabled'} />
              }
              label="Arquivar"
              onClick={() => onGridAction?.('archive', row)}
            />
          </span>
        </Tooltip>
      ) : (
        <span>
          <GridActionsCellItem
            placeholder={''}
            id="archive"
            key="Arquivar"
            sx={{
              cursor: 'no-drop'
            }}
            disabled={true}
            icon={<Archive color={'disabled'} />}
            label="Arquivar"
            onClick={() => onGridAction?.('archive', row)}
          />
        </span>
      )
    ]
  }

  const modelTrackerGrid = {
    field: `archive`,
    width: 160,
    disableColumnMenu: true,
    hideable: false,
    filterable: false,
    sortable: false,
    renderHeader: () => (
      <Tooltip
        title={
          !currentContract ? 'Selecione um contrato' : 'Filtrar por arquivados'
        }
        placement="left"
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: !currentContract ? 'orange' : 'gray'
            }
          }
        }}
      >
        <Typography></Typography>
      </Tooltip>
    ),
    type: 'actions',
    getActions: (row: object) => [
      permissions?.canEdit ? (
        <Tooltip
          title={!currentContract ? 'Selecione um contrato' : toolTipEditRow}
          placement={copy && permissions?.canAdd ? 'top' : 'left'}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: !currentContract ? 'orange' : 'gray'
              }
            }
          }}
        >
          <span>
            <GridActionsCellItem
              placeholder={''}
              id="edit"
              key="Editar"
              disabled={!currentContract}
              icon={
                !isTracker ? (
                  <Edit color={!currentContract ? 'disabled' : 'primary'} />
                ) : (
                  <ChangeCircle
                    color={!currentContract ? 'disabled' : 'primary'}
                  />
                )
              }
              label="Editar"
              onClick={() => onGridAction?.('edit', row)}
            />
          </span>
        </Tooltip>
      ) : (
        <></>
      ),
      true ? (
        <Tooltip
          title={
            !currentContract
              ? 'Selecione um contrato'
              : 'Historico do modelo de rastreador'
          }
          placement={copy && permissions?.canAdd ? 'top' : 'left'}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: !currentContract ? 'orange' : 'gray'
              }
            }
          }}
        >
          <span>
            <GridActionsCellItem
              placeholder={''}
              id="historico"
              key="Historico"
              disabled={!currentContract}
              icon={<ManageSearch color={'primary'} />}
              label="Historico de edição"
              onClick={() => onGridAction?.('history', row)}
            />
          </span>
        </Tooltip>
      ) : (
        <></>
      )
    ]
  }

  const GridColumns = [...columns]

  if (!onlyGrid) {
    if (plotMap) {
      GridColumns.unshift({ ...plotMapGrid })
    }
    if (deleteRow) {
      GridColumns.push(removeItemGrid)
    }
    if (archiveColumn) {
      GridColumns.push(archivedGrid)
    }
    if (detailsErrorImport) {
      GridColumns.push(detailsErrorImportGrid)
    }
    if (detailsSweepOverview) {
      GridColumns.push(detailsSweepOverviewGrid)
    }
    if (buttonToReport) {
      GridColumns.push(buttonToOpenPdf)
    }
    if (modelTrackerColumns) {
      console.log(modelTrackerGrid)
      GridColumns.push(modelTrackerGrid)
    }
  }

  const currentDimensions = JSON.parse(
    localStorage.getItem('dimensions') ?? '{}'
  )

  const pathname =
    location.pathname.split('/')[2] === 'details'
      ? location.pathname.split('/')[1] + '-' + 'details'
      : location.pathname.split('/')[1]

  const onSaveDimensions = (e: any) => {
    localStorage.setItem(
      'dimensions',
      JSON.stringify({
        ...currentDimensions,
        [pathname]: {
          ...currentDimensions?.[pathname],
          columns: e.columns.columnVisibilityModel,
          density: e.density.value
        }
      })
    )
  }

  const ComponetsGrid = {
    Pagination: () => (
      <TablePagination
        sx={{
          '.MuiTablePagination-toolbar': {
            minHeight: '40px'
          }
        }}
        component={'div'}
        color="primary"
        count={totalRows}
        page={totalRows === rowsPerPage ? 0 : page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[
          10,
          15,
          30,
          50,
          maxRowsPerPage ? maxRowsPerPage : totalRows > 100 ? totalRows : 100
        ]}
        labelRowsPerPage={'Linhas por pagina '}
        labelDisplayedRows={({ from, to }) => {
          return `${from} a ${to} de ${totalRows}`
        }}
        onRowsPerPageChange={(e) => {
          handleRowPerPageChange?.(Number(e.target.value))
        }}
        onPageChange={(_, nextPage) => {
          handlePageChange?.(nextPage)
        }}
      />
    ),
    Toolbar: () => {
      return (
        <GridToolbarContainer
          className="row m-0 p-0 w-100"
          sx={{ alignItems: 'center' }}
        >
          <StyledGridToolbarItemContainer className="d-flex flex-wrap flex-md-nowrap justify-content-between col-12 col-lg-6 p-2">
            <LongMenu />

            <StyledSearchToolbar className="order-last order-md-0 mt-2 mt-md-0">
              {valueFilter ? (
                <GridToolbarQuickFilter
                  debounceMs={1500}
                  id="search"
                  sx={{
                    '.MuiOutlinedInput-root': {
                      height: '32px'
                    },
                    width: qrCodeFilter ? '95%' : '100%'
                  }}
                  style={{ width: qrCodeFilter ? '95%' : '100%' }}
                  variant="outlined"
                  className="p-0"
                  placeholder={searchPlaceHolder}
                  value={valueFilter}
                />
              ) : (
                <GridToolbarQuickFilter
                  debounceMs={1500}
                  id="search"
                  sx={{
                    '.MuiOutlinedInput-root': {
                      height: '32px'
                    },
                    width: qrCodeFilter ? '95%' : '100%'
                  }}
                  style={{ width: qrCodeFilter ? '95%' : '100%' }}
                  size="small"
                  variant="outlined"
                  placeholder={searchPlaceHolder}
                  className="p-0"
                />
              )}

              {qrCodeFilter ? (
                <Tooltip title="Procurar por Codigo de barra" placement="top">
                  <IconButton
                    aria-label="Codigo de barras"
                    component="label"
                    style={{
                      marginTop: '-2px',
                      alignItems: 'center'
                    }}
                    onClick={() => onGridAction?.('qrCode', null)}
                  >
                    <QrCodeScannerOutlined />
                  </IconButton>
                </Tooltip>
              ) : null}
            </StyledSearchToolbar>

            {permissions?.canAdd ? (
              <Tooltip
                className="justify-self-end"
                title={
                  !currentContract ? 'Selecione um contrato' : toolTipAddRow
                }
                placement={!currentContract ? 'left' : 'top'}
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: !currentContract ? 'orange' : 'gray'
                    }
                  }
                }}
              >
                <Button
                  id="add"
                  disabled={!currentContract}
                  variant="outlined"
                  color="success"
                  className="ms-3"
                  sx={{
                    alignItems: 'center'
                  }}
                  onClick={() => onGridAction?.('add', null)}
                >
                  <AddCircleOutlineOutlined style={{ marginRight: '5px' }} />
                  Adicionar
                </Button>
              </Tooltip>
            ) : (
              <></>
            )}
          </StyledGridToolbarItemContainer>
        </GridToolbarContainer>
      )
    }
  }

  const readComponentesGrids = () => {
    if (onlyGrid || plotMap || deleteRow || detailsSweepOverview) {
      return undefined
    }
    if (onlyPagination) {
      return {
        Pagination: ComponetsGrid.Pagination
      }
    }
    return { ...ComponetsGrid }
  }

  return (
    <Wrapper>
      <DataGrid
        {...rest}
        rows={rows.map((item) => {
          const mutable: any = item
          Object.keys(mutable).map((obj) => {
            if (mutable[obj as keyof typeof mutable] === '') {
              mutable[obj as keyof typeof mutable] = '---'
            }
          })
          return mutable
        })}
        columns={GridColumns}
        rowCount={rows.length}
        disableSelectionOnClick
        onFilterModelChange={(modal: GridFilterModel) => {
          onFilterChange?.(modal.quickFilterValues?.join(' ') ?? '')
        }}
        pagination
        density={currentDimensions?.[pathname]?.density ?? 'compact'}
        initialState={{
          columns: {
            columnVisibilityModel: currentDimensions?.[pathname]?.columns
          },
          ...initialState
        }}
        onStateChange={(e) => onSaveDimensions(e)}
        onSortModelChange={(modal: GridSortModel) => {
          onSortChange?.(modal)
        }}
        hideFooter={hideFooter}
        sx={{
          ...rest.sx
        }}
        components={{
          ...readComponentesGrids(),
          ...rest.components
        }}
      />
    </Wrapper>
  )
}

export default Grid
