import { AxiosError } from 'axios'
import Response from '../api/Response'
import { User } from '../../types/User'
import api, { apiNoAuth } from './api'

interface loginProps {
  email: string
  senha: string
}

interface loginImpersonateProps {
  codigoEmpresaSelecionada: number
  codigoUsuarioSelecionado: number
}

interface ChangePasswordProps {
  email: string
  senhaAtual: string
  novaSenha: string
  confirmarNovaSenha: string
}

export const login = async (
  loginProps: loginProps
): Promise<Response<User>> => {
  try {
    const response = await apiNoAuth.post('/Account/Logar', loginProps)

    return Response.fromData<User>(
      response.data.data as User,
      response.status,
      response.data.message,
      response.data.success
    )
  } catch (error) {
    return Response.fromError(error as AxiosError)
  }
}

export const loginImpersonate = async (
  loginProps: loginImpersonateProps
): Promise<Response<User>> => {
  try {
    const response = await api.post(
      'Account/GerarTokenUsuarioImpersonate',
      loginProps
    )

    return Response.fromData<User>(
      response.data.data as User,
      response.status,
      response.data.message,
      response.data.success
    )
  } catch (error) {
    return Response.fromError(error as AxiosError)
  }
}

export const changePassword = async (
  params: ChangePasswordProps
): Promise<Response<User>> => {
  try {
    const response = await api.put('Usuario/AlterarSenha', params)

    return Response.fromData<User>(
      response.data.data as User,
      response.status,
      response.data.message,
      response.data.success
    )
  } catch (e) {
    return Response.fromError(e as AxiosError)
  }
}

export const requestPasswordRecovery = async (
  email: string
): Promise<Response<any>> => {
  try {
    const response = await api.post('Account/SolicitarRecuperacaoSenha', {
      email
    })

    return Response.fromData<any>(
      response.data.data as any,
      response.status,
      response.data.message,
      response.data.success
    )
  } catch (error) {
    return Response.fromError(error as AxiosError)
  }
}

export const valitadePasswordRecoveryToken = async (params: {
  email: string
  tokenValidacao: string
}): Promise<Response<any>> => {
  try {
    const response = await api.post(
      'Account/ValidarTokenRecuperarSenha',
      params
    )

    return Response.fromData<any>(
      response.data.data as any,
      response.status,
      response.data.message,
      response.data.success
    )
  } catch (error) {
    return Response.fromError(error as AxiosError)
  }
}

export const recoverPassword = async (params: {
  email: string
  tokenValidacao: string
  novaSenha: string
}): Promise<Response<any>> => {
  try {
    const response = await api.post('Account/RecuperarSenha', params)

    return Response.fromData<any>(
      response.data.data as any,
      response.status,
      response.data.message,
      response.data.success
    )
  } catch (error) {
    return Response.fromError(error as AxiosError)
  }
}

export const ReloadToken = async (
  codigoUsuario: number
): Promise<Response<User>> => {
  try {
    const response = await api.post('/Account/RenovarTokenUsuario', {
      codigoUsuario: codigoUsuario
    })

    return Response.fromData<User>(
      response.data.data as User,
      response.status,
      response.data.message,
      response.data.success
    )
  } catch (error) {
    return Response.fromError(error as AxiosError)
  }
}
