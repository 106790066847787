import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'

const columnsObject = [
  {
    field: 'identificador',
    display: 'Modelo de rastreador',
    alignHeader: 'left',
    hideable: false,
    renderCell: (cellValues: GridRenderCellParams) => {
      return (
        <div
          style={{
            width: '100%',
            textAlign: 'left'
          }}
        >
          {cellValues.value}
        </div>
      )
    }
  },
  {
    field: 'dataCadastro',
    display: 'Data/hora última edição',
    center: false,
    hideable: false,
    renderCell: (cellValues: GridRenderCellParams) => {
      return (
        <div
          style={{
            width: '100%',
            textAlign: 'left'
          }}
        >
          {cellValues.value}
        </div>
      )
    }
  },
  {
    field: 'usuarioCadastro',
    display: 'Responsável última edição',
    width: 650,
    center: false,
    renderCell: (cellValues: GridRenderCellParams) => {
      return (
        <div
          style={{
            width: '100%',
            textAlign: 'left',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          }}
        >
          {cellValues.value}
        </div>
      )
    }
  }
]

export const columns: GridColDef[] = columnsObject.map((colun) => {
  return {
    field: `${colun.field}`,
    headerName: `${colun.display}`,
    hideable: colun?.hideable,
    flex: 1,
    minWidth: 200,
    headerAlign: colun.center ? 'center' : 'left',
    disableColumnMenu: true,
    sortable: false,
    renderCell: colun.renderCell
  }
})
