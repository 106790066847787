import { AxiosError } from 'axios'
import Response from '../api/Response'
import { Company } from '../../types/Company'
import api from './api'

interface createCompanyInterface {
  cnpj: string
  tipo: string
  razaoSocial: string
  emailUsuario: string
  nomeUsuario: string
  versaoTermosUso: string
}

export const listAllCompanys = async (): Promise<Response<Company[]>> => {
  try {
    const response = await api.get('Empresa/ListarTodas')

    return Response.fromData(
      response.data.data as Company[],
      response.status,
      response.data.message,
      response.data.success
    )
  } catch (error) {
    return Response.fromError(error as AxiosError)
  }
}

export const createCompany = async (
  params: createCompanyInterface
): Promise<Response<any>> => {
  try {
    const response = await api.post('Empresa/Cadastrar', params)

    return Response.fromData(
      response.data.data as any,
      response.status,
      response.data.message,
      response.data.success
    )
  } catch (error) {
    return Response.fromError(error as AxiosError)
  }
}
