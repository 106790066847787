import { useLocation } from 'react-router-dom'
import { Item, ItemTitle, Description, Figure, Icon, Upload } from './styles'

import UploadImage from '../../assets/icons/menu/upload.png'
import { MenuProps } from '../../types/Menu'

interface MenuItemProps {
  group: string
  item: MenuProps
  index: number
  onClick: () => void
}

function MenuItem({ group, item, index, onClick }: MenuItemProps) {
  const location = useLocation()
  return (
    <Item
      id={`${group}-${item.name}`}
      key={index}
      active={location.pathname.replace('/', '') === item.attributes.path}
      onClick={onClick}
    >
      <div className="d-flex align-items-center">
        <Figure>
          <Icon
            src={require(`../../assets/icons/menu/${item.attributes.icon}`)}
          />
          {item.attributes.import && <Upload src={UploadImage} />}
        </Figure>
        <ItemTitle>{item.name}</ItemTitle>
      </div>
      <Description>{item.attributes.description}</Description>
    </Item>
  )
}

export default MenuItem
