import {
  Map,
  MapEventType,
  PolylineEventType,
  PolylineOptions
} from '@inlog/inlog-maps/lib'

import * as geolib from 'geolib'

export const calculateCoordinates = (
  coordinateFrom: number[],
  coordinateTo: number[],
  meters: number
) => {
  const bearing = geolib.getRhumbLineBearing(
    { latitude: coordinateFrom[0], longitude: coordinateFrom[1] },
    { latitude: coordinateTo[0], longitude: coordinateTo[1] }
  )

  const newCoords = geolib.computeDestinationPoint(
    { latitude: coordinateFrom[0], longitude: coordinateFrom[1] },
    meters,
    bearing
  )

  return [newCoords.latitude, newCoords.longitude]
}

export const calculateAngle = (
  coordinateFrom: number[],
  coordinateMiddle: number[],
  coordinateTo: number[]
) => {
  const coordFrom = {
    latitude: coordinateFrom[0],
    longitude: coordinateFrom[1]
  }
  const coordMiddle = {
    latitude: coordinateMiddle[0],
    longitude: coordinateMiddle[1]
  }
  const coordTo = { latitude: coordinateTo[0], longitude: coordinateTo[1] }

  const a = [
    coordFrom.latitude - coordMiddle.latitude,
    coordFrom.longitude - coordMiddle.longitude
  ]
  const b = [
    coordTo.latitude - coordMiddle.latitude,
    coordTo.longitude - coordMiddle.longitude
  ]
  const scalarProduct = a[0] * b[0] + a[1] * b[1]
  const magnitudeA = Math.sqrt(a[0] * a[0] + a[1] * a[1])
  const magnitudeB = Math.sqrt(b[0] * b[0] + b[1] * b[1])
  const cosineOfAngle = scalarProduct / (magnitudeA * magnitudeB)
  const angleInRadians = Math.acos(cosineOfAngle)
  const angleInDegrees = (angleInRadians * 180) / Math.PI
  return angleInDegrees
}

export const renderPatch = (
  map: Map | null,
  color: string,
  latlng: number[][],
  index = 0,
  event?: (e: any) => void
) => {
  const options = new PolylineOptions(latlng)
  options.object = { type: 'patch' }
  options.color = color
  options.weight = 5
  options.addToMap = true
  options.fitBounds = false
  options.zIndex = index + 1

  map?.drawPolyline('patch', options, event)
}

export const removeAllPatch = (map: Map | null) => {
  map?.removePolylines('patch')
}

export const UpdatePolyline = (
  map: Map | null,
  uuid: string,
  latlng: number[],
  editable = true
) => {
  const path = map?.getPolylinePath(
    'polyline',
    (object: { uuid: string }) => object.uuid === uuid
  )
  path?.push(latlng)
  const options = new PolylineOptions(path)
  options.editable = editable
  map?.alterPolylineOptions(
    'polyline',
    options,
    (object: { uuid: string }) => object.uuid === uuid
  )
}

export const CreatePolyline = (
  map: Map | null,
  uuid: string,
  latlng: number[],
  event?: (e: any) => void,
  type = 'polyline',
  editable = true
) => {
  const options = new PolylineOptions([latlng])
  options.object = { uuid: uuid, type }
  options.color = '#009ACA'
  options.weight = 5
  options.editable = editable
  options.addToMap = true
  options.fitBounds = false
  options.draggable = true

  map?.drawPolyline('polyline', options, event)
}

export const addEventRemoveVertexPolyline = (
  map: Map | null,
  event: (e: any) => void
) => {
  map?.addPolylineEvent('polyline', PolylineEventType.RemoveAt, event)
}

export const RenderPolyline = (
  map: Map | null,
  uuid: string,
  latlng: number[][],
  event?: (e: any) => void,
  editable = true
) => {
  const options = new PolylineOptions(latlng)
  options.object = { uuid: uuid, type: 'polyline' }
  options.color = '#000000'
  options.weight = 5
  options.addToMap = true
  options.editable = editable
  options.fitBounds = false
  options.draggable = true

  map?.drawPolyline('polyline', options, event)
}

export const AlterPolylineColor = (
  map: Map | null,
  uuid: string,
  color: string
) => {
  const options = new PolylineOptions()
  options.color = color
  map?.alterPolylineOptions(
    'polyline',
    options,
    (object: { uuid: string }) => object.uuid === uuid
  )
}

export const RemoveAllPolylines = (map: Map | null) => {
  map?.removePolylines('polyline')
}

export const AddMapEventClick = (
  map: Map | null,
  event: (e: { latlng: number[] }) => void
) => {
  map?.addEventMap(MapEventType.Click, event)
}

export const RemoveMapEventClick = (map: Map | null) => {
  map?.removeEventMap(MapEventType.Click)
}

export const AddRightClickEventPolyline = (
  map: Map | null,
  uuid: string,
  event: (e: { latlng: number[] }) => void
) => {
  map?.addPolylineEvent(
    'polyline',
    PolylineEventType.RightClick,
    event,
    (object: { uuid: string }) => object.uuid === uuid
  )
}

export const AddEventPolyline = (
  map: Map | null,
  uuid: string,
  event: (e: { latlng: number[] }) => void
) => {
  map?.addPolylineEvent(
    'polyline',
    PolylineEventType.InsertAt,
    event,
    (object: { uuid: string }) => object.uuid === uuid
  )
  map?.addPolylineEvent(
    'polyline',
    PolylineEventType.RemoveAt,
    event,
    (object: { uuid: string }) => object.uuid === uuid
  )
  map?.addPolylineEvent(
    'polyline',
    PolylineEventType.SetAt,
    event,
    (object: { uuid: string }) => object.uuid === uuid
  )
  map?.addPolylineEvent(
    'polyline',
    PolylineEventType.DragPolyline,
    event,
    (object: { uuid: string }) => object.uuid === uuid
  )
}

export const ResetShapeColor = (
  map: Map | null,
  color: string,
  exceptionsUuid?: string[]
) => {
  const options = new PolylineOptions()
  options.color = color

  map?.alterPolylineOptions(
    'polyline',
    options,
    (object: { uuid: string }) => !exceptionsUuid?.includes(object.uuid)
  )
}

export const coordinatesToKm = (
  coordinatesFrom: number[],
  coordinatesTo: number[]
) => {
  const distance = geolib.getDistance(
    { latitude: coordinatesFrom[0], longitude: coordinatesFrom[1] },
    { latitude: coordinatesTo[0], longitude: coordinatesTo[1] }
  )

  return distance / 1000
}
