import axios, { AxiosError } from 'axios'
import Response from '../api/Response'

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_LUTOCARFRONT_GEOCODE_URL}/`
})

axiosInstance.interceptors.request.use(
  (config) => {
    const user = JSON.parse(localStorage.getItem('@lutocar-user-info')!).nome
    const company = JSON.parse(
      localStorage.getItem('@lutocar-current-contract')!
    ).razaoSocial

    const token = btoa(`@inlog.geocoding;${user};${company}`)

    config.headers['Authorization'] = token.toString()
    config.headers['Content-Type'] = 'application/json'
    config.headers['Access-Control-Allow-Origin'] = '*'
    config.headers['Access-Control-Allow-Methods'] =
      'GET, POST, PATCH, PUT, DELETE, OPTIONS'
    config.headers['Access-Control-Allow-Headers'] =
      'Origin, X-Requested-With, X-Auth-Token, Content-Type, Accept'
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

interface SocialReasonData {
  bairro: string
  cep: string
  cidade: string
  descricao: string
  estado: string
  latitude: number
  longitude: number
  numero: string
  rua: number
}

export const getCoordinateByAddress = async (
  address: string
): Promise<Response<SocialReasonData>> => {
  try {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_LUTOCARFRONT_GEOCODE_URI}/${address}`
    )

    return Response.fromData<SocialReasonData>(
      response.data,
      response.status,
      response.data.message
    )
  } catch (error) {
    return Response.fromError(error as AxiosError)
  }
}

export const getAddressByCoordinates = async (coordinates: {
  latitude: number
  longitude: number
}): Promise<Response<any>> => {
  try {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_LUTOCARFRONT_GEOCODE_REVERSE_URI}/${coordinates.latitude}, ${coordinates.longitude}`
    )

    return Response.fromData<SocialReasonData>(
      response.data,
      response.status,
      response.data.message
    )
  } catch (error) {
    return Response.fromError(error as AxiosError)
  }
}
