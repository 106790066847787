import { Box, CircularProgress } from '@mui/material'
import React, { ReactNode } from 'react'
import { Body, Footer, Header, Title, Wrapper } from './styles'

interface PropsCard {
  className?: string
  title: string
  children: ReactNode
  footer: ReactNode
  isLoading?: boolean
}

const Card: React.FC<PropsCard> = ({
  className,
  title,
  children,
  footer,
  isLoading = false
}) => {
  return (
    <Wrapper className={className}>
      <Header>
        <Title>{title}</Title>
      </Header>
      <Body>
        {isLoading ? (
          <Box display="flex" justifyContent="center" style={{ width: '100%' }}>
            <CircularProgress />
          </Box>
        ) : (
          children
        )}
      </Body>
      <Footer>{footer}</Footer>
    </Wrapper>
  )
}

export default Card
