import { ButtonHTMLAttributes, forwardRef } from 'react'
import { Maximize, Minimize } from 'react-feather'
import { StylesCloseProps, Wrapper } from './styles'

interface MaximizeProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    StylesCloseProps {
  isFullScreen: boolean
}

const MaximizeIcon = forwardRef<HTMLButtonElement, MaximizeProps>(
  ({ size, isFullScreen, ...rest }, ref) => {
    return (
      <Wrapper {...rest} ref={ref} size={size}>
        {isFullScreen ? <Minimize /> : <Maximize />}
      </Wrapper>
    )
  }
)

MaximizeIcon.displayName = 'Maximize'

export default MaximizeIcon
