import { View, Text, StyleSheet } from '@react-pdf/renderer'
import { colors } from '../../../assets/variables'
import { format } from 'date-fns'
import { ColumnsGridI, ColumnsGridReport } from './columnGrid'
import { RowsGridReport } from './rowsGrid'

const borderColor = colors.gray
const borderWidth = 0.5

const styles = StyleSheet.create({
  tableContainerHeader: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderRight: borderWidth,
    borderLeft: borderWidth,
    borderColor: borderColor
  },
  containerHeader: {
    flexDirection: 'row',
    borderBottomColor: borderColor,
    backgroundColor: '#FFFF',
    borderBottomWidth: borderWidth,
    alignItems: 'center',
    height: 15,
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1,
    color: colors.gray900,
    fontSize: 4
  },
  viewColumnHeader: {
    height: '100%',
    borderRightColor: borderColor,
    borderRightWidth: borderWidth,
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingLeft: 5
  },
  textColumnHeader: {
    color: colors.gray900,
    fontSize: 6
  },
  flag: {
    height: 5,
    width: 9,
    marginRight: 2
  }
})

interface ColumnsHeaderReportI {
  data: {
    dataOperacao: Date
    contrato: string
    rastreadores: any[]
    observation: string
  }
}

interface ColumnI {
  width: string
  keyName: string
  valueName: string
  isKeyValue?: boolean
  borderRight?: number
  borderBottom?: number
  alignItems?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'stretch'
    | 'baseline'
    | undefined
  flagColor?: string
}
const Column = ({
  width,
  keyName,
  valueName,
  borderRight = 0.5,
  borderBottom = 0,
  alignItems = 'flex-start',
  isKeyValue = true,
  flagColor
}: ColumnI) => {
  return (
    <View
      style={{
        ...styles.viewColumnHeader,
        width: width,
        borderRight: borderRight,
        borderBottom: borderBottom,
        alignItems: alignItems,
        borderColor: borderColor
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        {flagColor ? (
          <View style={{ ...styles.flag, backgroundColor: flagColor }} />
        ) : null}
        <Text style={{ ...styles.textColumnHeader, fontWeight: 600 }}>
          {isKeyValue ? `${keyName}: ${valueName}` : `${keyName}`}
        </Text>
      </View>
    </View>
  )
}

export const ColumnsHeaderReport = ({ data }: ColumnsHeaderReportI) => {
  const coluns: ColumnsGridI[] = [
    {
      name: 'ID Rastreador',
      width: '10%',
      textAlign: 'left'
    },
    {
      name: 'Serviço Especial',
      width: '25%',
      textAlign: 'left'
    },
    {
      name: 'Dist. Espacial Percorrida',
      width: '25%',
      textAlign: 'center'
    },
    {
      name: 'Tempo Operação',
      width: '10%',
      textAlign: 'center'
    },
    {
      name: 'Horário Inicial',
      width: '15%',
      textAlign: 'center'
    },
    {
      name: 'Horário Final',
      width: '15%',
      textAlign: 'center'
    }
  ]

  return (
    <>
      <View id="Data-Contrato" style={styles.tableContainerHeader}>
        <View style={styles.containerHeader}>
          <Column
            keyName="Data operação"
            valueName={format(data.dataOperacao, 'dd/MM/yyyy')}
            width="50%"
          />
          <Column
            keyName="Contrato"
            valueName={data.contrato}
            width="50%"
            borderRight={0}
          />
        </View>
      </View>
      <View
        id="RASTRADOR(ES) SELECIONADO(S)"
        style={styles.tableContainerHeader}
      >
        <View
          style={{
            ...styles.containerHeader,
            backgroundColor: colors.systemPastel,
            borderColor: borderColor,
            borderRightWidth: 0
          }}
        >
          <Column
            isKeyValue={false}
            keyName="RASTRADOR(ES) SELECIONADO(S)"
            valueName={''}
            width="100%"
            alignItems="center"
            borderRight={0}
          />
        </View>
      </View>

      <ColumnsGridReport data={coluns} />
      <RowsGridReport rows={data.rastreadores} columns={coluns} />

      <View id="Observation" style={styles.tableContainerHeader}>
        <View style={styles.containerHeader}>
          <Column
            keyName="Observação"
            valueName={data.observation}
            width="100%"
            borderRight={0}
          />
        </View>
      </View>

      <View
        id="IMAGEM DO SETOR: Comprovação de execução"
        style={styles.tableContainerHeader}
      >
        <View
          style={{
            ...styles.containerHeader,
            backgroundColor: colors.systemPastel,
            borderColor: borderColor,
            borderRightWidth: 0
          }}
        >
          <Column
            isKeyValue={false}
            keyName="IMAGEM DO SETOR: Comprovação de execução"
            valueName={''}
            width="100%"
            alignItems="center"
            borderRight={0}
          />
        </View>
      </View>
    </>
  )
}
