export const getColorLocalStorage = (trackerCode: number) => {
  let trackersColors: any[] = []
  const storage = localStorage.getItem('trackers_colors')
  if (storage) {
    trackersColors = JSON.parse(localStorage.getItem('trackers_colors') ?? '')
  }
  const newColor = trackersColors.find((el) => el[trackerCode])
  if (newColor) {
    return newColor[trackerCode]
  }
}
