import styled from 'styled-components'
import { Wrapper as Close } from '../CloseIcon/styles'

export const Header = styled.div<{ isCenter?: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.isCenter ? 'center' : 'space-between')};
  ${Close} {
  }
`

export const HeaderButtons = styled.div`
  flex-direction: row;
  display: flex;
  padding: 10px;
  column-gap: 15px;
`
