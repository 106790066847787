import React, { InputHTMLAttributes } from 'react'
import { Input, Text, Wrapper } from './styles'

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string
  label?: string
}

const Checkbox: React.FC<CheckboxProps> = ({ className, label, ...rest }) => {
  return (
    <Wrapper className={className}>
      <Input {...rest} type="checkbox" />
      <Text>{label}</Text>
    </Wrapper>
  )
}

export default Checkbox
