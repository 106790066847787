import { createRoot } from 'react-dom/client'

import { Provider } from 'react-redux'
import { store } from './app/store'

import { ThemeProvider } from '@mui/material/styles'
import { theme } from './mui-theme'
import { SnackbarProvider } from 'notistack'
import App from './routes'
import { BrowserRouter } from 'react-router-dom'
import { Suspense } from 'react'
import GlobalLoading from './components/LoadingGlobal'

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <Suspense fallback={<GlobalLoading />}>
            <App />
          </Suspense>
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  </BrowserRouter>
)
