import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import useTrackers from '../../../utils/useTrackers'
import { useAppSelector } from '../../../app/hooks'
import { selectCurrentContract } from '../../../app/slices/contractSlice'
import Option from '../../../types/Option'

interface FilterTrackersI {
  onFilteredList: (list: Option[]) => void
  defaultValue?: Option[]
}

const FilterTrackers: React.FC<FilterTrackersI> = ({
  onFilteredList,
  defaultValue
}) => {
  const currentContract = useAppSelector(selectCurrentContract)
  const [options, setOptions] = useState<Option[]>([])
  const [query, setSearch] = useState('')

  const { handleFetch, loading } = useTrackers((response) => {
    if (response.data) {
      const trackersList = response.data.map((item: any) => {
        return {
          value: item.codigo,
          label: `${('0000' + item.identificador).slice(-4)}`
        }
      })

      setOptions(trackersList)
    }
  })

  useEffect(() => {
    const haveTrackerInOptions = options.find((item) => item.label === query)
    if (currentContract && !haveTrackerInOptions) {
      handleFetch({
        arquived: false,
        take: 100,
        skip: 0,
        query: query,
        codigoContrato: currentContract.codigo
      })
    }
  }, [currentContract, query])

  return (
    <Autocomplete
      size="small"
      fullWidth
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '.MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input':
          {
            height: '14px'
          }
      }}
      loading={loading}
      limitTags={6}
      options={options ?? []}
      defaultValue={defaultValue}
      noOptionsText="Sem rastreadores cadastrados"
      isOptionEqualToValue={(option, value) => {
        return option.value === value.value
      }}
      onChange={(event: any, newValue: Option[]) => {
        onFilteredList(newValue)
      }}
      onBlurCapture={() => {
        setSearch('')
      }}
      multiple
      disableCloseOnSelect
      disableListWrap
      getOptionLabel={(option: any) => {
        return option.label
      }}
      onBlur={() => setSearch('')}
      //   ListboxProps={{
      //     onScroll: (event: React.SyntheticEvent) => {
      //       const { scrollTop, scrollHeight, clientHeight } = event.currentTarget
      //       if (
      //         Math.floor(((scrollTop + clientHeight) / scrollHeight) * 100 + 1) >=
      //         100
      //       ) {
      //         const delayPagination = setTimeout(() => {
      //           if (countTrackers !== trackers.length)
      //             setPaginationTrackers(paginationTrackers + 1)
      //         }, 500)
      //         return () => clearTimeout(delayPagination)
      //       }
      //     }
      //   }}
      renderOption={(props, option) => {
        return (
          <li
            {...props}
            key={option.value}
            style={{
              height: '2rem'
            }}
          >
            {/* <Checkbox
              key={option.value}
              icon={icon}
              checkedIcon={checkedIcon}
              checked={checkOption(option)}
            /> */}
            <span
              style={{
                fontSize: '14px'
              }}
            >
              {option.label}
            </span>
          </li>
        )
      }}
      renderInput={(params: any) => {
        return (
          <TextField
            {...params}
            label="Id Rastreador(es)"
            variant="outlined"
            sx={{
              width: '100%',
              alignItems: 'center'
            }}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )
      }}
    />
  )
}

export default FilterTrackers
