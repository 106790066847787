import * as yup from 'yup'
import { validateImei } from './generical'

export const createTrackerSchema = yup.object().shape({
  imei: yup
    .string()
    .required('IMEI é obrigatório')
    .test('imei-validate', '', function (value) {
      if (!validateImei(value)) {
        return this.createError({
          message: `IMEI inválido`
        })
      }
      return true
    }),
  codigoModeloRastreador: yup
    .object()
    .nullable()
    .test(
      'model-empty',
      'Modelo do rastreador é obrigatório',
      function (field) {
        if (field && field.value !== -1) {
          return true
        }
        return false
      }
    )
})

export const editTrackerSchema = yup.object().shape({
  imei: yup
    .string()
    .required('IMEI é obrigatório')
    .test('imei-validate', '', function (value) {
      if (!validateImei(value)) {
        return this.createError({
          message: `IMEI inválido`
        })
      }
      return true
    })
})

export const createModelTrackerSchema = yup.object().shape({
  identificador: yup.string().required('Modelo do rastreador é obrigatório')
})
