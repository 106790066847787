import { Page, Document, StyleSheet, Image } from '@react-pdf/renderer'
import { HeaderTitleAndImage } from '../headerTitleAndImage'
import { ColumnsHeaderReport } from './columnsHeader'

const styles = StyleSheet.create({
  page: {
    paddingTop: '2vh',
    paddingBottom: '2vh',
    paddingHorizontal: 10
  },
  map_screen: {
    height: 300,
    width: '100%',
    maxWidth: '100%',
    maxHeight: 300,
    objectFit: 'contain',
    objectPosition: 'center'
  }
})

interface ReportSweepingDetailsI {
  reportName: string
  headerInfos: {
    contrato: string
    dataOperacao: Date
    rastreadores: any[]
    prefeitura?: string
    urlContratoLogo?: string | null
    urlPrefeituraLogo?: string | null
    observation: string
  }
  contextMapScreen?: string
  //   cleanMapScreen?: string
}

// Create Document Component
export const ReportSweepingSpecialService = ({
  reportName,
  headerInfos,
  contextMapScreen
}: ReportSweepingDetailsI) => {
  return (
    <Document author="Lutocar" title="Relatório de Serviços Especiais">
      <Page size="A4" style={styles.page} wrap>
        <HeaderTitleAndImage
          data={{
            reportName,
            concessionaireUrlPhoto: headerInfos.urlContratoLogo ?? undefined,
            cityHallUrlPhoto: headerInfos.urlPrefeituraLogo ?? undefined,
            cityHallName: headerInfos.prefeitura
          }}
        />
        <ColumnsHeaderReport data={{ ...headerInfos }} />
        <Image src={contextMapScreen} style={styles.map_screen} cache={false} />
      </Page>
    </Document>
  )
}
