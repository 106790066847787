import { lazy, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Wrapper, Group, GroupTitle, MenuItems } from './styles'
import ListMenuConfig from '../../utils/menu.json'

import { MenuProps as MenuType } from '../../types/Menu'
const MenuItem = lazy(() => import('../MenuItem'))

import { useAppSelector, useAppDispatch } from '../../app/hooks'
import {
  selectActionSystem,
  selectUserIsCityHall,
  selectUserIsContractByMatriz,
  userIsImpersonate
} from '../../app/slices/authSlice'

import { v4 as uuidv4 } from 'uuid'
import { setPermission } from '../../app/slices/permissionSlice'
import getScreenPermissionByScreenName from '../../utils/getPermissionByScreen'
import {
  getScreenOnlyCityHall,
  getScreenOnlyHeadquarters,
  getScreenSpecialServices
} from '../../utils/getScreenOnlyHeadquartes'
import {
  selectAllContract,
  selectCurrentContract
} from '../../app/slices/contractSlice'
import { Contract } from '../../types/Contract'

interface MenuListProps {
  group: string
  items: MenuType[] | any[]
}

interface MenuProps {
  show: boolean
  toggle: () => void
}

export const Menu = ({ show }: MenuProps) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const userActionsSystem = useAppSelector(selectActionSystem)!
  const isImpersonate = useAppSelector(userIsImpersonate)!
  const isContractMatriz = useAppSelector(selectUserIsContractByMatriz)
  const isCityHall = useAppSelector(selectUserIsCityHall)
  const currentContract = useAppSelector(selectCurrentContract) as Contract
  const allContracts = useAppSelector(selectAllContract) as Contract[]

  const [listMenuWithPermission, setListMenuWithPermission] = useState<
    MenuListProps[]
  >([])

  function handleLink(path: string) {
    navigate(`/${path}`)
  }

  const getOnlyScreenPermission = () => {
    return userActionsSystem
      .map((item: any) => {
        return item.tela
      })
      .filter((elem: any, index: any, self: any) => {
        return index === self.indexOf(elem)
      })
  }

  const populateTheListOfAllowedScreens = (listMenuPermissionConfig: any[]) => {
    dispatch(setPermission(listMenuPermissionConfig))
  }

  useEffect(() => {
    if (userActionsSystem) {
      const listMenuPermissionConfig: any[] = []

      const screenBySpecialServices = getScreenSpecialServices()
      const screenPermission = getOnlyScreenPermission()
      const screenByHeadquarters = getScreenOnlyHeadquarters()
      const screenByCityHall = getScreenOnlyCityHall()

      const menuListWithFilteredPermission = ListMenuConfig.map((group) => {
        return {
          ...group,
          items: group.items
            .map((item) => {
              if (isCityHall) {
                const matrizHaveSpecialService = allContracts.some(
                  (el: any) => el.matriz && el?.habilitarServicosEspeciais
                )
                if (
                  matrizHaveSpecialService &&
                  item.attributes.path === 'sweep-special-services'
                ) {
                  listMenuPermissionConfig.push({
                    pageName: item.attributes.path,
                    listPermission: getScreenPermissionByScreenName(
                      item.attributes?.permission!,
                      userActionsSystem
                    )
                  })
                  return item
                }
                if (screenByCityHall.includes(item.name)) {
                  listMenuPermissionConfig.push({
                    pageName: item.attributes.path,
                    listPermission: getScreenPermissionByScreenName(
                      item.attributes?.permission!,
                      userActionsSystem
                    )
                  })
                  return item
                }

                return null
              }
              if (item.attributes.enabledWithoutContract) {
                if (
                  currentContract?.habilitarServicosEspeciais &&
                  screenBySpecialServices.includes(item.name) &&
                  screenPermission?.includes(item.attributes?.permission!)
                ) {
                  listMenuPermissionConfig.push({
                    pageName: item.attributes.path,
                    listPermission: getScreenPermissionByScreenName(
                      item.attributes?.permission!,
                      userActionsSystem
                    )
                  })
                  return item
                }
              }

              if (screenByHeadquarters?.includes(item.name)) {
                if (
                  isContractMatriz &&
                  screenPermission?.includes(item.attributes?.permission!)
                ) {
                  listMenuPermissionConfig.push({
                    pageName: item.attributes.path,
                    listPermission: getScreenPermissionByScreenName(
                      item.attributes?.permission!,
                      userActionsSystem
                    )
                  })

                  return item
                }
              } else if (
                screenPermission?.includes(item.attributes?.permission!) &&
                !item.attributes.enabledWithoutContract
              ) {
                if (
                  isImpersonate ||
                  (item.attributes.path !== 'sector-imports' &&
                    item.attributes.path !== 'sector-scheduling-imports')
                ) {
                  listMenuPermissionConfig.push({
                    pageName: item.attributes.path,
                    listPermission: getScreenPermissionByScreenName(
                      item.attributes?.permission!,
                      userActionsSystem
                    )
                  })

                  return item
                }

                // TODO: Paleativo, deixar Importação somente para impersonate por enquanto.
                // listMenuPermissionConfig.push({
                //   pageName: item.attributes.path,
                //   listPermission: getScreenPermissionByScreenName(
                //     item.attributes?.permission!,
                //     userActionsSystem
                //   )
                // })

                // return item
              }

              // TODO: Trava para SLU
              if (
                currentContract.cnpj === '17.851.447/0002-58' &&
                item.attributes.permission === 'slu'
              ) {
                return item
              }

              return null
            }, [])
            .filter((item) => item !== null)
        } as MenuListProps
      })

      populateTheListOfAllowedScreens(listMenuPermissionConfig)

      setListMenuWithPermission(menuListWithFilteredPermission)
    }
  }, [userActionsSystem, currentContract])

  // useEffect(() => {
  //   listMenuPermissionConfig.push({
  //     pageName: item.attributes.path,
  //     listPermission: getScreenPermissionByScreenName(
  //       item.attributes?.permission!,
  //       userActionsSystem
  //     )
  //   })
  // }, [screenBySpecialServices])

  return (
    <Wrapper show={show}>
      {listMenuWithPermission.map((group) => {
        const uuid = uuidv4()

        if (group.items.length > 0) {
          return (
            <Group className="container" key={uuid}>
              <GroupTitle>{group.group}</GroupTitle>
              <MenuItems>
                {group.items.map((menuItem, menuIndex) => (
                  <MenuItem
                    key={menuIndex}
                    item={menuItem}
                    group={group.group}
                    onClick={() => handleLink(menuItem.attributes.path)}
                    index={menuIndex}
                  />
                ))}
              </MenuItems>
            </Group>
          )
        }
        return null
      })}
    </Wrapper>
  )
}

export default Menu
