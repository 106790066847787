import { lazy, useEffect, useState } from 'react'

import LogoLutocar from '../../assets/images/logo-inlog.png'
import { Users } from 'react-feather'
import {
  Wrapper,
  Hamburguer,
  Title,
  User,
  Info,
  UserImpersonate,
  Branches,
  Branch,
  Footer
} from './styles'

import ListMenuConfig from '../../utils/menu.json'

const Avatar = lazy(() => import('../Avatar/index'))
const Button = lazy(() => import('../Button/index'))
const ChangePassword = lazy(() => import('../ChangePassword/index'))
const ImpersonateModal = lazy(() => import('../ImpersonateModal/index'))

import Menu from '../Menu'
import Popover from '@mui/material/Popover'
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import {
  AllContractsType,
  removeCurrentContract,
  selectAllContract,
  selectCurrentContract,
  setAllContracts,
  setCurrentContract
} from '../../app/slices/contractSlice'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  logout,
  selectUser,
  selectUserIsCityHall,
  userIsImpersonate
} from '../../app/slices/authSlice'
import { Box, Typography } from '@mui/material'
import { Contract } from '../../types/Contract'
import { useAppVersion } from '../../utils/useAppVersion'

export const Header = () => {
  const version = useAppVersion()
  const navigate = useNavigate()
  const location = useLocation()
  const user = useAppSelector(selectUser)
  const isImpersonate = useAppSelector(userIsImpersonate)
  const allContracts = useAppSelector(selectAllContract)
  const isCityHall = useAppSelector(selectUserIsCityHall)
  const currentContractStorage = localStorage.getItem(
    '@lutocar-current-contract'
  )
  const currentContract = useAppSelector(selectCurrentContract)
  const dispatch = useAppDispatch()

  const [titlePage, setTitlePage] = useState<string>('')
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const [showMenu, setShowMenu] = useState(false)
  const [showAllContracts, setShowAllContracts] = useState(false)
  const [isHome, setIsHome] = useState(false)
  const [modals, setModals] = useState({
    impersonate: false,
    changePassword: false
  })

  useEffect(() => {
    const checkContracts = async () => {
      try {
        if (!allContracts) {
          const response = JSON.parse(
            localStorage.getItem('@lutocar-user-info')!
          )

          if (response) {
            dispatch(setAllContracts(response.contratos))

            if (currentContractStorage && !currentContract) {
              dispatch(
                setCurrentContract(
                  JSON.parse(currentContractStorage) as Contract
                )
              )
            }
          }
        }
      } catch (e) {}
    }

    checkContracts()
  }, [allContracts])

  useEffect(() => {
    if (location.pathname == '/') {
      setIsHome(true)
      setShowMenu(true)
      setTitlePage('')
    } else {
      setIsHome(false)
      setShowMenu(false)
      ListMenuConfig.find((menu) => {
        menu.items.find((item) => {
          if (item.attributes.path === location.pathname.split('/')[1]) {
            if (item.attributes.allContracts) {
              setShowAllContracts(true)
            } else {
              setShowAllContracts(false)
              if (currentContract?.codigo === 0 || currentContract === null) {
                allContracts !== null &&
                  dispatch(
                    setCurrentContract(
                      allContracts?.find((contract) => contract.matriz) ??
                        allContracts[0]
                    )
                  )
              }
            }
            setTitlePage(item.attributes.title)
            return
          }
        })
      })
      if (allContracts) {
        const currentContractAsType = currentContract as Contract
        const isCompanyDealership =
          currentContractAsType.empresa?.tipoEmpresa === 'Concessionaria'
        if (
          location.pathname.includes('sweeping') ||
          location.pathname === '/sweep-special-services'
        ) {
          if (isCityHall) {
            const isCityHallContracts = allContracts?.filter((item) => {
              if (
                location.pathname.includes('sweeping') ||
                location.pathname === '/sweep-special-services'
              ) {
                if (
                  isCityHall &&
                  item.empresa?.tipoEmpresa === 'Concessionaria'
                ) {
                  return item
                }
              } else if (
                isCityHall &&
                item.empresa?.tipoEmpresa === 'Prefeitura'
              ) {
                return item
              } else if (
                !isCityHall &&
                item.empresa?.tipoEmpresa === 'Concessionaria'
              ) {
                return item
              }
            })
            if (isCityHallContracts.length) {
              if (!isCompanyDealership && currentContractAsType.codigo !== 0) {
                dispatch(
                  setCurrentContract(
                    isCityHallContracts?.find((contract) => contract.matriz) ??
                      isCityHallContracts[0]
                  )
                )
              }
            } else {
              dispatch(
                setCurrentContract({
                  codigo: 0,
                  razaoSocial: 'Todos os contratos',
                  chaveGoogle: '',
                  raioVarricaoVelocidade: []
                } as AllContractsType)
              )
            }
          }
        } else {
          if (isCityHall) {
            if (isCompanyDealership) {
              dispatch(setCurrentContract(allContracts[0]))
            }
          } else {
            if (!isCompanyDealership) {
              dispatch(setCurrentContract(allContracts[0]))
            }
          }
        }
      }
    }
  }, [location.pathname, allContracts])

  const [dropdown, setDropdown] = useState(true)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Wrapper>
        <div className="d-flex align-items-center ps-3">
          {!isHome ? (
            <Hamburguer
              active={showMenu}
              onClick={() => setShowMenu((prevShowMenu) => !prevShowMenu)}
            >
              <span></span>
            </Hamburguer>
          ) : null}

          <img
            src={LogoLutocar}
            width={window.innerWidth > 568 ? '90' : '50'}
            alt="Logo Lutocar"
            onClick={() => {
              navigate('/')
            }}
            style={{ cursor: 'pointer' }}
          />
          <Title id="page-title">
            Lutocar {titlePage ? `- ${titlePage}` : ''}
          </Title>
        </div>

        <div className="d-flex align-items-center">
          <User
            id="id-user-content"
            onClick={(e) => {
              setDropdown(!dropdown)
              handleClick(e)
            }}
          >
            <Info>
              <span>{user?.nome ?? ''}</span>
              <small>
                {currentContract?.razaoSocial ?? 'Todos os contratos'}
              </small>
            </Info>
            <Avatar fullName={user?.nome?.toString()} />
          </User>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
          >
            <Branches show={open}>
              <Box style={{ margin: '8px' }}>
                <div
                  className="d-flex flex-column align-items-stretch"
                  style={{ maxHeight: 300, overflow: 'auto' }}
                >
                  {showAllContracts ? (
                    <Branch
                      active={
                        currentContract?.codigo === 0 ||
                        currentContract === null
                      }
                      onClick={() => {
                        dispatch(removeCurrentContract())
                        handleClose()
                      }}
                    >
                      <span>Todos os contratos</span>
                      <small>Remover filtro por contrato</small>
                    </Branch>
                  ) : null}
                  {allContracts
                    ?.filter((item) => {
                      const isCompanyDealership =
                        item.empresa?.tipoEmpresa === 'Concessionaria'
                      if (
                        location.pathname.includes('sweeping') ||
                        location.pathname === '/sweep-special-services'
                      ) {
                        if (isCityHall) {
                          if (isCompanyDealership) {
                            return item
                          }
                        } else {
                          if (isCompanyDealership) {
                            return item
                          }
                        }
                      } else {
                        if (isCityHall) {
                          if (!isCompanyDealership) {
                            return item
                          }
                        } else {
                          if (isCompanyDealership) {
                            return item
                          }
                        }
                      }
                    })
                    .map((contract: Contract, index: number) => {
                      return (
                        <Branch
                          id={`contract-${index}`}
                          key={contract.codigo}
                          active={contract.codigo === currentContract?.codigo}
                          onClick={() => {
                            dispatch(setCurrentContract(contract))
                            handleClose()
                          }}
                        >
                          <span>{contract.razaoSocial}</span>
                          <small>{contract.cnpj}</small>
                        </Branch>
                      )
                    })}
                </div>
                <Footer style={{ flexDirection: 'column' }}>
                  <Box
                    style={{
                      flex: 1,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: !isImpersonate
                        ? 'space-between'
                        : 'flex-end'
                    }}
                  >
                    {!isImpersonate ? (
                      <Button
                        color="primary"
                        onClick={() => {
                          setModals((prevModals) => {
                            return { ...prevModals, changePassword: true }
                          })
                        }}
                      >
                        Alterar Senha
                      </Button>
                    ) : null}

                    <Button
                      id="logout"
                      color="error"
                      onClick={async () => {
                        dispatch(logout())
                        navigate('/login')
                      }}
                    >
                      Sair
                    </Button>
                  </Box>
                  <Box>
                    <Typography align="center" fontSize={12}>
                      Versão: {version}
                    </Typography>
                  </Box>
                  <ChangePassword
                    open={modals.changePassword}
                    close={() => {
                      setModals({ ...modals, changePassword: false })
                    }}
                  />
                </Footer>
              </Box>
            </Branches>
          </Popover>

          {isImpersonate ? (
            <>
              <UserImpersonate
                id="user-impersonate-toggle"
                onClick={() =>
                  setModals((prevModals) => {
                    return { ...prevModals, impersonate: true }
                  })
                }
              >
                <Users />
              </UserImpersonate>
              <ImpersonateModal
                open={modals.impersonate}
                close={() => {
                  setModals({ ...modals, impersonate: false })
                }}
              />
            </>
          ) : null}
        </div>
      </Wrapper>
      <Menu show={showMenu} toggle={() => setShowMenu(false)} />
    </>
  )
}

export default Header
