import { FilterAlt } from '@mui/icons-material'
import {
  Box,
  IconButton,
  List,
  Menu,
  MenuItem,
  Typography
} from '@mui/material'
import { useState } from 'react'

interface Option {
  label: string
  value: any
}

interface FilterHeaderI {
  headerName: string
  options: Option[]
  selected: Option
  selectedFilterHeader?: string | null
  onChange: (e: Option) => void
}

const FilterHeader = ({
  headerName,
  options,
  onChange,
  selected
}: FilterHeaderI) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuItemClick = (option: Option) => {
    onChange(option)
    setAnchorEl(null)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <Box sx={{ width: '100%' }}>
      <List
        component="nav"
        aria-label="Device settings"
        sx={{ bgcolor: 'background.paper' }}
      >
        <Typography variant="subtitle2">
          {headerName}
          <IconButton
            sx={{ marginLeft: '15px' }}
            size="small"
            id="lock-button"
            aria-haspopup="listbox"
            aria-controls="lock-menu"
            aria-label="when device is locked"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClickListItem}
          >
            <FilterAlt color={selected.value !== 0 ? 'primary' : 'disabled'} />
          </IconButton>
        </Typography>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox'
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            selected={option.label === selected?.label}
            onClick={() => handleMenuItemClick(option)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

export default FilterHeader
