import { useState, useEffect } from 'react'

export const useAppVersion = () => {
  const [version, setVersion] = useState('')

  useEffect(() => {
    fetch('/version.json')
      .then((res) => res.json())
      .then((data) => setVersion(data.version))
      .catch((err) => console.error(err))
  }, [])

  return version
}
