import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import formatSpecialServiceShift from '../../utils/formatSpecialServiceShift'

const columnsObject: GridColDef[] = [
  {
    field: 'Identificador',
    headerName: 'Identificador',
    sortable: true,
    hideable: false,
    headerAlign: 'left',
    renderCell: (cellValues: GridRenderCellParams) => {
      return (
        <div
          style={{
            width: '100%',
            textAlign: 'left'
          }}
        >
          {cellValues.value}
        </div>
      )
    }
  },
  {
    field: 'Descricao',
    headerName: 'Descrição',
    sortable: true,
    hideable: false,
    headerAlign: 'left',
    renderCell: (cellValues: GridRenderCellParams) => {
      return (
        <div
          style={{
            width: '100%',
            textAlign: 'left'
          }}
        >
          {cellValues.value}
        </div>
      )
    }
  },
  {
    field: 'turno',
    headerName: 'Turno',
    sortable: true,
    hideable: false,
    headerAlign: 'center',
    align: 'center',
    renderCell: (cellValues: GridRenderCellParams) => {
      if (cellValues.row.horaInicioTurno && cellValues.row.horaFimTurno) {
        const { startTimeFormat, endTimeFormat } = formatSpecialServiceShift(
          cellValues.row.horaInicioTurno,
          cellValues.row.horaFimTurno
        )

        return `De ${startTimeFormat} hrs até ${endTimeFormat} hrs`
      }
      return '--'
    }
  },
  {
    field: 'rastreadoresVinculados',
    headerName: 'Qtde. rastreador(es) vinculado(s)',
    sortable: true,
    hideable: false,
    headerAlign: 'center',
    align: 'center',
    renderCell: (cellValues: GridRenderCellParams) => {
      if (cellValues.value && cellValues.value.length > 0) {
        return cellValues.value
          .map((item: { identificador: string }) => {
            return `${('0000' + item.identificador).slice(-4)}`
          })
          .join(',')
      }
      return '--'
    }
  }
]

export const columns: GridColDef[] = columnsObject.map((colun) => {
  return {
    ...colun,
    field: `${colun.field}`,
    hideable: colun?.hideable,
    flex: 1,
    minWidth: 200,
    disableColumnMenu: true,
    renderCell: colun.renderCell
  }
})
