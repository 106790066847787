import { configureStore, combineReducers } from '@reduxjs/toolkit'
import AuthReducer from '../app/slices/authSlice'
import ContractReducer from '../app/slices/contractSlice'
import PermissionSlice from '../app/slices/permissionSlice'
import SectorImportSlice from '../app/slices/sectorImportSlice'

const rootReducer = combineReducers({
  authentication: AuthReducer,
  contract: ContractReducer,
  permission: PermissionSlice,
  importSector: SectorImportSlice
})

export const setupStore = (preloadedState: object = {}) => {
  return configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState
  })
}

export const store = setupStore()

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
