/* eslint-disable @typescript-eslint/no-use-before-define */
import { useState, useEffect } from 'react'
import Button from '../../components/Button'
import Input from '../../components/InputBase'
import Modal from '../../components/ModalBase'
import WarningModalExist from '../../components/WarningExistItemModal'

import { yupResolver } from '@hookform/resolvers/yup'
import { FieldError, SubmitHandler, useForm } from 'react-hook-form'

import { useSnackbar } from 'notistack'
import { useAppSelector } from '../../app/hooks'
import { selectCurrentContract } from '../../app/slices/contractSlice'
import { Box, Grid, Typography } from '@mui/material'
import { SpecialServiceT } from '../../types/SpecialService'
import { createSpecialService as ValidationCreate } from '../../utils/validations/specialServices'
import {
  createSpecialService,
  editSpecialService
} from '../../app/api/specialServices'
import TurnoInput from '../../components/TurnoShift'
import { userIsImpersonate } from '../../app/slices/authSlice'
import { colors } from '../../assets/variables'
import InputBase from '../../components/InputBase'
import { Option } from '../../types/Option'

import FilterTrackers from '../trackSweepOverview/components/filterTrackers'

interface SpecialServiceData {
  identificador: string
  descricao: string
  horaInicioTurno: string
  horaFimTurno: string
  codigoRastreadores: Option[]
  velocidadeMaximaParaConsiderarPonto?: number
}

export interface SpecialServiceProps {
  open: boolean
  close: () => void
  isEdit?: boolean
  specialService?: SpecialServiceT | null
}

export const SpecialService = ({
  open,
  close,
  isEdit,
  specialService
}: SpecialServiceProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const currentContract = useAppSelector(selectCurrentContract)
  const isImpersonate = useAppSelector(userIsImpersonate)

  const [isWarningOpen, setIsWarningOpen] = useState(false)
  const [warningArchived, setwarningArchived] = useState<boolean>(false)
  const [warningCode, setWarningCode] = useState<string>('')

  const { enqueueSnackbar } = useSnackbar()

  const { register, handleSubmit, formState, reset, setValue, getValues } =
    useForm<SpecialServiceData>({
      resolver: yupResolver(ValidationCreate)
    })
  const { errors } = formState

  const handleSaveSpecialService: SubmitHandler<SpecialServiceData> = async (
    values
  ) => {
    const params = {
      ...values,
      codigoContrato: currentContract?.codigo!,
      codigoRastreadores: values.codigoRastreadores.map((item) => item.value)
    }

    try {
      setLoading(true)
      const response = await createSpecialService(params)

      if (response.success) {
        enqueueSnackbar(response.message, {
          variant: 'success'
        })
        handleClose()
      } else {
        if (!response.message && response.data[0].mensagem) {
          if (response.data[0].entidadeValidacao) {
            setIsWarningOpen(true)
            setWarningCode(response.data[0].entidadeValidacao.codigo)
            setwarningArchived(
              response.data[0].entidadeValidacao.status !== 'Ativo'
            )
          } else if (response.data[0].mensagem) {
            enqueueSnackbar(response.data[0].mensagem, {
              variant: 'error'
            })
          }
        } else {
          enqueueSnackbar(response.message, {
            variant: 'error'
          })
          handleClose()
        }
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      enqueueSnackbar(
        'Ocorreu um erro interno. Comunique o administrador do sistema!',
        {
          variant: 'error'
        }
      )
      handleClose()
    }
  }

  const handleEditSpecialService: SubmitHandler<SpecialServiceData> = async (
    values
  ) => {
    const params = {
      ...values,
      codigo: specialService?.codigo!,
      codigoContrato: currentContract?.codigo!,
      codigoRastreadores: values.codigoRastreadores.map((item) => item.value)
    }

    try {
      setLoading(true)
      const response = await editSpecialService(params)

      if (response.success && response.data) {
        enqueueSnackbar(response.message, {
          variant: 'success'
        })
        handleClose()
      } else {
        if (!response.message && response.data[0].mensagem) {
          if (response.data[0].entidadeValidacao) {
            setIsWarningOpen(true)
            setWarningCode(response.data[0].entidadeValidacao.codigo)
            setwarningArchived(
              response.data[0].entidadeValidacao.status !== 'Ativo'
            )
          } else if (response.data[0].mensagem) {
            enqueueSnackbar(response.data[0].mensagem, {
              variant: 'error'
            })
          }
        } else {
          enqueueSnackbar(response.message, {
            variant: 'error'
          })
        }
        handleClose()
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      enqueueSnackbar(
        'Ocorreu um erro interno. Comunique o administrador do sistema!',
        {
          variant: 'error'
        }
      )
      handleClose()
    }
  }

  const handleClose = () => {
    setIsOpen(false)
    setIsWarningOpen(false)
    reset()
    close()
  }

  useEffect(() => {
    setIsOpen(open)
    if (isEdit) {
      reset({
        ...specialService,
        codigoRastreadores: specialService?.rastreadoresVinculados.map(
          (item) => {
            return {
              label: `${('0000' + item.identificador).slice(-4)}`,
              value: item.codigo
            } as Option
          }
        )
      })
    } else {
      reset({
        velocidadeMaximaParaConsiderarPonto: 120
      })
    }
  }, [open])

  return (
    <form
      onSubmit={handleSubmit(
        isEdit ? handleEditSpecialService : handleSaveSpecialService
      )}
    >
      <Modal
        title={
          !isEdit ? 'Cadastrar serviço especial' : 'Editar serviço especial'
        }
        open={isOpen}
        isLoading={loading}
        toggle={handleClose}
        fullWidth
        maxWidth="lg"
        footer={
          <Box display="flex" justifyContent="space-between" gap={1}>
            <Button
              disabled={loading}
              color="gray"
              onClick={() => {
                setIsOpen(false)
                close()
              }}
            >
              Cancelar
            </Button>
            <Button
              id="save-button"
              disabled={loading}
              color={isEdit ? 'primary' : 'success'}
              type="submit"
              onClick={handleSubmit(
                isEdit ? handleEditSpecialService : handleSaveSpecialService
              )}
            >
              {isEdit ? 'Salvar' : 'Cadastrar'}
            </Button>
          </Box>
        }
      >
        <Grid container spacing={0}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Input
                id="identificador"
                size="small"
                fullWidth
                label="Identificador"
                autoComplete="off"
                errorContent={errors.identificador as FieldError}
                {...register('identificador')}
              />
            </Grid>
            <Grid item xs={5}>
              <Input
                id="descricao"
                size="small"
                fullWidth
                label="Descrição"
                autoComplete="off"
                errorContent={errors.descricao as FieldError}
                {...register('descricao')}
              />
            </Grid>
            <Grid item xs={4}>
              <TurnoInput register={register} errors={errors} />
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12}>
              <FilterTrackers
                onFilteredList={(list: Option[]) => {
                  if (list.length === 0) {
                    setValue(`codigoRastreadores`, [])
                    return
                  }
                  setValue(`codigoRastreadores`, [...list])
                }}
                defaultValue={getValues('codigoRastreadores')}
              />
            </Grid>
          </Grid>

          {isImpersonate ? (
            <>
              <Grid item xs={12} md={12} mt={2}>
                <Typography
                  variant="subtitle1"
                  className="me-3"
                  sx={{
                    fontSize: '1rem',
                    fontFamily: 'Montserrat,sans-serif',
                    lineHeight: '1.4375em',
                    fontWeight: 400,
                    display: 'flex'
                  }}
                >
                  Configurações
                </Typography>
                <Box
                  display="flex"
                  justifyContent={'flex-start'}
                  alignItems={'center'}
                  flexDirection="column"
                  padding={2}
                  sx={{
                    border: 1,
                    borderRadius: 1,
                    borderColor: colors.gray200,
                    width: '100%',
                    maxHeight: 300,
                    overflow: 'auto',
                    px: 5,
                    py: 3,
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 2
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <InputBase
                        id="velocidadeMaximaParaConsiderarPonto"
                        size="small"
                        defaultValue="&nbsp;"
                        label="Velocidade máxima do ponto para considerar sua distancia espacial"
                        fullWidth
                        type="number"
                        autoComplete="off"
                        endAdornment={
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontSize: '12px',
                              justifyContent: 'center',
                              alignItems: 'center',
                              display: 'flex'
                            }}
                          >
                            (metros/minutos)
                          </Typography>
                        }
                        errorContent={
                          errors.velocidadeMaximaParaConsiderarPonto as FieldError
                        }
                        {...register('velocidadeMaximaParaConsiderarPonto')}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </>
          ) : null}
        </Grid>
      </Modal>
      <WarningModalExist
        isOpen={isWarningOpen}
        close={handleClose}
        routeRedirect={`/special-services/${warningCode}${
          warningArchived ? '/unarchive' : ''
        }`}
        archive={warningArchived}
        title="Serviço especial já existe"
      />
    </form>
  )
}
