import { ButtonHTMLAttributes, forwardRef } from 'react'
import { X } from 'react-feather'
import { StylesCloseProps, Wrapper } from './styles'

interface CloseProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    StylesCloseProps {}

const Close = forwardRef<HTMLButtonElement, CloseProps>(
  ({ size, ...rest }, ref) => {
    return (
      <Wrapper {...rest} ref={ref} size={size}>
        <X />
      </Wrapper>
    )
  }
)

Close.displayName = 'Close'

export default Close
