import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import ptBR from 'date-fns/locale/pt-BR'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker as DatePickerUI } from '@mui/x-date-pickers/DatePicker'
import { Control, Controller, FieldError } from 'react-hook-form'
import { FormHelperText } from '@mui/material'
import Input from '../InputBase'

export interface DatePickerProps {
  name: string
  label: string
  className?: string
  loading?: boolean
  disabled?: boolean
  control: Control<any>
  errorContent?: FieldError
  size?: 'small' | 'medium' | 'large'
  rules?: any
  maxDate?: Date
}

const DatePicker = ({
  name,
  label,
  className = 'mt-3',
  control,
  errorContent,
  size,
  disabled,
  rules,
  maxDate
}: DatePickerProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <>
            <DatePickerUI
              disabled={disabled}
              inputFormat="dd/MM/yyyy"
              label={label}
              className={className}
              value={field.value || null}
              onChange={field.onChange}
              maxDate={maxDate}
              renderInput={(params: any) => (
                <Input
                  {...params}
                  {...field}
                  size={size}
                  variant="outlined"
                  error={!!errorContent?.message}
                  disabled={disabled}
                />
              )}
            />
            {errorContent && (
              <FormHelperText error={!!errorContent?.message}>
                {errorContent?.message}
              </FormHelperText>
            )}
          </>
        )}
      />
    </LocalizationProvider>
  )
}
export default DatePicker
