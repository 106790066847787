import React from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { Paper } from '@mui/material'

interface VersionedItemProps {
  oldValue: string
  currentValue: string
}

const VersionedItem: React.FC<VersionedItemProps> = ({
  oldValue = '',
  currentValue = ''
}) => {
  const getHighlightedText = () => {
    const highlighted: React.ReactNode[] = []
    const maxLength = Math.max(oldValue.length, currentValue.length)

    for (let i = 0; i < maxLength; i++) {
      const oldChar = oldValue[i] || ''
      const newChar = currentValue[i] || ''

      if (oldChar !== newChar) {
        highlighted.push(
          <span style={{ backgroundColor: '#FFFF00' }} key={i}>
            {newChar}
          </span>
        )
      } else {
        highlighted.push(newChar)
      }
    }

    return highlighted
  }

  return (
    <ListItem>
      <ListItemText primary={getHighlightedText()} />
    </ListItem>
  )
}

interface VersionedListProps {
  isNew: boolean
  oldList: string[]
  currentValue: string[]
}

const VersionedList: React.FC<VersionedListProps> = ({
  isNew,
  oldList,
  currentValue
}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        gap: '0px',
        alignItems: 'center',
        width: '100%'
      }}
    >
      {isNew ? (
        <Paper
          style={{
            margin: '10px 0px',
            width: '100%',
            height: '450px',
            overflow: 'auto'
          }}
        >
          <List>
            {currentValue.map((item, index) => {
              return (
                <VersionedItem
                  key={index}
                  currentValue={item}
                  oldValue={oldList[index]}
                />
              )
            })}
          </List>
        </Paper>
      ) : (
        <Paper
          style={{
            margin: '10px 0px',
            width: '100%',
            height: '450px',
            overflow: 'auto'
          }}
        >
          <List>
            {oldList.map((item, index) => (
              <ListItem key={index}>
                <ListItemText primary={item} />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
    </div>
  )
}

export default VersionedList
