import {
  BatteryAlert,
  BatteryAlertRounded,
  BatteryCharging20,
  BatteryCharging30,
  BatteryCharging50,
  BatteryCharging60,
  BatteryCharging80,
  BatteryChargingFull
} from '@mui/icons-material'

interface BateryPercentIconI {
  percent: number
  percentToWarning: number
  percentToAlert: number
}

export const BateryPercentIcon = ({
  percent,
  percentToWarning,
  percentToAlert
}: BateryPercentIconI) => {
  let defaultColor:
    | 'success'
    | 'error'
    | 'warning'
    | 'disabled'
    | 'action'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'info' = 'success'
  if (percent < percentToAlert) {
    defaultColor = 'error'
  } else if (percent < percentToWarning) {
    defaultColor = 'warning'
  }

  switch (true) {
    case percent <= 20 && percent > 1:
      return <BatteryAlert color={defaultColor} />
    case percent >= 20 && percent <= 30:
      return <BatteryCharging20 color={defaultColor} />
    case percent >= 30 && percent <= 50:
      return <BatteryCharging30 color={defaultColor} />
    case percent >= 50 && percent <= 60:
      return <BatteryCharging50 color={defaultColor} />
    case percent >= 60 && percent <= 80:
      return <BatteryCharging60 color={defaultColor} />
    case percent >= 80 && percent <= 100:
      return <BatteryCharging80 color={defaultColor} />
    case percent === 100:
      return <BatteryChargingFull color={defaultColor} />
    default:
      return <BatteryAlertRounded color="disabled" />
  }
}
