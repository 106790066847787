import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { PagePermissionProps } from '../../types/PagePermission'

export interface PermissionsState {
  permissions: PermissionState[] | null
}

export interface PermissionState {
  pageName: string
  listPermission: PagePermissionProps
}

const initialState: PermissionsState = {
  permissions: null
}

export const permissionSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {
    setPermission: (state, action: PayloadAction<PermissionState[]>) => {
      return {
        ...state,
        permissions: action.payload
      }
    },
    cleanPermissions: (state) => {
      return {
        ...state,
        permissions: null
      }
    }
  }
})

export const { setPermission, cleanPermissions } = permissionSlice.actions

export const selectByPageName = (state: RootState, pageName: string) => {
  if (state.permission.permissions) {
    return (
      state.permission?.permissions.find(
        (route) => route.pageName === pageName
      ) ?? null
    )
  }
  return null
}

export default permissionSlice.reducer
