// Função auxiliar para converter graus em radianos
function toRad(degrees: number) {
  return degrees * (Math.PI / 180)
}

// Função para calcular a distância entre dois pontos usando latitude e longitude
function calculateDistance(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) {
  const R = 6371000 // Raio médio da Terra em metros
  const dLat = toRad(lat2 - lat1)
  const dLon = toRad(lon2 - lon1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const distancia = R * c
  return distancia
}

export function filterNextsPoints(points: string | any[], minDistance: number) {
  const filteredPoints = []

  for (let i = 0; i < points.length; i++) {
    const currentPoint = points[i]
    let hasClosePoints = false

    for (let j = i + 1; j < points.length; j++) {
      const nextPoint = points[j]
      const distance = calculateDistance(
        currentPoint.latitude,
        currentPoint.longitude,
        nextPoint.latitude,
        nextPoint.longitude
      )

      if (distance < minDistance) {
        hasClosePoints = true
        break
      }
    }

    if (!hasClosePoints) {
      filteredPoints.push(currentPoint)
    }
  }

  return filteredPoints
}
