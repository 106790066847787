import React from 'react'
import { Grid, Typography } from '@mui/material'
import { FieldError, UseFormRegister } from 'react-hook-form'
import Input from '../InputBase'

interface TurnoInputProps {
  register: UseFormRegister<any>
  errors: any
}

const TurnoInput: React.FC<TurnoInputProps> = ({ register, errors }) => {
  return (
    <Grid container spacing={0.5} alignItems="center">
      <Grid item>
        <Typography variant="subtitle2" fontSize={14}>
          Turno de:
        </Typography>
      </Grid>
      <Grid item>
        <Input
          id="horaInicioTurno"
          size="small"
          type="time"
          fullWidth
          autoComplete="off"
          errorContent={errors.horaInicioTurno as FieldError}
          {...register('horaInicioTurno')}
        />
      </Grid>
      <Grid item>
        <Typography variant="subtitle2" fontSize={14}>
          até
        </Typography>
      </Grid>
      <Grid item>
        <Input
          id="horaFimTurno"
          size="small"
          type="time"
          fullWidth
          autoComplete="off"
          errorContent={errors.horaFimTurno as FieldError}
          {...register('horaFimTurno')}
        />
      </Grid>
      <Grid item>
        <Typography variant="subtitle2" fontSize={14}>
          horas
        </Typography>
      </Grid>
    </Grid>
  )
}

export default TurnoInput
