import { lazy } from 'react'

import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer'
const ReturnButton = lazy(() => import('./ReturnButton'))
const LoadingReportGeneration = lazy(
  () => import('../../LoadingReportGeneration')
)

interface GenerationReportPdfI {
  fileName: string
  setDocumentPdf: React.Dispatch<any>
  setGenerationPdf: React.Dispatch<React.SetStateAction<boolean>>
  documentPdf: any
  onClick?: () => void
}

function GenerationReportPdf({
  fileName,
  setDocumentPdf,
  setGenerationPdf,
  documentPdf,
  onClick
}: GenerationReportPdfI) {
  return (
    <>
      <ReturnButton
        setDocumentPdf={setDocumentPdf}
        setGenerationPdf={setGenerationPdf}
        onClick={onClick}
      />
      <PDFDownloadLink document={documentPdf} fileName={fileName}>
        {({ loading: loadingPDF }) => {
          if (loadingPDF) {
            return <LoadingReportGeneration />
          } else {
            return null
          }
        }}
      </PDFDownloadLink>
      <PDFViewer showToolbar={true} width="100%" height="1200vh">
        {documentPdf}
      </PDFViewer>
    </>
  )
}

export default GenerationReportPdf
