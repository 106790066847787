import { colors } from './../../assets/variables'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
`

export const MapArea = styled.div`
  height: 100%;
  width: 100%;
  z-index: 99;
`
export const Loading = styled.div`
  background-color: white;
  color: ${colors.primary};
  display: flex;
  align-items: center;
  border-radius: 0.3rem;
  box-shadow: 0 0 10px ${colors.blackTransparent};
  position: absolute;
  left: 10px;
  bottom: 10px;
  padding: 10px 15px;
  z-index: 999;
`
