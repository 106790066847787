import { intervalToDuration } from 'date-fns'

export const compareDates = (
  startExecutionDate: string,
  endExecutionDate?: string
) => {
  let comparationEndDate = new Date()
  const executionStart = new Date(startExecutionDate)
  if (endExecutionDate) {
    comparationEndDate = new Date(endExecutionDate)
  }
  const { hours, minutes } = intervalToDuration({
    start: comparationEndDate,
    end: executionStart
  })
  return `${hours! < 10 ? 0 : ''}${hours}:${minutes! < 10 ? 0 : ''}${minutes}`
}
