import { lazy, useEffect, useState } from 'react'
import { Routes, Route } from 'react-router-dom'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// import { apmBase } from '@elastic/apm-rum'

import '../assets/scss/app.scss'
import api from '../app/api/api'
import { logout, selectUser, setUser } from '../app/slices/authSlice'
import { useAppSelector, useAppDispatch } from '../app/hooks'
import SpecialServices from '../pages/specialServices'
import SweepSpecialService from '../pages/sweepSpecialService'
import ModelTrackers from '../pages/modelTrackers'

const Wrapper = lazy(() => import('../components/Wrapper/index'))

const ProtectedRoute = lazy(() => import('./ProtectedRoute/ProtectedRoute'))
const Login = lazy(() => import('../pages/login'))
const Home = lazy(() => import('../pages/home'))
const NotAllowed = lazy(() => import('../pages/notAllowed'))
const RecoverPassword = lazy(() => import('../pages/recoverPassword'))
const Trackers = lazy(() => import('../pages/trackers'))
const Shifts = lazy(() => import('../pages/shifts'))
const AccessProfiles = lazy(() => import('../pages/accessProfiles'))
const Regions = lazy(() => import('../pages/regions'))
const ReportsConsolidatedSweeping = lazy(
  () => import('../pages/reportConsolidatedSweeping')
)
const MonitorIntegrationSlu = lazy(() => import('../pages/slu'))

const SectorImports = lazy(() => import('../pages/sectorImport'))
const AddImportSector = lazy(
  () => import('../pages/sectorImport/AddImport/addImportSector')
)
const SectorSchedulingImports = lazy(
  () => import('../pages/sectorSchedulingImport')
)
const AddSectorSchedulingImports = lazy(
  () =>
    import(
      '../pages/sectorSchedulingImport/AddImport/addImportSectorScheduling'
    )
)
const SweepOverview = lazy(() => import('../pages/sweepOverview'))
const SweepOverviewDetails = lazy(
  () => import('../pages/sweepOverview/details')
)
const CreateCompany = lazy(() => import('../pages/createCompany'))
const Impersonate = lazy(() => import('../pages/impersonate'))
const Sectors = lazy(() => import('../pages/sectors'))
const SectorsScheduling = lazy(() => import('../pages/sectorScheduling'))
const Terms = lazy(() => import('../pages/terms'))
const Users = lazy(() => import('../pages/users'))
const Error404 = lazy(() => import('../pages/error/404'))
const Contracts = lazy(() => import('../pages/contracts'))
const ReportTracker = lazy(() => import('../pages/trackSweepOverview'))

// apmBase.init({
//   serviceName: 'lutocar_front',
//   serverUrl: 'https://10.30.1.11:8200', // TODO: Após testes alterar para process.env.REACT_APP_LUTOCARFRONT_APM_URL
//   logLevel: 'info',
//   environment: 'dev',
//   distributedTracing: true,
//   distributedTracingOrigins: [
//     'http://localhost:3000',
//     'https://lutocar-dev.inlog.com.br/',
//     'https://lutocar.inlog.com.br/',
//     'https://api-lutocar-dev.inlog.com.br'
//   ]
// })

const App = () => {
  const dispatch = useAppDispatch()

  const user = useAppSelector(selectUser)
  const auth = localStorage.getItem('@lutocar-token')
  const authImpersonate = localStorage.getItem('@lutocar-token-impersonate')
  const [isAuth, setIsAuth] = useState(!!auth || !!authImpersonate)

  useEffect(() => {
    const checkAuthInfo = async () => {
      try {
        if ((auth || authImpersonate) && !user) {
          // TODO: Chamar o /me caso for ser criado
          const response = JSON.parse(
            localStorage.getItem('@lutocar-user-info')!
          )

          dispatch(setUser(response))
          setIsAuth(!!response)
        }
        if ((auth || authImpersonate) && user) {
          setIsAuth(true)
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Falha ao inicializar app', e)
      }
    }

    checkAuthInfo()
  }, [user])

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        dispatch(logout())
        window.location.reload()
      }
      return Promise.reject(error)
    }
  )

  return (
    <>
      <Routes>
        <Route
          index
          path="/login"
          element={
            <Wrapper>
              <Login />
            </Wrapper>
          }
        />
        <Route path="/recover-password" element={<RecoverPassword />}>
          <Route path=":token" element={<RecoverPassword />} />
          <Route path="" element={<RecoverPassword />} />
        </Route>
        <Route path="/not-allowed" element={<NotAllowed />} />
        <Route path="*" element={<Error404 />} />
        <Route
          path="impersonate"
          element={
            <ProtectedRoute isAuth={isAuth} skipPermition pathName="">
              <Wrapper>
                <Impersonate />
              </Wrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path="/create-company"
          element={
            <ProtectedRoute isAuth={isAuth} skipPermition pathName="">
              <Wrapper>
                <CreateCompany />
              </Wrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path="terms"
          element={
            <Wrapper>
              <Terms />
            </Wrapper>
          }
        />

        <Route
          index
          path="/"
          element={
            <ProtectedRoute isAuth={isAuth} skipPermition pathName="">
              <Wrapper showHeader>
                <Home />
              </Wrapper>
            </ProtectedRoute>
          }
        />
        {
          '' // TODO: CADASTROS
        }
        <Route
          path="/contracts"
          element={
            <ProtectedRoute isAuth={isAuth} pathName="contratos">
              <Wrapper showHeader>
                <Contracts />
              </Wrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path="/trackers"
          element={
            <ProtectedRoute isAuth={isAuth} pathName="rastreadores">
              <Wrapper showHeader>
                <Trackers />
              </Wrapper>
            </ProtectedRoute>
          }
        />
        <Route path="/users">
          <Route path=":codigo">
            <Route
              path=""
              element={
                <ProtectedRoute isAuth={isAuth} pathName="usuarios">
                  <Wrapper showHeader>
                    <Users />
                  </Wrapper>
                </ProtectedRoute>
              }
            />
            <Route
              path=":unarchive"
              element={
                <ProtectedRoute isAuth={isAuth} pathName="usuarios">
                  <Wrapper showHeader>
                    <Users />
                  </Wrapper>
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path=""
            element={
              <ProtectedRoute isAuth={isAuth} pathName="usuarios">
                <Wrapper showHeader>
                  <Users />
                </Wrapper>
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="/sectors">
          <Route path=":codigo">
            <Route
              path=""
              element={
                <ProtectedRoute isAuth={isAuth} pathName="setores">
                  <Wrapper showHeader>
                    <Sectors />
                  </Wrapper>
                </ProtectedRoute>
              }
            />
            <Route
              path=":unarchive"
              element={
                <ProtectedRoute isAuth={isAuth} pathName="setores">
                  <Wrapper showHeader>
                    <Sectors />
                  </Wrapper>
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path=""
            element={
              <ProtectedRoute isAuth={isAuth} pathName="setores">
                <Wrapper showHeader>
                  <Sectors />
                </Wrapper>
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="/regions">
          <Route path=":codigo">
            <Route
              path=""
              element={
                <ProtectedRoute isAuth={isAuth} pathName="regioes">
                  <Wrapper showHeader>
                    <Regions />
                  </Wrapper>
                </ProtectedRoute>
              }
            />
            <Route
              path=":unarchive"
              element={
                <ProtectedRoute isAuth={isAuth} pathName="regioes">
                  <Wrapper showHeader>
                    <Regions />
                  </Wrapper>
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path=""
            element={
              <ProtectedRoute isAuth={isAuth} pathName="regioes">
                <Wrapper showHeader>
                  <Regions />
                </Wrapper>
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="/shifts">
          <Route path=":codigo">
            <Route
              path=""
              element={
                <ProtectedRoute isAuth={isAuth} pathName="turnos">
                  <Wrapper showHeader>
                    <Shifts />
                  </Wrapper>
                </ProtectedRoute>
              }
            />
            <Route
              path=":unarchive"
              element={
                <ProtectedRoute isAuth={isAuth} pathName="turnos">
                  <Wrapper showHeader>
                    <Shifts />
                  </Wrapper>
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path=""
            element={
              <ProtectedRoute isAuth={isAuth} pathName="turnos">
                <Wrapper showHeader>
                  <Shifts />
                </Wrapper>
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="/access-profiles">
          <Route path=":codigo">
            <Route
              path=""
              element={
                <ProtectedRoute isAuth={isAuth} pathName="perfis-acesso">
                  <Wrapper showHeader>
                    <AccessProfiles />
                  </Wrapper>
                </ProtectedRoute>
              }
            />
            <Route
              path=":unarchive"
              element={
                <ProtectedRoute isAuth={isAuth} pathName="perfis-acesso">
                  <Wrapper showHeader>
                    <AccessProfiles />
                  </Wrapper>
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path=""
            element={
              <ProtectedRoute isAuth={isAuth} pathName="perfis-acesso">
                <Wrapper showHeader>
                  <AccessProfiles />
                </Wrapper>
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="/special-services">
          <Route path=":codigo">
            <Route
              path=""
              element={
                <ProtectedRoute isAuth={isAuth} pathName="servicos_especiais">
                  <Wrapper showHeader>
                    <SpecialServices />
                  </Wrapper>
                </ProtectedRoute>
              }
            />
            <Route
              path=":unarchive"
              element={
                <ProtectedRoute isAuth={isAuth} pathName="servicos_especiais">
                  <Wrapper showHeader>
                    <SpecialServices />
                  </Wrapper>
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path=""
            element={
              <ProtectedRoute isAuth={isAuth} pathName="servicos_especiais">
                <Wrapper showHeader>
                  <SpecialServices />
                </Wrapper>
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="/model-trackers">
          <Route path=":codigo">
            <Route
              path=""
              element={
                <ProtectedRoute isAuth={isAuth} pathName="model_tracker">
                  <Wrapper showHeader>
                    <ModelTrackers />
                  </Wrapper>
                </ProtectedRoute>
              }
            />
            <Route
              path=":unarchive"
              element={
                <ProtectedRoute isAuth={isAuth} pathName="model_tracker">
                  <Wrapper showHeader>
                    <ModelTrackers />
                  </Wrapper>
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path=""
            element={
              <ProtectedRoute isAuth={isAuth} pathName="model_tracker">
                <Wrapper showHeader>
                  <ModelTrackers />
                </Wrapper>
              </ProtectedRoute>
            }
          />
        </Route>
        <Route
          path="/sector-scheduling"
          element={
            <ProtectedRoute isAuth={isAuth} pathName="agendamento-setores">
              <Wrapper showHeader>
                <SectorsScheduling />
              </Wrapper>
            </ProtectedRoute>
          }
        />
        {
          '' // TODO: Sinótico e BI
        }
        <Route
          path="/tracker-sweep-overview"
          element={
            <ProtectedRoute isAuth={isAuth} pathName="relatorios">
              <Wrapper showHeader>
                <ReportTracker />
              </Wrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path="/reports-consolidated-sweeping"
          element={
            <ProtectedRoute isAuth={isAuth} pathName="relatorios">
              <Wrapper showHeader>
                <ReportsConsolidatedSweeping />
              </Wrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path="/sweeping"
          element={
            <ProtectedRoute isAuth={isAuth} pathName="sinotico-de-varricao">
              <Wrapper showHeader>
                <SweepOverview />
              </Wrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path="/sweep-special-services"
          element={
            <ProtectedRoute isAuth={isAuth} pathName="servicos_especiais">
              <Wrapper showHeader>
                <SweepSpecialService />
              </Wrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path="/sweeping/details"
          element={
            <ProtectedRoute isAuth={isAuth} pathName="sinotico-de-varricao">
              <Wrapper showHeader>
                <SweepOverviewDetails />
              </Wrapper>
            </ProtectedRoute>
          }
        >
          <Route
            path=":codigo"
            element={
              <ProtectedRoute isAuth={isAuth} pathName="sinotico-de-varricao">
                <Wrapper showHeader>
                  <SweepOverviewDetails />
                </Wrapper>
              </ProtectedRoute>
            }
          />
          <Route
            path=""
            element={
              <ProtectedRoute isAuth={isAuth} pathName="sinotico-de-varricao">
                <Wrapper showHeader>
                  <SweepOverviewDetails />
                </Wrapper>
              </ProtectedRoute>
            }
          />
        </Route>
        <Route
          path="/monitor-integration"
          element={
            <ProtectedRoute isAuth={isAuth} pathName="slu">
              <Wrapper showHeader>
                <MonitorIntegrationSlu />
              </Wrapper>
            </ProtectedRoute>
          }
        >
          <Route
            path=""
            element={
              <ProtectedRoute isAuth={isAuth} pathName="slu">
                <Wrapper showHeader>
                  <MonitorIntegrationSlu />
                </Wrapper>
              </ProtectedRoute>
            }
          />
        </Route>
        {
          '' // TODO: Importações
        }
        <Route
          path="/sector-scheduling-imports"
          element={
            <ProtectedRoute
              isAuth={isAuth}
              pathName="importacoes_agendamento_setores"
            >
              <Wrapper showHeader>
                <SectorSchedulingImports />
              </Wrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path="/sector-scheduling-imports/add"
          element={
            <ProtectedRoute
              isAuth={isAuth}
              pathName="importacoes_agendamento_setores"
            >
              <Wrapper showHeader>
                <AddSectorSchedulingImports />
              </Wrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path="/sector-imports"
          element={
            <ProtectedRoute isAuth={isAuth} pathName="importacoes_setores">
              <Wrapper showHeader>
                <SectorImports />
              </Wrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path="/sector-imports/add"
          element={
            <ProtectedRoute isAuth={isAuth} pathName="importacoes_setores">
              <Wrapper showHeader>
                <AddImportSector />
              </Wrapper>
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  )
}

export default App
