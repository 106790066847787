import { useEffect, useState } from 'react'
import { Grid, ConfirmArchive } from '../../components/index'
import { GridSortModel } from '@mui/x-data-grid'
import { useSnackbar } from 'notistack'

import { Wrapper } from './styles'
import { columns } from './columns'
import { useAppSelector } from '../../app/hooks'
import { selectCurrentContract } from '../../app/slices/contractSlice'
import { SpecialService } from './specialServices'
import { useParams } from 'react-router-dom'
import { selectByPageName } from '../../app/slices/permissionSlice'
import { Contract } from '../../types/Contract'
import {
  archiveSpecialService,
  listAllSpecialServices,
  readDetails,
  unarchiveSpecialService
} from '../../app/api/specialServices'
import { SpecialServiceT } from '../../types/SpecialService'

interface Row {
  columns: object[]
  id: string
  row: SpecialServiceT
}

export const SpecialServices = () => {
  const params = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const currentContract = useAppSelector(selectCurrentContract)

  const permissions = useAppSelector((state) =>
    selectByPageName(state, 'special-services')
  )

  // Modais
  const [openSpecialService, setOpenSpecialService] = useState(false)
  const [confirmArchive, setConfirmArchive] = useState(false)
  const [editRegion, setEditRegion] = useState(false)

  //  Grid
  const [loading, setLoading] = useState(true)
  const [archived, setArchived] = useState<boolean>(false)
  const [rows, setRows] = useState([{ id: '' }])
  const [specialServiceEdit, setSpecialServiceEdit] =
    useState<SpecialServiceT | null>(null)
  const [specialServiceArchive, setSpecialServiceArchive] =
    useState<SpecialServiceT | null>(null)
  const [order, setOrder] = useState<GridSortModel>([
    {
      field: 'CodigoContrato',
      sort: 'asc'
    }
  ])
  const [filter, setFilter] = useState<string>()

  // Paginação
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(30)

  // Ações
  const handleActions = async (action: string, row: Row | null) => {
    switch (action) {
      case 'add':
        setEditRegion(false)
        setOpenSpecialService(true)
        break
      case 'archived':
        setArchived(!archived)
        break
      case 'edit':
        // Limpa cabeçalho da linha para ordenar/filtrar
        delete row?.row.Descricao
        delete row?.row.Identificador

        setSpecialServiceEdit(row?.row as SpecialServiceT)
        setEditRegion(true)
        setOpenSpecialService(true)
        break
      case 'archive':
        setSpecialServiceArchive(row?.row as SpecialServiceT)
        setConfirmArchive(true)
        break
      case 'unarchive':
        setSpecialServiceArchive(row?.row as SpecialServiceT)
        setConfirmArchive(true)
    }
  }

  const handleOrder = async (model: GridSortModel) => {
    if (model.length === 0) {
      setOrder([
        {
          field: 'CodigoContrato',
          sort: 'asc'
        }
      ])
    } else {
      setOrder(model)
    }
  }

  const listSpecialServices = async (contract: Contract) => {
    if (contract) {
      setLoading(true)
      try {
        const response = await listAllSpecialServices({
          codigoContrato: contract?.codigo,
          arquived: archived,
          orderDirection: order[0].sort!,
          orderName: order[0].field,
          query: filter,
          take: rowsPerPage,
          skip: rowsPerPage * page
        })

        if (response.success && response.data) {
          const sepcialServicesRows = response.data.map(
            (sepcialServices: {
              codigo: any
              identificador: any
              descricao: any
            }) => {
              return {
                ...sepcialServices,
                id: `${sepcialServices.codigo}`,
                Identificador: `${sepcialServices.identificador}`,
                Descricao: `${sepcialServices.descricao}`,
                Turno: '--',
                'Qtde. rastreador(es) vinculado(s)': '2'
              }
            }
          )
          setRows(sepcialServicesRows)

          setTotal(response.count!)

          setLoading(false)
        } else {
          setLoading(false)
          enqueueSnackbar(
            response.message ??
              'Ocorreu um erro interno. Comunique o administrador do sistema!',
            {
              variant: 'error'
            }
          )
        }
      } catch (error: any) {
        setLoading(false)
        enqueueSnackbar(
          'Ocorreu um erro interno. Comunique o administrador do sistema!',
          {
            variant: 'error'
          }
        )
      }
    }
  }

  const readDetailsByCode = async (code: string) => {
    try {
      const response = await readDetails(code)

      if (response.success && response.data) {
        const data: SpecialServiceT = {
          ...response.data,
          codigo: response.data.codigo,
          codigoContrato: response.data.codigoContrato,
          identificador: response.data.identificador,
          descricao: response.data.descricao,
          status: response.data.status,
          horaFimTurno: response.data.horaFimTurno,
          horaInicioTurno: response.data.horaInicioTurno
        }
        if (params.unarchive && data.status !== 'Ativo') {
          setSpecialServiceArchive(data)
          setConfirmArchive(false)
        }
        setSpecialServiceEdit(data)
        setEditRegion(true)
        setOpenSpecialService(true)

        setLoading(false)
      } else {
        enqueueSnackbar(
          response.message ??
            'Ocorreu um erro interno. Comunique o administrador do sistema!',
          {
            variant: 'error'
          }
        )
      }
    } catch (error) {
      enqueueSnackbar(
        'Ocorreu um erro interno. Comunique o administrador do sistema!',
        {
          variant: 'error'
        }
      )
    }
  }

  useEffect(() => {
    if (params.codigo) {
      readDetailsByCode(params.codigo)
    }
  }, [params.codigo])

  useEffect(() => {
    if (!openSpecialService) {
      listSpecialServices(currentContract as Contract)
    }
  }, [
    archived,
    order,
    filter,
    page,
    rowsPerPage,
    openSpecialService,
    currentContract
  ])

  return (
    <Wrapper className="container">
      <Grid
        permissions={permissions?.listPermission}
        archive={archived}
        columns={columns}
        rows={rows ?? []}
        totalRows={total ?? 0}
        loading={loading}
        toolTipAddRow="Adicionar Serviço Especial"
        toolTipArchive="Arquivar Serviço Especial"
        toolTipEditRow="Editar Serviço Especial"
        toolTipUnarchive="Desarquivar Serviço Especial"
        searchPlaceHolder="Pesquisar por descrição"
        page={page}
        rowsPerPage={rowsPerPage}
        paginationMode="server"
        handlePageChange={(nextPage: number) => {
          setPage(nextPage)
        }}
        handleRowPerPageChange={(nextRowsPerPage: number) => {
          setRowsPerPage(nextRowsPerPage)
        }}
        onGridAction={(action, row) => handleActions(action, row as Row)}
        onSortChange={(model: GridSortModel) => handleOrder(model)}
        onFilterChange={(searchFilter) => setFilter(searchFilter)}
        initialState={{
          sorting: {
            sortModel: [{ field: 'Codigo', sort: 'asc' }]
          }
        }}
      />
      <SpecialService
        open={openSpecialService}
        close={() => setOpenSpecialService(false)}
        isEdit={editRegion}
        specialService={specialServiceEdit}
      />
      <ConfirmArchive
        runNow={
          !!params.unarchive &&
          openSpecialService &&
          specialServiceEdit?.status !== 'Ativo'
        }
        open={confirmArchive}
        close={() => {
          setConfirmArchive(false)
          listSpecialServices(currentContract as Contract)
        }}
        data={{
          code: specialServiceArchive?.codigo ?? null,
          status: specialServiceArchive?.status!
        }}
        screenName="Serviço Especial"
        serviceFunction={
          specialServiceArchive?.status === 'Ativo'
            ? archiveSpecialService
            : unarchiveSpecialService
        }
      />
    </Wrapper>
  )
}

export default SpecialServices
