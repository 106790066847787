import { View, Text, Image, StyleSheet } from '@react-pdf/renderer'
import { format } from 'date-fns'
import { colors } from '../../assets/variables'

const borderColor = colors.gray
const borderWidth = 0.5

const styles = StyleSheet.create({
  header_more_logos: {
    height: 50,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: 8,
    borderTop: 0,
    borderBottom: borderWidth,
    borderLeft: 0,
    borderRight: 0,
    borderColor: borderColor,
    BorderStyle: 'solid'
  },
  header_contract: {
    height: 30,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: 5,
    borderTop: 0,
    borderBottom: borderWidth,
    borderLeft: borderWidth,
    borderRight: borderWidth,
    borderColor: borderColor,
    BorderStyle: 'solid'
  },
  title_contract_header: {
    color: colors.gray900,
    fontWeight: 300,
    fontSize: 6,
    textAlign: 'center'
  },
  title_header: {
    color: colors.gray900,
    fontWeight: 300,
    fontSize: 10,
    textAlign: 'center'
  },
  image_header: {
    height: 40,
    width: 40,
    maxWidth: 40,
    maxHeight: 40,
    objectFit: 'contain',
    objectPosition: 'center',
    marginBottom: 5
  }
})

interface HeaderTitleAndImageI {
  data: {
    reportName: string
    concessionaireUrlPhoto?: string
    cityHallUrlPhoto?: string
    cityHallName?: string
  }
  isTracker?: boolean
}

export const HeaderTitleAndImage = ({
  data,
  isTracker = false
}: HeaderTitleAndImageI) => {
  let titleHeaderContract = ''
  if (data && data.cityHallName) {
    if (isTracker) {
      titleHeaderContract = `Contrato: ${data.cityHallName}`
    } else {
      titleHeaderContract = `À ${data.cityHallName}`
    }
  }
  return (
    <>
      <View style={styles.header_more_logos}>
        {data?.cityHallUrlPhoto ? (
          <Image
            src={data.cityHallUrlPhoto + '?noCache=' + Math.random().toString()}
            style={styles.image_header}
            cache={false}
          />
        ) : (
          <Text style={styles.image_header} />
        )}

        <Text style={styles.title_header}>{data?.reportName}</Text>

        {data?.concessionaireUrlPhoto ? (
          <Image
            src={
              data.concessionaireUrlPhoto +
              '?noCache=' +
              Math.random().toString()
            }
            style={styles.image_header}
            cache={false}
          />
        ) : (
          <Text style={styles.image_header} />
        )}
      </View>
      <View style={styles.header_contract}>
        <Text style={styles.title_contract_header}>{titleHeaderContract}</Text>
        <Text style={styles.title_contract_header}>
          Data/Hora Relatório: {format(new Date(), 'dd/MM/yyy HH:mm:ss')}
        </Text>
      </View>
    </>
  )
}
