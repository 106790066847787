import React from 'react'
import { StylesSpinnerProps } from './styles'
import logo from '../../assets/images/logo-inlog.png'
import { Box, CircularProgress, Typography } from '@mui/material'

const LoadingReportGeneration: React.FC<StylesSpinnerProps> = ({}) => {
  return (
    <Box
      sx={{
        p: 2,
        gap: 3,
        position: 'fixed',
        left: 0,
        top: 50,
        minWidth: '100vw',
        backgroundColor: '#fff',
        zIndex: 999
      }}
      className="container d-flex flex-column justify-content-center align-items-center vh-100"
    >
      <CircularProgress />
      <img src={logo} alt="" width="150" />
      <div className="d-flex align-items-center mb-2 gap-3">
        <Typography variant="h5">Gerando relatório</Typography>
      </div>
    </Box>
  )
}

export default LoadingReportGeneration
