import { useCallback, useState } from 'react'
import { listAllTrackers } from '../app/api/tracker'
import { Tracker } from '../types/Tracker'
import Response from '../app/api/Response'

interface Params {
  codigoContrato?: number | null | undefined
  take?: number
  skip?: number
  arquived?: boolean
  query?: string
}

type OnSuccess = ((response: Response<Tracker[]>) => void) | null
type OnError = ((err: any) => void) | null

const useTrackers = (onSuccess: OnSuccess = null, onError: OnError = null) => {
  const [data, setTrackers] = useState<Tracker[] | undefined>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<any>(null)

  const handleFetch = useCallback(
    async (params: Params) => {
      setLoading(true)
      try {
        const response = await listAllTrackers(params)
        if (response) {
          setTrackers(response.data)
          if (onSuccess) {
            onSuccess(response)
          }
        }
      } catch (err: any) {
        setError(err)
        if (onError) {
          onError(err)
        }
      } finally {
        setLoading(false)
      }
    },
    [onSuccess, onError]
  )

  return { handleFetch, data, loading, error }
}

export default useTrackers
