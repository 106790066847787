export function sortByDate(a: { dataHora: any }, b: { dataHora: any }) {
  const dateA = new Date(a?.dataHora ?? '').getTime()
  const dateB = new Date(b?.dataHora ?? '').getTime()
  if (dateA < dateB) {
    return -1
  } else if (dateA > dateB) {
    return 1
  }
  return 0
}

export function sortByOnlyDate(a: { date: any }, b: { date: any }) {
  const dateA = new Date(a?.date ?? '').getTime()
  const dateB = new Date(b?.date ?? '').getTime()
  if (dateA < dateB) {
    return -1
  } else if (dateA > dateB) {
    return 1
  }
  return 0
}
