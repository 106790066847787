import * as yup from 'yup'

export const createUserSchema = yup.object().shape({
  email: yup
    .string()
    .required('E-mail obrigatório')
    .email('E-mail inválido')
    .matches(
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
      'E-mail inválido'
    ),
  nome: yup.string().required('Nome é obrigatório'),
  codigoPerfilAcesso: yup
    .object()
    .required('Perfil de acesso é obrigatório')
    .test(
      'empty-profile-access',
      'Perfil de acesso é obrigatório',
      function (field) {
        if (field && field.value) {
          return true
        }
        return false
      }
    ),
  codigoContratos: yup
    .array()
    .required('Codigo de contrato é obrigatório')
    .test(
      'empty-profile-access',
      'Codigo de contrato é obrigatório',
      function (field) {
        if (field && field.length > 0) {
          return true
        }
        return false
      }
    )
})

export const editUserSchema = yup.object().shape({
  email: yup
    .string()
    .required('E-mail obrigatório')
    .email('E-mail inválido')
    .matches(
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
      'E-mail inválido'
    ),
  nome: yup.string().required('Nome é obrigatório'),
  codigoPerfilAcesso: yup
    .object()
    .nullable(true)
    .required('Perfil de acesso é obrigatório')
    .test(
      'empty-profile-access',
      'Perfil de acesso é obrigatório',
      function (field) {
        if (field && field.value) {
          return true
        }
        return false
      }
    ),
  codigoContratos: yup
    .array()
    .required('Codigo de contrato é obrigatório')
    .test(
      'empty-profile-access',
      'Codigo de contrato é obrigatório',
      function (field) {
        if (field && field.length > 0) {
          return true
        }
        return false
      }
    )
})

export const changePasswordSchema = yup.object().shape({
  senhaAtual: yup
    .string()
    .required('Senha atual é obrigatória')
    .min(6, 'No minimo 6 caracteres'),
  novaSenha: yup
    .string()
    .required('Nova senha é obrigatória')
    .min(6, 'No minimo 6 caracteres'),
  confirmarNovaSenha: yup
    .string()
    .required('Confirmação de senha é obrigatório')
    .oneOf([yup.ref('novaSenha'), null], 'As senhas tem que ser iguais')
})
