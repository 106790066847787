import { ResizeButton, ResizeGrid } from './style'
import { ExpandLess, ExpandMore } from '@mui/icons-material'

interface ResizeGridComponentI {
  resizeArea: number
  changeResizeArea: (area: number) => void
}

function ResizeGridComponent({
  resizeArea,
  changeResizeArea
}: ResizeGridComponentI) {
  return (
    <ResizeGrid>
      <ResizeButton
        disabled={resizeArea === 4}
        onClick={() => changeResizeArea(resizeArea + 1)}
      >
        <ExpandLess />
      </ResizeButton>
      <ResizeButton
        disabled={resizeArea === 1}
        onClick={() => changeResizeArea(resizeArea - 1)}
      >
        <ExpandMore />
      </ResizeButton>
    </ResizeGrid>
  )
}

export default ResizeGridComponent
