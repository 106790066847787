import { lazy, useEffect, useState } from 'react'
import { format } from 'date-fns'
import { Map } from '@inlog/inlog-maps/lib'

const Table = lazy(() => import('../../components/Grid/index'))
const OrganismMap = lazy(() => import('../../components/MapArea/index'))
const InputBase = lazy(() => import('../../components/InputBase/index'))

import { useSnackbar } from 'notistack'
import { useAppSelector } from '../../app/hooks'
import { selectByPageName } from '../../app/slices/permissionSlice'

import {
  FilterContainer,
  GridContainer,
  ListFilter,
  MapContainer,
  ViewDetails,
  Wrapper
} from './styles'
import { Option } from '../../types/Option'
import { mapHooks } from '../../map/index'
import { Search } from '@mui/icons-material'
import { selectCurrentContract } from '../../app/slices/contractSlice'
import { Box, Checkbox, Grid, InputAdornment, Typography } from '@mui/material'
import { FieldError, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { GridSortModel, GridToolbarColumnsButton } from '@mui/x-data-grid'
import { selectUser, userIsImpersonate } from '../../app/slices/authSlice'
import {
  combineDateAndTime,
  localToUtc,
  utcToLocal
} from '../../utils/utcDateTime'
import { setFilterColumnComplete } from './columns'
import { colors } from '../../assets/variables'

import { TrackerSweepOverview } from '../../types/TrackerSweepOverview'
import LoadingReportGeneration from '../../components/LoadingReportGeneration'
import ResizeGridComponent from '../../components/ResizeGrid'
import GenerationReportPdf from '../../components/Report/GenerationReportPdf'
import colorByIndex from '../../utils/colorByIndex'
import formatTime from '../sweepOverview/utilsSweepOverview/formatTime'
import renderPopup from '../sweepOverview/utilsSweepOverview/renderPopupMap'
import { SelectAutoComplete, SpinnerLoading } from '../../components'
import readSpecialServices from '../../utils/globalApis/readSpecialServices'
import {
  listAllSpecialServiceSweep,
  readHistorySpecialServiceByTrackerCode
} from '../../app/api/specialServiceSweep'
import { SweeSpecialServiceT } from '../../types/SpecialService'
import formatSpecialServiceShift from '../../utils/formatSpecialServiceShift'
import { isEqual } from 'lodash'
import ToolsHeader from './components/toolsHeader'
import ToolsListTrackers from './components/toolsListTrackers'
import FilterOtherExecution from './components/filterOtherExecution/filterOtherExecution'
import GenerationTimeControl from './components/generationTimeControl'
import { filterNextsPoints } from '../../utils/calculateOverlays'
import { AddMapEventZoomChangeMap } from '../../map/mapHooks'
import { getMinDistanceByZoom } from '../../utils/getMinDistanceByZoom'
import { ReportSweepingSpecialService } from '../../components/Report/ReportSweppingSpecialServices'
import { sortByDate, sortByOnlyDate } from '../../utils/sortByDate'
import { toFixed } from '../../utils/toFixedNumber'
import { compareDates } from '../../utils/compareDates'
import SliderRangePoints from '../sweepOverview/components/sliderRangePoints'
import getRangeTimeToFilter from '../../utils/getRangeTimeToFilter'
import { filterPoints } from '../sweepOverview/utilsSweepOverview/plotPointTracker'
import { sameDates } from '../../utils/sameDates'
import { getColorLocalStorage } from '../sweepOverview/components/getColorLocalStorage'
import { maxDateByDates, minDateByDates } from '../../utils/getDateByArrayDates'
import { pt } from 'date-fns/locale'

interface Row {
  columns: object[]
  id: string
  row: TrackerSweepOverview
}
interface ReportTrackerData {
  dataExecucao: string
  codigoSpecialServiceShift: Option
}

export interface FilterHeaderI {
  batery: {
    selected: { label: string; value: any }
    options: { label: string; value: any }[]
  }
}

export const SweepSpecialService = () => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const currentContract = useAppSelector(selectCurrentContract)
  const isImpersonate = useAppSelector(userIsImpersonate)
  const user = useAppSelector(selectUser)

  const [generationPdf, setGenerationPdf] = useState<boolean>(false)
  const [documentPdf, setDocumentPdf] = useState<any | null>(null)

  const [data, setData] = useState<any[]>([])
  const [filters, setFilters] = useState<any>(null)

  const permissions = useAppSelector((state) =>
    selectByPageName(state, 'sweeping')
  )

  const [dataExecucao, ___] = useState<Date>(new Date())

  const [showInfo, setShowInfo] = useState<boolean>(true)
  const [generationTime, setGenerationTime] = useState<boolean>(false)

  const [trackerList, setTrackerList] = useState<any[]>([])
  const [groupedTrackers, setGroupedTrackers] = useState<any[]>([])

  const [dateFilter, setDateFilter] = useState<any>(new Date())
  const [zoomMap, setZoomMap] = useState<number>(0)

  // Grid states
  const [resizeArea, setResizeArea] = useState(1)
  const [renderMap, setRenderMap] = useState(false)
  const [map, setMap] = useState<Map | null>(null)
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [loadingShapes, setLoadingShapes] = useState(false)

  // Serviço Especial
  const [specialServices, setSpecialServices] = useState<Option[]>([])
  const [searchSpecialServices, setSearchSpecialServices] = useState<string>('')
  const [loadingSpecialServices, setLoadingSpecialServices] = useState(false)
  const [countSpecialServices, setCountSpecialServices] = useState<
    number | null
  >(null)
  const [paginationSpecialServices, setPaginationSpecialServices] = useState(0)

  //  Grid
  const [columns, setColumns] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const [rows, setRows] = useState<any[]>([])
  const [filter, setFilter] = useState<string>('')
  const [filterBlur, setFilterBlur] = useState<boolean>(false)

  const [filterHeaderRows, setFilterHeaderRows] = useState<any[]>([])

  interface GroupState {
    filterRangeDate: number[] | null
    filterChanged: boolean
  }
  const [groupStates, setGroupStates] = useState<{ [key: string]: GroupState }>(
    {}
  )
  const [timeEnds, setTimeEnds] = useState<{ [group: string]: Date | null }>({})
  const [timeStarts, setTimeStarts] = useState<{
    [group: string]: Date | null
  }>({})

  const [filteredInfos, setFilteredInfos] = useState<any>()
  const [savedRanges, setSavedRanges] = useState<any[]>([])
  const [allDatesTracker, setAllDatesTracker] = useState<any[]>([])

  const {
    watch,
    getValues,
    formState: { errors },
    control
  } = useForm<ReportTrackerData>({})

  const [filterHeader, setFilterHeader] = useState<FilterHeaderI>({
    batery: {
      selected: { label: 'Todos', value: 0 },
      options: [{ label: 'Todos', value: 0 }]
    }
  })

  const bateryFilter = (row: any, currentFilter: FilterHeaderI) => {
    if (currentFilter.batery) {
      if (
        row.nivelDaBateria < currentFilter.batery?.selected?.value &&
        currentFilter.batery.selected?.value !== 0
      ) {
        return true
      }
      return false
    }
    return false
  }

  const removePinPointsMap = (removeId: string | undefined) => {
    mapHooks.removePoints(map, removeId ?? '')

    const item = rows.find((row) => row.id === removeId)
    mapHooks.RemoveAllOverlay(map, `${item.idRastreador}start-point`)
    mapHooks.RemoveAllOverlay(map, `${item.idRastreador}current-point`)

    mapHooks.removeGroupMarkers(
      map,
      `${item.idRastreador}-${item.servicoEspecial.codigo}`,
      'lutocar-point'
    )
  }

  function createElementMouseOver({ trackerCode, dateTime }: any) {
    const element = document.createElement('div')
    element.innerHTML = `${trackerCode}\n${utcToLocal(
      new Date(dateTime),
      'HH:mm'
    )}`
    element.style.cssText =
      'font-size: 12px;font-weight: 600; letter-spacing: 1px;color: black;height: 30px; width: 120px;text-align: center;display: flex;justify-content: center;align-items: center;transform: translateX(-60px) translateY(-38px); white-space: pre-line; line-height: 1'
    element.style.position = 'absolute'

    return element
  }

  function createElementClick({ dateTime }: any) {
    const element = document.createElement('div')
    element.innerHTML = `${utcToLocal(new Date(dateTime), 'HH:mm')}`
    element.style.cssText =
      'font-size: 12px;font-weight: 600; letter-spacing: 1px;color: black;height: 30px; width: 120px;text-align: center;display: flex;justify-content: center;align-items: center;transform: translateX(-60px) translateY(-38px); white-space: pre-line; line-height: 1'
    element.style.position = 'absolute'

    return element
  }

  const removePlotHistoryPoints = (trackerId: string, groupUuid: string) => {
    mapHooks.RemoveAllOverlay(map, `${trackerId}start-point`)
    mapHooks.RemoveAllOverlay(map, `${trackerId}current-point`)
    mapHooks.removeGroupPoints(map, groupUuid, 'lutocar-point')
  }

  const plotHistoryPoints = (
    props: {
      points: {
        bateria: number
        codigo: number
        codigoRastreador: number
        dataHora: string
        distanciaProximoPonto: number
        latitude: number
        longitude: number
        tempoParado: number
        tipoPonto: 'InicioDeDeslocamento'
        velocidadeMedia: number
      }[]
      index: number
      color: string
      trackerId: string
      specialServiceId?: string
      infos?: any
      currentIndex?: number[]
    }[],
    firstLoad?: boolean,
    newIndex?: {
      newIndex?: number[]
      groupKey: string
    }
  ) => {
    const allPoints: any[] = []
    let trackerSelected: string | null = null
    let trackerIndexSelected: number | null = null

    setLoadingShapes(true)
    setGenerationTime(false)

    const unifyItens = unifyObjects(props)
    const result = contructionPlots(unifyItens[0], newIndex)

    trackerList.forEach((tracker) => {
      const trackerId = tracker.codigo
      const specialServiceId = tracker.servicoEspecial.codigo
      const groupUuid = `${trackerId}${specialServiceId ? '-' : ''}${
        specialServiceId ? specialServiceId : ''
      }`
      if (!tracker.checked) {
        mapHooks.RemoveAllOverlay(map, `${trackerId}start-point`)
        mapHooks.RemoveAllOverlay(map, `${trackerId}current-point`)
        mapHooks.removeGroupPoints(map, groupUuid, 'lutocar-point')
      }
    })
    result?.trackers.forEach((track) => {
      const trackerId = track.codigo
      const specialServiceId = track.servicoEspecial.codigo
      const groupUuid = `${trackerId}${specialServiceId ? '-' : ''}${
        specialServiceId ? specialServiceId : ''
      }`
      mapHooks.RemoveAllOverlay(map, `${trackerId}start-point`)
      mapHooks.RemoveAllOverlay(map, `${trackerId}current-point`)

      mapHooks.removeGroupPoints(map, groupUuid, 'lutocar-point')
      const rangeTimeSortedBySpecialService = result.rangeTimeSorted.filter(
        (el) => el.specialServiceId === track.servicoEspecial.codigo
      )

      let currentRangeTime: number[] | null =
        groupStates[track.specialServices]?.filterRangeDate

      if (!currentRangeTime) {
        currentRangeTime = [0, rangeTimeSortedBySpecialService.length - 1]
      }

      if (
        newIndex &&
        newIndex?.newIndex &&
        newIndex?.groupKey === track.specialServices
      ) {
        currentRangeTime = newIndex?.newIndex
      }

      if (firstLoad && !newIndex) {
        currentRangeTime = [0, rangeTimeSortedBySpecialService.length - 1]
      }
      const newColor = getColorLocalStorage(track.code)
      if (newColor) {
        track.color = newColor
      }

      const pointsFiltered = filterPoints(
        track,
        currentRangeTime,
        rangeTimeSortedBySpecialService,
        isImpersonate,
        false,
        false,
        true
      )
      allPoints.push(pointsFiltered)
    })

    const allPointsFormat: any[] = [].concat(...allPoints)
    const trackerDateRanges: any = {}
    mapHooks.removeAllMarkers(map)
    mapHooks.RemoveMapEventClick(map)
    mapHooks.RemoveAllOverlay(map)

    allPointsFormat.forEach((item, index) => {
      const { dateTime } = item.object
      const { codigo } = item.tracker

      if (!trackerDateRanges[codigo]) {
        trackerDateRanges[codigo] = {
          minDate: dateTime,
          maxDate: dateTime,
          minIndex: index,
          maxIndex: index
        }
      } else {
        if (dateTime < trackerDateRanges[codigo].minDate) {
          trackerDateRanges[codigo].minDate = dateTime
          trackerDateRanges[codigo].minIndex = index
        }
        if (dateTime > trackerDateRanges[codigo].maxDate) {
          trackerDateRanges[codigo].maxDate = dateTime
          trackerDateRanges[codigo].maxIndex = index
        }
      }
    })
    allPointsFormat.forEach((item, mappingIndex) => {
      const minIndex = trackerDateRanges[item.tracker.codigo].minIndex
      const maxIndex = trackerDateRanges[item.tracker.codigo].maxIndex

      const isFirstOrLast =
        minIndex === mappingIndex || maxIndex === mappingIndex

      if (minIndex === mappingIndex) {
        const element = createElementMouseOver({
          trackerCode: item.tracker.label,
          dateTime: item.object.dateTime
        })
        mapHooks.RenderOverlay(
          map,
          element,
          [item?.latitudePlot!, item?.longitudePlot!],
          `${item.tracker.codigo}start-point`
        )
      } else if (maxIndex === mappingIndex) {
        const element = createElementMouseOver({
          trackerCode: item.tracker.label,
          dateTime: item.object.dateTime
        })
        mapHooks.RenderOverlay(
          map,
          element,
          [item?.latitudePlot!, item?.longitudePlot!],
          `${item.tracker.codigo}current-point`
        )
      }
      mapHooks.renderPointWithRadio({
        map,
        object: item.object,
        config: null,
        size: isFirstOrLast ? 5 : 5,
        borderColor: isFirstOrLast ? item.object.color : colors.white,
        color: isFirstOrLast ? colors.white : item.object.color,
        borderWidth: isFirstOrLast ? 3 : 1,
        type: 'lutocar-point',
        event: () => {
          if (item.tracker !== trackerSelected && trackerSelected !== null) {
            mapHooks.updateRadio(
              map,
              item.object.color,
              [item?.latitudePlot!, item?.longitudePlot!],
              10,
              false
            )
            trackerSelected = null
            trackerIndexSelected = null
            mapHooks.removePopup(map)
            mapHooks.RemoveAllOverlay(map)
          } else {
            trackerSelected = item?.tracker?.label
            trackerIndexSelected = item?.object?.index
            mapHooks.updateRadio(
              map,
              item.object.color,
              [item?.latitudePlot!, item?.longitudePlot!],
              10,
              true
            )

            const elementClick = createElementClick({
              dateTime: item.dataHora
            })
            mapHooks.RemoveAllOverlay(map)
            mapHooks.RenderOverlay(map, elementClick, [
              item?.latitudePlot!,
              item?.longitudePlot!
            ])

            mapHooks.AddMapEventClick(map, () => {
              mapHooks.RemoveMapEventClick(map)
              mapHooks.updateRadio(
                map,
                item.object.color,
                [item?.latitudePlot!, item?.longitudePlot!],
                10,
                false
              )
              mapHooks.removePopup(map)
              trackerSelected = null
              trackerIndexSelected = null
              mapHooks.RemoveAllOverlay(map)
            })
          }
        },
        rightClick: () => {
          trackerSelected = item?.tracker?.label
          trackerIndexSelected = item?.object?.index
          mapHooks.AddMapEventClick(map, () => {
            mapHooks.RemoveMapEventClick(map)
            mapHooks.removePopup(map)
            trackerSelected = null
            trackerIndexSelected = null
            mapHooks.RemoveAllOverlay(map)
          })
          renderPopup(
            map,
            item.tracker.label,
            item.point.dataHora,
            String(item.point.bateria),
            `${
              formatTime(item.point.tempoParado) === ''
                ? '00:00'
                : formatTime(item.point.tempoParado)
            }`,
            `${item.point.raio}`,
            item.latitudePlot!,
            item.longitudePlot!,
            item.point.tipoPonto,
            item.point.velocidadeMedia,
            item.point?.distanciaProximoPonto ?? 0
          )
        },
        mouseOver: () => {
          if (!isFirstOrLast) {
            if (trackerSelected === null && trackerIndexSelected === null) {
              mapHooks.RemoveAllOverlay(map)
              const elementMouse = createElementMouseOver({
                trackerCode: item.tracker.label,
                dateTime: item.object.dateTime
              })
              mapHooks.RenderOverlay(map, elementMouse, [
                item.latitudePlot!,
                item.longitudePlot!
              ])
            }
          }
        },
        mouseOut: () => {
          if (!isFirstOrLast) {
            if (trackerSelected === null && trackerIndexSelected === null) {
              mapHooks.RemoveAllOverlay(map)
            }
          }
        }
      })
    })

    const updatePoint = (signal: number) => {
      const hasPopUp = document.querySelector('.popup-info')
      const markersPloted = allPointsFormat
        .filter((el: any) => el.tracker.label === trackerSelected)
        .sort((a: any, b: any) => a.object.index - b.object.index)

      const selectedIndex = markersPloted.findIndex(
        (marker: any) => marker.object.index === trackerIndexSelected
      )

      const calculatedIndex =
        selectedIndex + signal < 0
          ? markersPloted.length - 1
          : selectedIndex + signal > markersPloted.length - 1
          ? 0
          : selectedIndex + signal

      const previusSelected = markersPloted[calculatedIndex]

      trackerSelected = previusSelected.tracker.label
      trackerIndexSelected = previusSelected.object.index

      mapHooks.updateRadio(
        map,
        previusSelected.object.color,
        [previusSelected?.latitudePlot!, previusSelected?.longitudePlot!],
        10,
        true
      )

      const element = createElementClick({
        trackerCode: previusSelected.tracker,
        dateTime: previusSelected.object.dateTime
      })

      mapHooks.RemoveAllOverlay(map)
      mapHooks.RenderOverlay(map, element, [
        previusSelected.latitudePlot!,
        previusSelected.longitudePlot!
      ])
      if (hasPopUp) {
        renderPopup(
          map,
          previusSelected.tracker.label,
          previusSelected.point.dataHora,
          String(previusSelected.point.bateria),
          `${
            formatTime(previusSelected.point.tempoParado) === ''
              ? '00:00'
              : formatTime(previusSelected.point.tempoParado)
          }`,
          `${previusSelected.point.raio}`,
          previusSelected.latitudePlot!,
          previusSelected.longitudePlot!,
          previusSelected.point.tipoPonto,
          previusSelected.point.velocidadeMedia,
          previusSelected.point?.distanciaProximoPonto ?? 0
        )
      }
    }

    window.addEventListener('keyup', (event) => {
      if (
        (event.key === 'ArrowLeft' ||
          event.keyCode === 37 ||
          event.key === 'ArrowUp' ||
          event.keyCode === 38) &&
        trackerSelected !== null &&
        trackerIndexSelected !== null
      ) {
        updatePoint(-1)
      }
      if (
        (event.key === 'ArrowRight' ||
          event.keyCode === 39 ||
          event.key === 'ArrowDown' ||
          event.keyCode === 40) &&
        trackerSelected !== null &&
        trackerIndexSelected !== null
      ) {
        updatePoint(+1)
      }
    })

    if (firstLoad) {
      map && map?.fitBoundsElements('lutocar-point')!
    }
    setLoadingShapes(false)
  }

  function unifyObjects(arr: any[]): any[] {
    const result: { [key: number]: any } = {}

    for (const item of arr) {
      if (result[item.codigoRastreador]) {
        result[item.codigoRastreador].points.push(...item.points)
        result[item.codigoRastreador].infos.push(item.infos)
      } else {
        result[item.codigoRastreador] = {
          points: [...item.points],
          codigoRastreador: item.codigoRastreador,
          infos: [item.infos]
        }
      }
    }
    return Object.values(result)
  }

  const contructionPlots = (
    props: {
      points: {
        bateria: number
        codigo: number
        codigoRastreador: number
        dataHora: string
        distanciaProximoPonto: number
        latitude: number
        longitude: number
        tempoParado: number
        tipoPonto: 'InicioDeDeslocamento'
        velocidadeMedia: number
      }[]
      index: number
      color: string
      trackerId: string
      specialServiceId?: string
      infos: any[]
      currentIndex?: number[]
    },
    newIndex?: {
      newIndex?: number[]
      groupKey: string
    }
  ) => {
    if (!props) {
      return
    }
    props.points.sort(sortByDate)
    const { rangeTimeSorted, endChange, startChange } = getRangeTimeToFilter({
      delta: false,
      executionDate: dateFilter,
      trackers: props.infos.map((item: any) => {
        return {
          ...item,
          pontosHistorico: props.points.filter(
            (el) => String(el.codigoRastreador) === item.codigo
          ),
          trackerCode: item.codigo,
          specialServiceId: item.servicoEspecial.codigo
        }
      }),
      timeEnd: undefined,
      timeEndShift: undefined,
      timeStart: undefined,
      timeStartShift: undefined
    })

    rangeTimeSorted.push(...savedRanges)
    rangeTimeSorted.sort(sortByOnlyDate)

    Object.keys(timeStarts).forEach((key) => {
      const specialServiceId = props.infos.find(
        (el) => el.specialServices === key
      ).servicoEspecial.codigo
      const rangeByService = rangeTimeSorted.filter(
        (el) => el.specialServiceId === specialServiceId
      )
      let date = new Date(
        `${localToUtc(new Date(dateFilter!), 'yyyy/MM/dd')} ${localToUtc(
          timeStarts[key]!,
          'HH:mm:ss'
        )}`
      )

      const equals = sameDates(
        date,
        rangeByService[rangeByService.length - 1].date
      )

      if (timeStarts[key]! > rangeByService[rangeByService.length - 1].date) {
        date.setDate(date.getDate() - 1)
      }

      if (!equals) {
        if (timeStarts[key] && (!newIndex || newIndex.groupKey !== key)) {
          if (date <= rangeByService[0].date) {
            if (!newIndex) {
              updateGroupState(key, {
                filterRangeDate: [0, rangeByService.length],
                filterChanged: groupStates[key as any]?.filterChanged
              })
            }
          }
          if (
            date <= rangeByService[0].date ||
            date >= rangeByService[rangeByService.length - 1].date
          ) {
            if (date >= rangeByService[rangeByService.length - 1].date) {
              const formatStartDate = date
              formatStartDate.setDate(formatStartDate.getDate() - 1)
              date = new Date(format(formatStartDate, 'yyyy/MM/dd HH:mm:ss'))
            }
            rangeTimeSorted.push({
              date: date,
              trackerId: 1,
              value: 0,
              roadSnap: false,
              specialServiceId: specialServiceId
            })
            savedRanges.push({
              date: date,
              trackerId: 1,
              value: 0,
              roadSnap: false,
              specialServiceId: specialServiceId
            })
            setSavedRanges(savedRanges)
          }
        }
      }
    })

    Object.keys(timeEnds).forEach((key) => {
      const specialServiceId = props.infos.find(
        (el) => el.specialServices === key
      ).servicoEspecial.codigo
      const rangeByService = rangeTimeSorted.filter(
        (el) => el.specialServiceId === specialServiceId
      )

      let date = new Date(
        `${localToUtc(new Date(dateFilter!), 'yyyy/MM/dd')} ${localToUtc(
          timeEnds[key]!,
          'HH:mm:ss'
        )}`
      )

      if (timeEnds[key]! < rangeByService[rangeByService.length - 1].date) {
        date.setDate(date.getDate() + 1)
      }

      const equals = sameDates(
        date,
        rangeByService[rangeByService.length - 1].date
      )

      if (!equals) {
        if (timeEnds[key] && (!newIndex || newIndex.groupKey !== key)) {
          if (date >= rangeByService[rangeByService.length - 1].date) {
            if (!newIndex) {
              updateGroupState(key, {
                filterRangeDate: [0, rangeByService.length],
                filterChanged: groupStates[key as any]?.filterChanged
              })
            }
          }
          if (
            date >= rangeByService[rangeByService.length - 1].date ||
            date <= rangeByService[0].date
          ) {
            if (date <= rangeByService[0].date) {
              const formatStartDate = date
              formatStartDate.setDate(formatStartDate.getDate() + 1)
              date = new Date(format(formatStartDate, 'yyyy/MM/dd HH:mm:ss'))
            }

            rangeTimeSorted.push({
              date: date,
              trackerId: 1,
              value: 0,
              roadSnap: false,
              specialServiceId: specialServiceId
            })
            savedRanges.push({
              date: date,
              trackerId: 1,
              value: 0,
              roadSnap: false,
              specialServiceId: specialServiceId
            })
            setSavedRanges(savedRanges)
          }
        }
      }
    })

    rangeTimeSorted.sort((a, b) => {
      if (a.specialServiceId && b.specialServiceId) {
        if (
          a.specialServiceId &&
          b.specialServiceId &&
          a.specialServiceId !== b.specialServiceId
        ) {
          return a.specialServiceId - b.specialServiceId
        } else {
          return a.date.getTime() - b.date.getTime()
        }
      } else {
        return a.date.getTime() - b.date.getTime()
      }
    })

    let previousSpecialServiceId: number | null = null
    let indexCounter = 0

    rangeTimeSorted.forEach((item) => {
      if (item.specialServiceId) {
        if (previousSpecialServiceId !== item.specialServiceId) {
          previousSpecialServiceId = item.specialServiceId
          indexCounter = 0
        }
      }
      item.value = indexCounter++
    })

    setAllDatesTracker(rangeTimeSorted)
    return {
      trackers: props.infos.map((item: any) => {
        return {
          ...item,
          pontosHistorico: props.points.filter(
            (el) => String(el.codigoRastreador) === item.codigo
          ),
          trackerCode: item.codigo,
          specialServiceId: item.servicoEspecial.codigo
        }
      }),
      rangeTimeSorted,
      endChange,
      startChange
    }
  }

  const handleFilterHeader = (filterHeaderChanged: any) => {
    setFilterHeader(filterHeaderChanged)

    setRows((prevRows) => {
      const filteredRows = prevRows.filter((row) => {
        const filterBatery = filterHeaderChanged.batery?.selected.value !== 0
        if (filterBatery) {
          if (bateryFilter(row, filterHeaderChanged)) {
            return row
          }
        } else if (filterBatery && bateryFilter(row, filterHeaderChanged)) {
          return row
        }
      })

      if (filteredRows.length > 0) {
        rows.forEach((row) => {
          removePinPointsMap(row.codigo)
        })
      }

      setFilterHeaderRows(filteredRows)
      return [...prevRows]
    })
  }

  const getPrintContextMap = async () => {
    mapHooks.removePopup(map)
    mapHooks.removePolylineByType(map, 'fixed-hover')
    mapHooks.removePolylineByType(map, 'complete-shape')
    mapHooks.removePolylineByType(map, 'hover-polyline')

    const image = await map?.takeMapScreenshot()
    return image
  }

  const handleGenerateReports = async () => {
    setGenerationPdf(true)
    await new Promise((resolve) => setTimeout(resolve, 1000))
    const contextMap = await getPrintContextMap()

    const sortedTrackers = trackerList.reduce((result, tracker) => {
      const codigo = tracker?.servicoEspecial?.codigo

      if (!codigo) {
        return result
      }

      if (!result[codigo]) {
        result[codigo] = []
      }

      result[codigo].push(tracker)
      return result
    }, {})

    const separatedTrackers = Object.values(sortedTrackers).flatMap(
      (group) => group
    )

    const reportData = {
      dataOperacao: new Date(dateFilter),
      contrato: trackerList[0].contrato.razaoSocial,
      rastreadores: separatedTrackers.map((item: any) => {
        let hasPoints = false
        let duringTime
        let distanceSum

        const points = data[item.id]
        if (points && points.length > 0) {
          hasPoints = true
          points.sort(sortByDate)
          distanceSum = points.reduce(
            (acc: any, cur: { distanciaProximoPonto: any }) =>
              acc + cur.distanciaProximoPonto,
            0
          )

          duringTime = compareDates(
            points[0].dataHora,
            points[points.length - 1].dataHora
          )
        }

        return {
          color: item.color,
          idRastreador: item.idRastreador,
          specialService: item.specialServices,
          distance: distanceSum ? `${toFixed(distanceSum, 2)} m` : '---',
          duringTime: duringTime ?? '--:--',
          timeStartPoint: hasPoints
            ? utcToLocal(new Date(points[0].dataHora), 'HH:mm:ss')
            : '--:--:--',
          timeEndPoint: hasPoints
            ? utcToLocal(
                new Date(points[points.length - 1].dataHora),
                'HH:mm:ss'
              )
            : '--:--:--'
        }
      }),
      urlContratoLogo: trackerList[0].contrato.logomarca,
      urlPrefeituraLogo: trackerList[0].prefeitura.logomarca,
      prefeitura: trackerList[0].prefeitura.razaoSocial,
      observation:
        'Os valores de medida em metros do setor e trechos de rua exibidos são aproximados'
    }

    setDocumentPdf(
      <ReportSweepingSpecialService
        reportName="Relatório de Serviços Especiais"
        headerInfos={reportData}
        contextMapScreen={contextMap}
      />
    )
    setGenerationPdf(false)
  }

  const handleActions = async (action: string, row: Row | null) => {
    switch (action) {
      case 'detailsSweepOverview':
        navigate(`/sweeping/details/${row?.id}`)
        break
    }
  }

  const currentDimensions = JSON.parse(
    localStorage.getItem('dimensions') ?? '{}'
  )

  const pathname = location.pathname.split('/')[1]

  const changeResizeArea = (area: number) => {
    setResizeArea(area)
    localStorage.setItem(
      'dimensions',
      JSON.stringify({
        ...currentDimensions,
        [pathname]: {
          ...currentDimensions[pathname],
          height: area
        }
      })
    )
  }

  const resizeMapNothingSelectRow = (selectedRows: string[]) => {
    if (!selectedRows.length) {
      setRenderMap(false)
    }
  }

  const onSelectRow = async (selectedRows: any) => {
    setLoadingShapes(true)
    if (selectedIds.length < selectedRows.length) {
      try {
        setRenderMap(true)
        let currentMap = map
        if (!currentMap && currentContract !== null) {
          const keyGoogle =
            currentContract.codigo === 0
              ? user?.contratos?.find((c) => c.chaveGoogle)?.chaveGoogle
              : currentContract?.chaveGoogle?.trim()
              ? String(currentContract?.chaveGoogle)
              : null
          currentMap = await mapHooks.loadMap(
            'map-main',
            keyGoogle ? String(keyGoogle) : null
          )
          setMap(currentMap)
        }
        setLoadingShapes(false)
      } catch (err) {
        enqueueSnackbar(
          'Ocorreu um erro interno. Comunique o administrador do sistema!',
          {
            variant: 'error'
          }
        )
      }
    } else {
      const deselectedId = selectedIds.filter(
        (item) => !selectedRows.includes(item)
      )
      deselectedId.forEach((id) => {
        removePinPointsMap(id)
      })

      setTrackerList(
        trackerList.filter((item) => !deselectedId.includes(item.id))
      )

      setLoadingShapes(false)
    }
    resizeMapNothingSelectRow(selectedRows)
    setSelectedIds(selectedRows)

    const dataToSelectedIds = rows
      .filter((item) => {
        if (selectedRows.includes(item.id)) {
          return item
        }
      })
      .filter((el) => el !== null)

    dataToSelectedIds.forEach((item, index) => {
      if (!trackerList.some((tracker: any) => tracker.id === item.id)) {
        setTrackerList((prevTrackerList: any) => [
          ...prevTrackerList,
          {
            ...item,
            label: item.idRastreador,
            groupUuid: item.id,
            color: item.color,
            checked: true
          }
        ])
      } else {
        setTrackerList((prevTrackerList: any[]) => {
          const updatedTrackerList = [...prevTrackerList]
          updatedTrackerList[index] = {
            ...updatedTrackerList[index],
            checked: true
          }
          return updatedTrackerList
        })
      }
    })
  }

  const handleFilterExecution = async (props: any) => {
    try {
      const filteredInfosArray = [] as any[]

      const infosSpecialServices = props.dataToSelectedIds.map((item: any) => {
        const currentDate = props.dateFilter ? props.dateFilter : new Date()
        let startTime = item.servicoEspecial.horaInicioTurno
        let endTime = item.servicoEspecial.horaFimTurno

        if (timeStarts[item.specialServices]) {
          startTime = format(timeStarts[item.specialServices]!, 'HH:mm:ss')
        }
        if (timeEnds[item.specialServices]) {
          endTime = format(timeEnds[item.specialServices]!, 'HH:mm:ss')
        }
        const startDateShift = combineDateAndTime(
          format(currentDate, 'yyyy-MM-dd'),
          new Date(`${format(new Date(), 'yyyy-MM-dd')} ${startTime}`)
        )
        const endDateShift = combineDateAndTime(
          format(currentDate, 'yyyy-MM-dd'),
          new Date(`${format(new Date(), 'yyyy-MM-dd')} ${endTime}`)
        )
        let startDateCompare: Date | undefined = undefined
        let endDateCompare: Date | undefined = undefined
        if (props.startTime) {
          startDateCompare = new Date(props.startTime)
        }
        if (props.endTime) {
          endDateCompare = new Date(props.endTime)
        }

        if (endDateCompare instanceof Date) {
          if (allDatesTracker[0]?.date) {
            const firstDateInMap = new Date(
              utcToLocal(allDatesTracker[0]?.date, 'yyyy/MM/dd HH:mm:ss')
            )

            if (
              endDateCompare.getHours() < firstDateInMap.getHours() ||
              (endDateCompare.getHours() === firstDateInMap.getHours() &&
                endDateCompare.getMinutes() < firstDateInMap.getMinutes())
            ) {
              endDateCompare.setDate(endDateCompare.getDate() + 1)
            }
          }
        }
        if (startDateCompare instanceof Date) {
          if (allDatesTracker[allDatesTracker.length - 1]?.date) {
            const lastDateInMap = new Date(
              utcToLocal(
                allDatesTracker[allDatesTracker.length - 1]?.date,
                'yyyy/MM/dd HH:mm:ss'
              )
            )
            const dateFormat = new Date(
              localToUtc(startDateCompare, 'yyyy/MM/dd HH:mm:ss')
            )
            if (
              dateFormat.getHours() > lastDateInMap.getHours() ||
              (dateFormat.getHours() === lastDateInMap.getHours() &&
                dateFormat.getMinutes() > lastDateInMap.getMinutes())
            ) {
              startDateCompare.setDate(startDateCompare.getDate() - 1)
            }
          }
        }

        const dates: Date[] = []

        if (startDateShift) {
          dates.push(new Date(startDateShift))
        }
        if (endDateShift) {
          dates.push(new Date(endDateShift))
        }
        if (props.startTime || startDateCompare) {
          dates.push(startDateCompare ?? new Date(props.startTime))
        }
        if (props.endTime || endDateCompare) {
          dates.push(endDateCompare ?? new Date(props.endTime))
        }

        const startDate = new Date(
          format(minDateByDates(dates), 'yyyy/MM/dd HH:mm:ss', {
            locale: pt
          })
        )
        const endDate = new Date(
          format(maxDateByDates(dates), 'yyyy/MM/dd HH:mm:ss', {
            locale: pt
          })
        )

        const startShiftDate = localToUtc(startDate, 'yyyy/MM/dd HH:mm:ss')
        const endShiftDate = localToUtc(endDate, 'yyyy/MM/dd HH:mm:ss')

        return {
          ...item,
          trackerId: item.codigo,
          dateFilter: format(currentDate, 'yyyy-MM-dd'),
          startTime: startShiftDate,
          endTime: endShiftDate,
          speedLimit: item.servicoEspecial.velocidadeMaximaParaConsiderarPonto
        }
      })

      const promises = infosSpecialServices
        .filter((el: any) => el.checked)
        .map((infos: any, index: number) => {
          if (filters) {
            const groupUuid = `${infos.trackerId}-${infos.servicoEspecial.codigo}`
            const filtersAreSame = isEqual(filters[groupUuid as any], infos)

            if (filtersAreSame) {
              const filteredData = data[groupUuid as any]
              const filterInfos = filters[groupUuid as any]
              const row = rows.find((item) => item.codigo === infos.trackerId)

              filteredInfosArray.push({
                points: filteredData,
                index: filterInfos.trackerIndex,
                trackerId: row.idRastreador,
                color: row.color,
                specialServiceId: infos.servicoEspecial.codigo,
                infos
              })
              return
            }
          }

          return new Promise((resolve, reject) => {
            readHistorySpecialServiceByTrackerCode(
              Number(infos.trackerId),
              infos.startTime,
              infos.endTime,
              infos.speedLimit
            )
              .then((result) => {
                const row = rows.find((item) => item.id === infos.groupUuid)
                resolve({
                  result: result,
                  trackerId: row.idRastreador,
                  specialServiceId: row.servicoEspecial.codigo,
                  color: row.color,
                  trackerIndex: index,
                  infos
                })
              })
              .catch((error) => {
                reject(error)
              })
          })
        })

      Promise.all(promises.filter((el: any) => el !== undefined))
        .then((results: any[]) => {
          results.forEach((result: any) => {
            const {
              result: promiseResult,
              trackerId,
              specialServiceId,
              trackerIndex,
              color,
              infos
            } = result

            setFilters((f: any) => {
              return {
                ...f,
                [`${infos.trackerId}-${specialServiceId}`]: infos
              }
            })
            if (promiseResult.data.length > 0) {
              setData((d: any) => {
                return {
                  ...d,
                  [`${infos.trackerId}-${specialServiceId}`]: promiseResult.data
                }
              })
            }
            if (promiseResult.data.length > 0) {
              filteredInfosArray.push({
                points: promiseResult.data,
                index: trackerIndex,
                color,
                trackerId,
                specialServiceId,
                infos
              })
            }
          })
        })
        .catch((error) => {
          enqueueSnackbar('Erro ao filtrados rastreadores!', {
            variant: 'error'
          })
          console.log(error)
        })
        .finally(() => {
          setLoading(false)
          setLoadingShapes(false)
          if (filteredInfosArray.length > 0) {
            setFilteredInfos(filteredInfosArray)
            setAllDatesTracker([])
            plotHistoryPoints(filteredInfosArray, true)
          } else {
            plotHistoryPoints(filteredInfos, true)
          }
        })
    } catch (error) {
      console.error(error)
      enqueueSnackbar('Erro ao filtrados rastreadores!', {
        variant: 'error'
      })
    }
  }

  useEffect(() => {
    if (currentContract !== null && documentPdf === null) {
      const keyGoogle =
        currentContract.codigo === 0
          ? user?.contratos?.find((c) => c.chaveGoogle)?.chaveGoogle
          : currentContract?.chaveGoogle?.trim()
          ? String(currentContract?.chaveGoogle)
          : null
      if (selectedIds.length) {
        mapHooks
          .loadMap('map-main', keyGoogle ? String(keyGoogle) : null)
          .then((currentMap: any) => {
            mapHooks.renderRadio(currentMap, [0, 0])
            setMap(currentMap)
          })
      }
    }
  }, [currentContract, documentPdf])

  const plotTracker = () => {
    setLoadingShapes(true)
    const filtersHandleExecution = trackerList.map((item: any) => {
      return {
        dateFilter: dateFilter ?? format(new Date(), 'yyyy-MM-dd'),
        startTime: new Date(
          `${format(new Date(), 'yyyy-MM-dd')} ${
            item.servicoEspecial.horaInicioTurno
          }`
        ),
        endTime: new Date(
          `${format(new Date(), 'yyyy-MM-dd')} ${
            item.servicoEspecial.horaFimTurno
          }`
        )
      }
    })

    if (trackerList.length > 0) {
      handleFilterExecution({
        dateFilter: filtersHandleExecution[0].dateFilter,
        startTime: null,
        endTime: null,
        dataToSelectedIds: trackerList
      })
      AddMapEventZoomChangeMap(map, () => {
        const zoom = map?.getZoom()
        setZoomMap(zoom ?? 0)
      })
    }
    setLoadingShapes(false)
  }

  useEffect(() => {
    setLoadingShapes(true)
    plotTracker()
    groupedTrackerList() // Checkbox dos rastreadores.
    setTimeout(() => {
      map && map?.fitBoundsElements('lutocar-point')!
      setLoadingShapes(false)
    }, 600)
  }, [trackerList])

  useEffect(() => {
    if (map) {
      if (currentDimensions?.[pathname]?.height) {
        setResizeArea(currentDimensions[pathname].height)
      } else {
        setResizeArea(3)
      }
      setLoading(false)
      setLoadingShapes(true)
    } else {
      setLoading(true)
      setLoadingShapes(true)
      setResizeArea(1)
    }
  }, [map])

  useEffect(() => {
    groupedTrackerList()
  }, [loadingShapes, data, timeStarts, timeEnds, groupStates])

  const handleTimeEndChange = (group: string, value: Date | null) => {
    setTimeEnds((prevTimeEnds) => ({
      ...prevTimeEnds,
      [group]: value
    }))
  }
  const handleTimeStartChange = (group: string, value: Date | null) => {
    setTimeStarts((prevTimeStarts) => ({
      ...prevTimeStarts,
      [group]: value
    }))
  }

  const updateGroupState = (groupKey: string, newState: GroupState) => {
    setGroupStates((prevGroupStates) => ({
      ...prevGroupStates,
      [groupKey]: newState
    }))
  }

  const groupedTrackerList = () => {
    const groupTrackers = trackerList.reduce((groups, tracker) => {
      const groupKey = tracker.specialServices
      if (!groups[groupKey]) {
        groups[groupKey] = []
      }
      groups[groupKey].push(tracker)
      return groups
    }, {})

    const groupedList = Object.keys(groupTrackers).map((groupKey) => {
      const currentSpecialServiceId = groupTrackers[groupKey].map(
        (item: any) => item.servicoEspecial.codigo
      )[0]

      return (
        <div key={groupKey}>
          <Box mt={0.5} className="d-flex align-items-center py-1">
            {loadingShapes ? (
              <Box
                sx={{
                  padding: '0 10px 0 0'
                }}
              >
                <SpinnerLoading />
              </Box>
            ) : (
              <Checkbox
                sx={{
                  padding: '0 10px 0 0'
                }}
                disabled={false}
                checked={groupTrackers[groupKey as any].some(
                  (item: any) => item.checked
                )}
                onChange={() => {
                  setTrackerList((prevTrackerList: any[]) => {
                    const updatedTrackerList = [...prevTrackerList]
                    const trackers = groupTrackers[groupKey as any]
                    trackers.forEach((element: any) => {
                      const indexTracker = updatedTrackerList.findIndex(
                        (item: any) => item.id === element.id
                      )
                      updatedTrackerList[indexTracker] = {
                        ...updatedTrackerList[indexTracker],
                        checked: !groupTrackers[groupKey as any].some(
                          (item: any) => item.checked
                        )
                      }
                    })
                    return updatedTrackerList
                  })
                  if (
                    groupTrackers[groupKey as any].some(
                      (item: any) => item.checked
                    )
                  ) {
                    groupTrackers[groupKey as any].forEach((element: any) => {
                      const groupUuid = `${element.label}${
                        element.servicoEspecial.codigo ? '-' : ''
                      }${
                        element.servicoEspecial.codigo
                          ? element.servicoEspecial.codigo
                          : ''
                      }`
                      removePlotHistoryPoints(element.label, groupUuid)
                    })
                  } else {
                    plotTracker()
                  }
                }}
              />
            )}
            <Typography
              variant="subtitle2"
              fontWeight={400}
              sx={{
                width: '100%'
              }}
            >
              {groupKey}
            </Typography>
          </Box>

          <Box display="flex" flexDirection={'row'} flexWrap={'wrap'}>
            {groupTrackers[groupKey as any].map(
              (tracker: any, index: number) => (
                <ToolsListTrackers
                  key={index}
                  loadingHistoryPoints={loadingShapes}
                  tracker={tracker}
                  hasPoints={
                    data[tracker.id] ? !!data[tracker.id].length : false
                  }
                  onClick={() => {
                    setTrackerList((prevTrackerList: any[]) => {
                      const updatedTrackerList = [...prevTrackerList]
                      const indexTracker = updatedTrackerList.findIndex(
                        (item: any) => item.id === tracker.id
                      )
                      updatedTrackerList[indexTracker] = {
                        ...updatedTrackerList[indexTracker],
                        checked: !tracker.checked
                      }
                      return updatedTrackerList
                    })
                    if (tracker.checked) {
                      const groupUuid = `${tracker.label}${
                        tracker.servicoEspecial.codigo ? '-' : ''
                      }${
                        tracker.servicoEspecial.codigo
                          ? tracker.servicoEspecial.codigo
                          : ''
                      }`
                      removePlotHistoryPoints(tracker.label, groupUuid)
                    } else {
                      plotTracker()
                    }
                  }}
                />
              )
            )}
          </Box>

          {groupTrackers[groupKey as any].some(
            (tracker: any) => tracker.checked
          ) ? (
            <SliderRangePoints
              specialService
              isImpersonate={isImpersonate}
              someChecked={groupTrackers[groupKey as any].some(
                (item: { id: string | number }) =>
                  !!data[item.id as any]?.length
              )}
              trackers={groupTrackers[groupKey as any].map((item: any) => {
                return {
                  ...item, // TODO: Vericicar aqui para adicionar todos os rastreadores do grupo.
                  pontosHistorico: allDatesTracker.filter(
                    (el) => el.trackerId === item.codigo
                  ),
                  trackerCode: item.codigo
                }
              })}
              allDatesTracker={allDatesTracker.filter(
                (el) => el.specialServiceId === currentSpecialServiceId
              )}
              filterRangeDate={
                groupStates[groupKey as any]?.filterRangeDate ?? [
                  0,
                  allDatesTracker.filter(
                    (el) => el.specialServiceId === currentSpecialServiceId
                  ).length - 1
                ]
              }
              setFilterChanged={(value) => {
                updateGroupState(groupKey, {
                  filterChanged: value.valueOf() as boolean,
                  filterRangeDate: groupStates[groupKey as any]?.filterRangeDate
                })
              }}
              setFilterRangeDate={(value) => {
                if (value) {
                  updateGroupState(groupKey, {
                    filterRangeDate: value.valueOf() as number[],
                    filterChanged: groupStates[groupKey as any]?.filterChanged
                  })
                }
              }}
              setSavedFilterRangeDate={() => {
                // todo: nada
                // console.log(value)
              }}
              executionDate={format(dateFilter, 'yyyy-MM-dd')}
              timeEnd={timeEnds[groupKey] ?? ''}
              setTimeEnd={(endTime) => {
                if (endTime instanceof Date) {
                  handleTimeEndChange(groupKey, endTime)
                }
              }}
              timeStart={timeStarts[groupKey] ?? ''}
              setTimeStart={(startTime) => {
                if (startTime instanceof Date) {
                  handleTimeStartChange(groupKey, startTime)
                }
              }}
              getRangeTimeToFilter={() => {
                const { rangeTimeSorted } = getRangeTimeToFilter({
                  delta: false,
                  executionDate: dateFilter,
                  trackers: groupTrackers[groupKey as any].map((item: any) => {
                    return {
                      ...item,
                      // eslint-disable-next-line @typescript-eslint/no-shadow
                      pontosHistorico: allDatesTracker
                        .filter((el) => el.trackerId === item.rastreador.codigo)
                        .map((x: any) => {
                          return { ...x, dataHora: x.date }
                        }),
                      trackerCode: item.codigo
                    }
                  }),
                  timeEnd: timeEnds[groupKey],
                  timeEndShift:
                    groupTrackers[groupKey as any][0].servicoEspecial
                      .horaFimTurno,
                  timeStart: timeStarts[groupKey],
                  timeStartShift:
                    groupTrackers[groupKey as any][0].servicoEspecial
                      .horaInicioTurno
                })

                rangeTimeSorted.push(...savedRanges)
                rangeTimeSorted.sort((a, b) => {
                  if (a.specialServiceId && b.specialServiceId) {
                    if (
                      a.specialServiceId &&
                      b.specialServiceId &&
                      a.specialServiceId !== b.specialServiceId
                    ) {
                      return a.specialServiceId - b.specialServiceId
                    } else {
                      return a.date.getTime() - b.date.getTime()
                    }
                  } else {
                    return a.date.getTime() - b.date.getTime()
                  }
                })
                return { rangeTimeSorted }
              }}
              getHistoryPointsByTrackerCode={async () => {
                setLoading(true)
                setLoadingShapes(true)
                handleFilterExecution({
                  dateFilter: dateFilter,
                  startTime: timeStarts[groupKey],
                  endTime: timeEnds[groupKey],
                  dataToSelectedIds: trackerList
                })

                return data
              }}
              plotPointTracker={(value) => {
                setAllDatesTracker([])
                // const specialServiceId =
                //   groupTrackers[groupKey as any][0].servicoEspecial.codigo
                plotHistoryPoints(filteredInfos, false, {
                  newIndex: value,
                  groupKey
                })
              }}
              showAllRadius={() => {
                // console.log('showAllRadius')
              }}
            />
          ) : null}
        </div>
      )
    })

    setGroupedTrackers(groupedList)
  }

  const getSpecialServicesInfos = async () => {
    const infosSpecialService = await readSpecialServices({
      contractId: currentContract?.codigo,
      paginationSpecialService: 0,
      searchSpecialService: '',
      setLoadingSpecialService: setLoadingSpecialServices,
      enqueueSnackbar
    })
    if (infosSpecialService) {
      const { specialServiceList, totalSpecialService } = infosSpecialService
      setSpecialServices(specialServiceList)
      setCountSpecialServices(totalSpecialService)
      setLoadingSpecialServices(false)
    }
  }

  const listTrackersMonitoring = async () => {
    setLoadingShapes(true)
    setLoading(true)
    try {
      const response = await listAllSpecialServiceSweep({
        codigoContrato: currentContract?.codigo ?? 0,
        dataExecucao: format(dataExecucao, 'yyyy-MM-dd'),
        query: filter,
        exibirUltimoPontoRoadSnap: false
      })

      if (!response.success || response.error) {
        setLoadingShapes(false)
        enqueueSnackbar(
          response.message ??
            'Ocorreu um erro interno. Comunique o administrador do sistema!',
          {
            variant: 'error'
          }
        )
      }

      if (response.success && response.data.length > 0) {
        const trackersRows = response.data.map(
          (specialService: SweeSpecialServiceT, index: number) => {
            const { startTimeFormat, endTimeFormat } =
              formatSpecialServiceShift(
                specialService.servicoEspecial.horaInicioTurno,
                specialService.servicoEspecial.horaFimTurno
              )
            const contract = currentContract as any
            return {
              ...specialService,
              id: `${specialService.rastreador.codigo}-${specialService.servicoEspecial.codigo}`,
              codigo: `${specialService.rastreador.codigo}`,
              idRastreador: `${(
                '0000' + specialService.rastreador.identificador
              ).slice(-4)}`,
              color: colorByIndex(index),
              nivelDaBateria:
                specialService.rastreador?.ultimoHistoricoRastrador?.bateria ??
                0,
              horarioGeracaoUltimoPonto: specialService.rastreador
                ?.ultimoHistoricoRastrador?.dataHora
                ? utcToLocal(
                    new Date(
                      specialService?.rastreador.ultimoHistoricoRastrador?.dataHora
                    ),
                    'dd/MM/yyyy - HH:mm:ss'
                  )
                : '',
              specialServices: `${specialService.servicoEspecial.descricao} (De ${startTimeFormat} hrs até ${endTimeFormat} hrs)`,
              percentualBateriaCritica: contract?.percentualBateriaCritica,
              percentualBateriaAlerta: contract?.percentualBateriaAlerta
            }
          }
        )

        const deselectedsIds = selectedIds.filter(
          (item) => !trackersRows.map((m: any) => m.codigo).includes(item)
        )

        deselectedsIds.map((uuid) => {
          mapHooks.removePolylines(map, uuid)
        })

        setRows(trackersRows)
        setFilterHeaderRows(trackersRows)
      }
      const filteredColumns = setFilterColumnComplete(
        filterHeader,
        handleFilterHeader
      )

      setColumns(filteredColumns)
      setLoading(false)
      setLoadingShapes(false)
    } catch (error: any) {
      setLoading(false)
      setLoadingShapes(false)
      enqueueSnackbar(
        'Ocorreu um erro interno. Comunique o administrador do sistema!',
        {
          variant: 'error'
        }
      )
    }
  }

  const handleFilterByText = (search: string) => {
    const normalizedSearch = search.toLocaleLowerCase()
    if (search.trim() !== '') {
      const filterableColumns = columns
        .filter((column) => column.sortable)
        .map((column) => column.field)

      const rowsToFilter = rows

      setFilterHeaderRows(
        rowsToFilter.filter((row) => {
          const mappingToSearch = filterableColumns.map((column) => row[column])
          const result = mappingToSearch.filter((str) => {
            if (typeof str === 'string') {
              const normaliedString = str.toLocaleLowerCase()
              return normaliedString.includes(normalizedSearch)
            }
            if (Array.isArray(str)) {
              return !!str.find((item) => {
                if (typeof item === 'string') {
                  const normaliedString = item.toLocaleLowerCase()
                  return normaliedString.includes(normalizedSearch)
                }
              })
            }
          })

          if (result.length) {
            return row
          }
          return null
        })
      )
    } else {
      setFilterHeaderRows([])
    }
  }

  useEffect(() => {
    const textFilter = filter || getValues('codigoSpecialServiceShift.label')

    const delayDebounceFn = setTimeout(() => {
      handleFilterByText(textFilter ?? '')
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [filter, watch('codigoSpecialServiceShift'), filterBlur])

  useEffect(() => {
    if (searchSpecialServices || paginationSpecialServices) {
      getSpecialServicesInfos()
    }
  }, [searchSpecialServices, paginationSpecialServices])

  useEffect(() => {
    if (currentContract) {
      Promise.all([listTrackersMonitoring(), getSpecialServicesInfos()])
    }
  }, [currentContract])

  useEffect(() => {
    if (
      map &&
      currentContract?.chaveGoogle?.trim() &&
      !Object.keys(window).includes('google')
    ) {
      window.location.reload()
    } else if (
      map &&
      !currentContract?.chaveGoogle?.trim() &&
      Object.keys(window).includes('google')
    ) {
      window.location.reload()
    }
  }, [currentContract])

  const handleOrder = async (model: GridSortModel) => {
    if (model.length !== 0) {
      const currentRow = filterHeaderRows.length ? filterHeaderRows : rows

      const sortedRows = [...currentRow].sort((a, b) => {
        const field = model[0].field
        const direction = model[0].sort === 'desc' ? -1 : 1
        if (a[field] < b[field]) {
          return -1 * direction
        } else if (a[field] > b[field]) {
          return 1 * direction
        } else {
          return 0
        }
      })
      setFilterHeaderRows(sortedRows)
    }
  }

  const showAllOverlays = () => {
    if (generationTime) {
      mapHooks.RemoveAllOverlay(map, 'generate-time')
      const rowsFilter = rows
        .filter((row) => selectedIds.includes(row.id))
        .map((r) => {
          return {
            trackCode: `${r.rastreador.codigo}-${r.servicoEspecial.codigo}`,
            trackId: r.idRastreador
          }
        })

      const minDistance = getMinDistanceByZoom(zoomMap)

      rowsFilter.forEach((track) => {
        if (
          data[track.trackCode as any] &&
          data[track.trackCode as any].length
        ) {
          const filteredData = filterNextsPoints(
            data[track.trackCode as any],
            minDistance ?? 30
          )

          filteredData.forEach((point: any) => {
            const element = createElementMouseOver({
              trackerCode: track.trackId,
              dateTime: point.dataHora
            })

            mapHooks.RenderOverlay(
              map,
              element,
              [point.latitude, point.longitude],
              'generate-time'
            )
          })
        }
      })
    } else {
      mapHooks.RemoveAllOverlay(map, 'generate-time')
    }
  }

  useEffect(() => {
    showAllOverlays()
  }, [generationTime, zoomMap])

  return (
    <>
      {!documentPdf ? (
        <Wrapper resizeArea={resizeArea} renderMap={renderMap}>
          <>
            <MapContainer>
              <OrganismMap loadingShapes={loadingShapes} id="map-main" />
              {selectedIds.length ? (
                <ViewDetails showInfo={showInfo}>
                  <ToolsHeader
                    showInfo={showInfo}
                    setShowInfo={setShowInfo}
                    handleGenerateReports={handleGenerateReports}
                  />
                  <Typography variant="subtitle1" fontWeight={600}>
                    Filtros de Visualização - {format(dateFilter, 'dd/MM/yyyy')}
                  </Typography>
                  <ListFilter>
                    <Box
                      className="d-flex align-items-center"
                      justifyContent="space-between"
                    >
                      <Typography
                        variant="subtitle1"
                        fontWeight={400}
                        sx={{
                          width: 250
                        }}
                      >
                        Rastreador (es)
                      </Typography>
                    </Box>
                  </ListFilter>
                  <ListFilter>{groupedTrackers}</ListFilter>
                  <GenerationTimeControl
                    generationTime={generationTime}
                    setGenerationTime={setGenerationTime}
                  />
                  <FilterOtherExecution
                    showFilter={true}
                    setShowFilter={() => {
                      // console.log('DESAPARÇE') // TODO: Se precisar controlar.
                    }}
                    loadingShapes={loadingShapes}
                    dateFilter={dateFilter}
                    setDateFilter={setDateFilter}
                    filterExecution={plotTracker}
                  />
                </ViewDetails>
              ) : null}
            </MapContainer>
            <GridContainer>
              <ResizeGridComponent
                resizeArea={resizeArea}
                changeResizeArea={changeResizeArea}
              />
              <FilterContainer>
                <form className="w-100">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <SelectAutoComplete
                        id="codigoSpecialServiceShift"
                        sx={{
                          '.MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input':
                            {
                              height: '15px'
                            }
                        }}
                        onSearch={(search) => {
                          if (
                            search.trim() !== '' &&
                            specialServices.length > 0
                          )
                            setSpecialServices([])
                          setPaginationSpecialServices(0)
                          setSearchSpecialServices(search)
                        }}
                        onPagination={() => {
                          if (countSpecialServices !== specialServices.length)
                            setPaginationSpecialServices(
                              paginationSpecialServices + 1
                            )
                        }}
                        label="Serviço Especial"
                        name="codigoSpecialServiceShift"
                        options={specialServices ?? []}
                        control={control}
                        loading={loadingSpecialServices}
                        errorContent={
                          (errors.codigoSpecialServiceShift
                            ?.value as FieldError) ??
                          (errors.codigoSpecialServiceShift as FieldError)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={8} display="flex">
                      <InputBase
                        sx={{
                          '.MuiOutlinedInput-input': {
                            height: '15px'
                          },
                          marginRight: '105px'
                        }}
                        placeholder="Digite o conteúdo ou parte do conteúdo que deseja pesquisar no grid"
                        size="small"
                        type="text"
                        value={filter}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setFilter(e.target.value)
                        }
                        onBlur={() => {
                          setFilterBlur(true)
                        }}
                        fullWidth
                        endAdornment={
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        }
                      />
                    </Grid>
                  </Grid>
                </form>
              </FilterContainer>
              <Table
                permissions={{
                  ...permissions?.listPermission!,
                  canAdd: false
                }}
                archiveColumn={false}
                checkboxSelection
                disableSelectionOnClick
                selectionModel={selectedIds}
                columns={columns}
                rows={filterHeaderRows.length ? filterHeaderRows : rows}
                loading={loading}
                isRowSelectable={() => !loadingShapes}
                onSelectionModelChange={(row) => onSelectRow(row)}
                paginationMode="client"
                sortingMode="client"
                onSortChange={(model: GridSortModel) => handleOrder(model)}
                onGridAction={(action, row) =>
                  handleActions(action, row as Row)
                }
                hideFooter={true}
                components={{
                  Toolbar: () => (
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      right={8}
                      top={-46}
                      position="absolute"
                    >
                      <GridToolbarColumnsButton placeholder={''} />
                    </Box>
                  )
                }}
                componentsProps={{
                  panel: {
                    sx: {
                      inset: '-100px auto auto auto !important',
                      transform: 'translate(0px, 0px) !importante',
                      paddingLeft: '75%'
                    }
                  }
                }}
              />
            </GridContainer>
          </>
        </Wrapper>
      ) : null}
      {documentPdf ? (
        <GenerationReportPdf
          fileName="Relatório Serviços Especiais.pdf"
          documentPdf={documentPdf}
          setDocumentPdf={setDocumentPdf}
          setGenerationPdf={setGenerationPdf}
          onClick={() => {
            setResizeArea(3)
            new Promise((resolve) => setTimeout(resolve, 1000)).then(() => {
              setTrackerList((prevTrackerList: any) => [
                ...prevTrackerList.map((item: any) => {
                  return {
                    ...item,
                    checked: item.checked
                  }
                })
              ])
            })
          }}
        />
      ) : (
        <></>
      )}
      {generationPdf ? <LoadingReportGeneration /> : <></>}
    </>
  )
}

export default SweepSpecialService
