import { Box, Checkbox } from '@mui/material'
import { LegendColor } from '../../sweepOverview/details/styles'
import { SpinnerLoading } from '../../../components'

interface ToolsListTrackersI {
  tracker: {
    label: string
    color: string
    checked: boolean
  }
  hasPoints: boolean
  onClick: () => void
  loadingHistoryPoints: boolean
}

function ToolsListTrackers(props: ToolsListTrackersI) {
  const arentPoint = props.loadingHistoryPoints ? false : !props.hasPoints

  return (
    <Box
      className="d-flex align-items-center cursor-pointer"
      width={125}
      onClick={props.onClick}
    >
      <LegendColor
        style={{
          width: '100%'
        }}
        color={arentPoint ? 'gray' : props.tracker.color}
      >
        {props.loadingHistoryPoints ? (
          <Box
            sx={{
              padding: '0 12px 0 0'
            }}
          >
            <SpinnerLoading />
          </Box>
        ) : (
          <Checkbox
            onClick={arentPoint ? props.onClick : () => null}
            onChange={arentPoint ? props.onClick : () => null}
            color={'default'}
            disabled={arentPoint}
            checked={props.tracker.checked}
            inputProps={{ 'aria-label': props.tracker.label }}
            style={{ color: arentPoint ? 'gray' : props.tracker.color }}
          />
        )}
        <span style={{ textDecoration: arentPoint ? 'line-through' : 'none' }}>
          {props.tracker.label}
        </span>
      </LegendColor>
    </Box>
  )
}

export default ToolsListTrackers
