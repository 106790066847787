import { FilterAlt } from '@mui/icons-material'
import { Box, IconButton, List, Menu, Typography } from '@mui/material'
import { useState } from 'react'
import { SelectAutoComplete } from '../../../components'
import { useForm } from 'react-hook-form'

interface Option {
  label: string
  value: any
}

interface FilterHeaderI {
  headerName: string
  selected: Option
  onChange: (e: Option) => void
}

const FilterHeaderSelect = ({
  headerName,
  onChange,
  selected
}: FilterHeaderI) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const { control, getValues } = useForm<{ levelBatery: Option }>({})

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const percentsToChoice: Option[] = []
  percentsToChoice.push({
    label: 'Todos',
    value: 0
  })
  for (let i = 1; i < 21; i++) {
    percentsToChoice.push({
      label: `${i * 5}%`,
      value: i * 5
    })
  }

  const handleClose = () => {
    onChange(getValues('levelBatery'))
    setAnchorEl(null)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <List
        component="nav"
        aria-label="Device settings"
        sx={{ bgcolor: 'background.paper' }}
      >
        <Typography variant="subtitle2">
          {headerName}
          <IconButton
            sx={{ marginLeft: '15px' }}
            size="small"
            id="lock-button"
            aria-haspopup="listbox"
            aria-controls="lock-menu"
            aria-label="when device is locked"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClickListItem}
          >
            <FilterAlt color={selected.value !== 0 ? 'primary' : 'disabled'} />
          </IconButton>
        </Typography>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox'
        }}
      >
        <SelectAutoComplete
          id="levelBatery"
          label="Nível da bateria abaixo de:"
          name="levelBatery"
          options={percentsToChoice ?? []}
          control={control}
          loading={false}
          sx={{
            width: 205
          }}
        />
      </Menu>
    </Box>
  )
}

export default FilterHeaderSelect
