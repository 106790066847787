import { Box } from '@mui/material'
import {
  Control,
  FieldError,
  FieldErrorsImpl,
  UseFormHandleSubmit
} from 'react-hook-form'
import { Button, CardBase, SelectAutoComplete } from '../index'
import { Option } from '../../types/Option'
import { StyledSelectAutoComplete } from './styles'

export type ImpersonateData = {
  empresa: Option
  usuario: Option
}

interface ImpersonateProps {
  handleSubmit: UseFormHandleSubmit<ImpersonateData>
  onSubmit: (value: any) => void
  handleCancel: (value: any) => void
  handleCreateAccountAccess: (value: any) => void
  handleGoConfigModelTrackers: (value: any) => void
  companys?: Option[]
  loading: boolean
  loadingCompany: boolean
  usersByCompany?: Option[]
  companySelect: boolean
  loadingUser: boolean
  errors: Partial<FieldErrorsImpl<ImpersonateData>>
  control: Control<ImpersonateData, any>
  title?: string
  isModal?: boolean
  configModelTrackers?: boolean | null
}

const Impersonate = ({
  handleCancel,
  handleSubmit,
  handleCreateAccountAccess,
  handleGoConfigModelTrackers,
  onSubmit,
  companys = [],
  loading = false,
  loadingCompany = false,
  usersByCompany = [],
  loadingUser = false,
  companySelect = false,
  control,
  errors,
  title,
  isModal = false,
  configModelTrackers
}: ImpersonateProps) => {
  return (
    <Box
      component="form"
      className={
        !isModal
          ? 'container d-flex justify-content-center align-items-center vh-100'
          : ''
      }
      onSubmit={handleSubmit(onSubmit)}
    >
      <CardBase
        isLoading={loading}
        className={!isModal ? 'col-lg-5 col-md-8 col-sm-10 col-12' : ''}
        title={title ?? 'Modo Impersonate'}
        footer={
          <div className="d-flex justify-content-between align-items-center">
            <Button disabled={loading} color="gray" onClick={handleCancel}>
              Cancelar
            </Button>
            {configModelTrackers && (
              <Button
                disabled={loading}
                id="go-config-model-tracker"
                type="button"
                color="gray"
                onClick={handleGoConfigModelTrackers}
              >
                Configurar rastreadores
              </Button>
            )}
            <Button
              disabled={loading}
              type="button"
              color="primary"
              onClick={handleCreateAccountAccess}
            >
              Criar conta de acesso
            </Button>
            <div className="d-flex align-items-center">
              <Button
                id="login-impersonate"
                disabled={loading}
                type="submit"
                color="success"
                onClick={handleSubmit(onSubmit)}
              >
                Fazer Login
              </Button>
            </div>
          </div>
        }
      >
        <p>
          Selecione uma filial e um usuário para simular um login com esse
          usuário
        </p>
        <Box className="mb-3 mt-3">
          <SelectAutoComplete
            label="Empresa"
            name="empresa"
            id="empresa"
            options={companys}
            control={control}
            loading={loadingCompany}
            errorContent={
              (errors.empresa?.value as FieldError) ??
              (errors.empresa as FieldError)
            }
          />
        </Box>

        <StyledSelectAutoComplete
          label="Usuario"
          name="usuario"
          id="usuario"
          options={usersByCompany}
          control={control}
          loading={loadingUser}
          disabled={companySelect}
          errorContent={
            (errors.usuario?.value as FieldError) ??
            (errors.usuario as FieldError)
          }
        />
      </CardBase>
    </Box>
  )
}
export default Impersonate
