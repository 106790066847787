const formatTime = (timeSeconds: number) => {
  let minutes = Math.floor(timeSeconds / 60)
  let seconds = timeSeconds - minutes * 60
  const hours = Math.floor(timeSeconds / 3600)
  if (minutes >= 1 && hours <= 0) {
    return `${minutes < 10 ? 0 : ''}${minutes}:${
      seconds < 10 ? 0 : ''
    }${seconds}`
  } else if (hours >= 1) {
    seconds = timeSeconds - hours * 3600
    minutes = Math.floor(seconds / 60)
    seconds = seconds - minutes * 60
    return `${hours < 10 ? 0 : ''}${hours}:${minutes < 10 ? 0 : ''}${minutes}:${
      seconds < 10 ? 0 : ''
    }${seconds}`
  } else {
    if (seconds > 0 || minutes > 0) {
      return `${minutes < 10 ? 0 : ''}${minutes}:${
        seconds < 10 ? 0 : ''
      }${seconds}`
    } else {
      return ''
    }
  }
}
export default formatTime
