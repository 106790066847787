import * as yup from 'yup'

export const loginFormSchema = yup.object().shape({
  email: yup
    .string()
    .required('E-mail obrigatório')
    .email('E-mail inválido')
    .matches(
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
      'E-mail inválido'
    ),
  senha: yup
    .string()
    .required('Senha obrigatória')
    .min(6, 'No minimo 6 caracteres')
})

export const stepsForgotPasswordSchema = [
  yup.object().shape({
    email: yup
      .string()
      .required('E-mail obrigatório')
      .email('E-mail inválido')
      .matches(
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
        'E-mail inválido'
      )
  }),
  yup.object().shape({
    tokenValidacao: yup
      .string()
      .required('Digite o código enviado ao seu e-mail!')
      .min(4, 'O código deve ter 4 caracteres!')
      .max(4, 'O código deve ter 4 caracteres!')
  }),
  yup.object().shape({
    novaSenha: yup
      .string()
      .required('Nova senha é obrigatória')
      .min(6, 'No minimo 6 caracteres'),
    confirmarNovaSenha: yup
      .string()
      .required('Confirmação de senha é obrigatório')
      .oneOf([yup.ref('novaSenha'), null], 'As senhas tem que ser iguais')
  })
]
export const impersonateFormSchema = yup.object().shape({
  empresa: yup
    .object()
    .nullable()
    .test('empty-empresa', 'Empresa é obrigatório', function (field) {
      if (field && field.value !== -1) {
        return true
      }
      return false
    }),
  usuario: yup
    .object()
    .nullable()
    .test('empty-usuario', 'Usuário é obrigatório', function (field) {
      if (field && field.value !== -1) {
        return true
      }
      return false
    })
})
