import styled from 'styled-components'
import { colors } from '../../assets/variables'
import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material'

export const Wrapper = styled.div`
  height: 25px;
  width: 100%;
  min-width: 300px;
  display: flex;
  background-color: ${colors.grayPastel};
  border: 1px solid rgba(224, 224, 224, 1);
`

const backgroundColor = {
  green: '#0BCC35',
  greenStrong: '#2E9845',
  yellow: '#E8A239',
  red: '#EB0505'
}

export const ProgressPercent = styled.div`
  width: ${(props: { percent: number; color: string }) => `${props.percent}%`};
  visibility: ${(props: { percent: number; color: string }) =>
    props.percent === 0 ? `hidden` : `inherit`};
  background-color: ${(props: { percent: number; color: string }) =>
    backgroundColor[props.color as keyof typeof backgroundColor]};
  height: 24px;
  color: white;
  font-size: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 5px;
  text-align: center;
`

export const TooltipCustomization = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)<{ colorTwo: string }>(({ colorTwo }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: backgroundColor[colorTwo as keyof typeof backgroundColor],
    color: colors.white,
    boxShadow: colors.borderColor,
    border: '2px solid black'
  }
}))
