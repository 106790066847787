import { Controller, FieldError } from 'react-hook-form'
import type { Control } from 'react-hook-form'
import {
  Autocomplete,
  CircularProgress,
  FormControl,
  FormHelperText,
  SxProps,
  TextField
} from '@mui/material'

import { Option } from '../../types/Option'
import { useEffect, useState } from 'react'

interface SelectAutoCompleteProps {
  id?: string
  onSearch?: (search: string) => void
  notifyChange?: () => void
  onPagination?: () => void
  name: string
  label: string
  className?: string
  options: Option[] | any
  defaultOption?: Option | null
  loading?: boolean
  disabled?: boolean
  control: Control<any>
  errorContent?: FieldError
  sx?: SxProps
  disableClearable?: boolean
  multiple?: boolean
}

const SelectAutoComplete = ({
  id,
  onSearch,
  onPagination,
  name,
  label,
  className,
  options,
  defaultOption,
  control,
  errorContent,
  sx,
  notifyChange,
  loading = false,
  disabled = false,
  disableClearable = false,
  multiple = false
}: SelectAutoCompleteProps) => {
  const [search, setSearch] = useState('')
  const [isSearched, setIsSearched] = useState(false)

  useEffect(() => {
    if (isSearched) {
      const delaySearch = setTimeout(() => {
        onSearch?.(search)
      }, 1500)
      return () => clearTimeout(delaySearch)
    }
  }, [search])

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControl fullWidth error={!!errorContent}>
          <Autocomplete
            multiple={multiple}
            sx={sx}
            id={id}
            className={className}
            options={options}
            renderOption={(props, option) => (
              <li {...props} key={option.value}>
                {option.label}
              </li>
            )}
            loading={loading}
            size="small"
            fullWidth
            isOptionEqualToValue={(option, valueOption) =>
              option.label === valueOption.label
            }
            disableClearable={disableClearable}
            disabled={disabled}
            noOptionsText="Sem opções"
            getOptionLabel={(option) => option.label ?? ''}
            onChange={(_, values) => {
              notifyChange ? notifyChange() : null
              onChange(values)
            }}
            defaultValue={defaultOption}
            value={multiple ? value : value ?? defaultOption ?? ''}
            onBlur={() => {
              setSearch('')
            }}
            ListboxProps={{
              onScroll: (event: React.SyntheticEvent) => {
                event.preventDefault()
                const { scrollTop, scrollHeight, clientHeight } =
                  event.currentTarget
                if (
                  Math.floor(
                    ((scrollTop + clientHeight) / scrollHeight) * 100 + 1
                  ) >= 100
                ) {
                  const delayPagination = setTimeout(() => {
                    onPagination?.()
                  }, 500)
                  return () => clearTimeout(delayPagination)
                }
              }
            }}
            renderInput={(params) => (
              <TextField
                error={!!errorContent}
                {...params}
                size="small"
                label={label}
                disabled={disabled}
                variant="outlined"
                defaultValue={search}
                value={search}
                sx={{
                  '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button':
                    {
                      '-webkit-appearance': 'none',
                      margin: 0
                    },
                  '& input[type=number]': {
                    '-moz-appearance': 'textfield'
                  }
                }}
                onChange={(e) => {
                  e.preventDefault()
                  setIsSearched(true)
                  setSearch(e.target.value)
                }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
          {errorContent && (
            <FormHelperText>{errorContent?.message}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  )
}
export default SelectAutoComplete
