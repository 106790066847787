import { useEffect, useState } from 'react'
import {
  Button,
  InputBase,
  ModalBase,
  SelectAutoComplete
} from '../../components/index'

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import { createModelTrackerSchema } from '../../utils/validations/tracker'
import { Box, Grid, Typography } from '@mui/material'

import {
  EditModelTrackerProps,
  readHistoryModelTrackers
} from '../../app/api/modelTracker'
import { utcToLocal } from '../../utils/utcDateTime'
import Option from '../../types/Option'
import VersionedList from './versionCompare'

export interface TrackProps {
  open: boolean
  close: () => void
  isChange: boolean
  tracker: EditModelTrackerProps
}

export interface TrackRegisterProps {
  imei: string
  message: string
  isSuccess: boolean
}

export interface HistoryDateI {
  arquivoConfiguracao: string
  codigo: number
  dataCadastro: string
  identificador: string
  usuarioCadastro: string
}

export const HistoryModelTracker = ({ open, close, tracker }: TrackProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [historyDate, setHistoryData] = useState<HistoryDateI[]>([])
  const [secondCompare, setSecondCompare] = useState<HistoryDateI>()
  const [currentHistory, setCurrentHistory] = useState<HistoryDateI>()
  const { control, watch, getValues, setValue } = useForm<{
    codigo: Option
  }>({
    resolver: yupResolver(createModelTrackerSchema)
  })
  const closeAll = () => {
    setIsOpen(false)
    setHistoryData([])
    setSecondCompare(undefined)
    close()
  }

  const listHistoryModelsTrackers = async () => {
    setLoading(true)
    try {
      const response = await readHistoryModelTrackers(String(tracker?.codigo))

      if (response.success && response.data) {
        const formatDate = response.data.map((item) => {
          return {
            ...item,
            dataCadastro: utcToLocal(
              new Date(item.dataCadastro),
              'dd/MM/yyyy HH:mm'
            )
          }
        })
        formatDate.sort(
          (a: { dataCadastro: any }, b: { dataCadastro: any }) => {
            const dateA = new Date(a?.dataCadastro ?? '').getTime()
            const dateB = new Date(b?.dataCadastro ?? '').getTime()
            if (dateA > dateB) {
              return -1
            } else if (dateA < dateB) {
              return 1
            }
            return 0
          }
        )
        setCurrentHistory(formatDate[0])
        setHistoryData(formatDate)
        setValue('codigo', {
          value: 1,
          label: formatDate[1]?.dataCadastro
        })
        setLoading(false)
      } else {
        setLoading(false)
      }
    } catch (error: any) {
      setLoading(false)
    }
  }

  useEffect(() => {
    setIsOpen(open)
    if (open) {
      listHistoryModelsTrackers()
    }
  }, [open])

  useEffect(() => {
    if (getValues('codigo')) {
      setSecondCompare(historyDate[getValues('codigo').value] ?? null)
    }
  }, [watch('codigo')])

  return (
    <ModalBase
      title={'Historico configuração geral do Rastreador'}
      open={isOpen}
      toggle={() => {
        closeAll()
      }}
      fullWidth
      maxWidth={'xl'}
      footer={
        <Box display="flex" justifyContent="space-between" gap={1}>
          <Button
            disabled={loading}
            color="gray"
            onClick={() => {
              closeAll()
            }}
          >
            Voltar
          </Button>
        </Box>
      }
    >
      <>
        <Grid container spacing={2} mt={1}>
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <InputBase
              id="identificador"
              size="small"
              disabled={true}
              variant="outlined"
              label="Modelo do Rastreador"
              value={currentHistory?.identificador ?? ''}
              autoFocus
              autoComplete="off"
            />
          </Grid>
          <Grid
            item
            spacing={2}
            mt={1}
            xs={12}
            display="flex"
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              sm={6}
              mr={2}
              display="flex"
              flexDirection={'column'}
            >
              <Box>
                <Typography mb={4}>Configuração atual</Typography>
                <Box display={'flex'} gap={1} marginBottom={3}>
                  <InputBase
                    id="identificador"
                    size="small"
                    variant="outlined"
                    label="Data/hora da edição"
                    value={currentHistory?.dataCadastro ?? ''}
                    disabled={true}
                    fullWidth
                    autoFocus
                    autoComplete="off"
                  />
                  <InputBase
                    id="identificador"
                    size="small"
                    variant="outlined"
                    label="Responsavel pela edição"
                    value={currentHistory?.usuarioCadastro ?? ''}
                    disabled={true}
                    fullWidth
                    autoFocus
                    autoComplete="off"
                  />
                </Box>

                <VersionedList
                  isNew
                  currentValue={
                    historyDate[0]?.arquivoConfiguracao.split('\n') ?? []
                  }
                  oldList={secondCompare?.arquivoConfiguracao.split('\n') ?? []}
                />
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              mr={1}
              display="flex"
              flexDirection={'column'}
            >
              <Box>
                <Typography mb={4}>Configurações anteriores</Typography>

                <Box display={'flex'} gap={1} marginBottom={3}>
                  <SelectAutoComplete
                    multiple={false}
                    className={'className'}
                    label={'Data/hora da edição'}
                    id={'codigo'}
                    name="codigo"
                    control={control}
                    options={
                      historyDate
                        ?.map((el, index) => {
                          if (el.codigo !== currentHistory?.codigo) {
                            return {
                              label: el.dataCadastro,
                              value: index
                            }
                          }
                        })
                        .filter((el) => el !== undefined) ?? []
                    }
                    loading={false}
                  />
                  <InputBase
                    id="identificador"
                    size="small"
                    variant="outlined"
                    label="Responsavel pela edição"
                    value={secondCompare?.usuarioCadastro ?? ''}
                    disabled={true}
                    fullWidth
                    autoFocus
                    autoComplete="off"
                  />
                </Box>

                <VersionedList
                  isNew={false}
                  currentValue={
                    historyDate[0]?.arquivoConfiguracao.split('\n') ?? []
                  }
                  oldList={secondCompare?.arquivoConfiguracao.split('\n') ?? []}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </>
    </ModalBase>
  )
}
