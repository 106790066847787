export const getScreenOnlyHeadquarters = () => {
  return ['Contratos', 'Perfis de acesso', 'contratos', 'perfis-acesso']
}

export const getScreenSpecialServices = () => {
  return [
    'Serviços Especiais',
    'servicos_especiais',
    'special-services-sweep-overview'
  ]
}

export const getScreenOnlyCityHall = () => {
  return [
    'Usuários',
    'Varrição',
    'Turnos',
    'Relatórios',
    'Relatório Consolidado de Varrição',
    'usuarios',
    'sinotico-de-varricao',
    'relatorios',
    'turnos'
  ]
}
