import styled, { css } from 'styled-components'
import { colors } from '../../assets/variables'

interface StylesWrapperProps {
  show: boolean
}

const visible = css`
  visibility: visible;
  opacity: 1;
`
const hidden = css`
  visibility: hidden;
  opacity: 0;
`

export const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 50px;
  background-color: ${colors.grayPastel};
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 50px);
  z-index: 999;
  overflow-y: auto;
  ${(props: StylesWrapperProps) => (props.show ? visible : hidden)}
  &::-webkit-scrollbar {
    height: 15px;
    width: 15px;
  }

  &::-webkit-scrollbar-thumb {
    background: #dadada;
    border-radius: 12px;
    border: 4px solid ${colors.grayPastel};
  }

  &::-webkit-scrollbar-track {
    border-radius: 12px;
    background: ${colors.grayPastel};
  }
`

export const Group = styled.div`
  border: 1px solid ${colors.borderColor};
  margin-top: 1rem;
  border-radius: 0.3rem;
  padding: 1rem;
  background-color: white;
`

export const GroupTitle = styled.h2`
  display: flex;
  align-items: center;
  font-size: 20px;
  color: ${colors.primary};
  margin-bottom: 1rem;
`

export const MenuItems = styled.div`
  display: grid;
  gap: 25px;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
`

export const Item = styled.div`
  width: 100%;
  border: 1px solid ${colors.borderColor};
  border-radius: 0.3rem;
  padding: 1rem;
  transition: 0.1s;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
  }
`

export const Figure = styled.figure`
  position: relative;
  min-height: 60px;
  min-width: 60px;
  max-height: 60px;
  max-width: 60px;
  border-radius: 0.3rem;
  background-color: ${colors.grayPastel};
  padding: 0.7rem;
`
export const Icon = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
`

export const Upload = styled.img`
  height: 25px;
  width: 25px;
  object-fit: contain;
  object-position: center;
  position: absolute;
  right: -5px;
  bottom: -5px;
`

export const ItemTitle = styled.h4`
  font-size: 16px;
  font-weight: 500;
  margin-left: 1rem;
  color: ${colors.primary};
`

export const Description = styled.p`
  font-size: 14px;
  margin-top: 1rem;
  color: ${colors.gray900};
`
