import styled from 'styled-components'
import { colors } from '../../assets/variables'
import { Wrapper as Map } from '../MapArea/styles'

export const MapContainer = styled.div`
  position: relative;
  ${Map} {
    width: 100%;
    height: 100%;
  }
`

export const Wrapper = styled.div<{ wrapperHeight?: string | undefined }>`
  margin-top: 1rem;
  height: ${(props) => props.wrapperHeight || ' 70vh'};
  transition: height 0.3s ease-in-out;
  display: flex;
  align-items: stretch;
  .leaflet-marker-icon {
    margin-left: -4px !important;
    margin-top: -4px !important;
    width: 8px !important;
    height: 8px !important;
  }
  ${MapContainer} {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`
export const Groups = styled.div`
  position: absolute;
  top: 68px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 4.5px;
  border: solid 2px rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  z-index: 999;
  max-width: 185px;
  width: 100%;
`

export const FlagColor = styled.div`
  height: 12px;
  width: 24px;
  margin-right: 10px;
  margin-top: 10px;
  background-color: ${(props: { color: string }) => props.color};
`

export const ToolsList = styled.div`
  width: 67px;
  min-width: 67px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  max-height: 100%;
  @media (max-width: 768px) {
    flex-direction: row;
    width: 100%;
    height: 67px;
    min-height: 67px;
  }
`

export const MapSearch = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 4.5px;
  border: solid 2px rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  z-index: 999;
  display: flex;
  align-items: stretch;
  max-width: 400px;
  width: 100%;
  @media (max-width: 768px) {
    max-width: 200px;
  }
`

export const SearchBar = styled.input`
  width: 100%;
  padding-left: 0.8rem;
`

export const KmDrawn = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  border: solid 2px rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  padding: 4.5px;
  z-index: 999;
  color: ${(props: { alert: string }) =>
    colors[props.alert as keyof typeof colors]};
`
