import { Checkbox, Tooltip } from '@mui/material'
import { Control, Controller, ControllerRenderProps } from 'react-hook-form'

interface SelectProps {
  name: string
  defaultCheck?: boolean
  control: Control<any>
  hint?: string
}

const CheckboxMUI = ({
  name,
  defaultCheck = false,
  control,
  hint
}: SelectProps) => {
  const renderCheckBox = (props: ControllerRenderProps) => (
    <Checkbox
      {...props}
      checked={props.value}
      onChange={(e) => props.onChange(e.target.checked)}
    />
  )

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultCheck}
      render={({ field: props }) => {
        if (hint) {
          return <Tooltip title={hint}>{renderCheckBox(props)}</Tooltip>
        }
        return renderCheckBox(props)
      }}
    />
  )
}
export default CheckboxMUI
