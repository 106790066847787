import { forwardRef, ForwardRefRenderFunction, ReactNode } from 'react'
import { Button as ButtonUI, ButtonProps as ButtonPropsUI } from '@mui/material'
import { colors } from '../../assets/variables'

export interface ButtonProps extends ButtonPropsUI {
  dataTestId?: string
  backgroundColor?: string
  children?: ReactNode
  loading?: boolean
}

const ButtonBase: ForwardRefRenderFunction<HTMLButtonElement, ButtonProps> = (
  { dataTestId, backgroundColor, children, ...rest }: ButtonProps,
  ref
) => {
  return (
    <ButtonUI
      data-testid={dataTestId}
      sx={{
        backgroundColor:
          colors[(backgroundColor as keyof typeof colors) ?? 'transparent']
      }}
      ref={ref}
      {...rest}
    >
      {children}
    </ButtonUI>
  )
}

const Button = forwardRef(ButtonBase)

export default Button
