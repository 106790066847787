import {
  forwardRef,
  ForwardRefRenderFunction,
  ReactElement,
  useEffect,
  useState
} from 'react'
import {
  Dialog as DialogUI,
  DialogProps as DialogPropsUI,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
  CircularProgress
} from '@mui/material'

import { Header, HeaderButtons } from './styles'
import { colors } from '../../assets/variables'
import Close from '../CloseIcon'
import MaximizeIcon from '../MaximizeIcon'

interface ModalProps extends DialogPropsUI {
  className?: string
  title?: string
  titleIsCenter?: boolean
  toggle: (value?: any) => void
  onClose?: () => void
  children: ReactElement
  footer?: ReactElement
  footerBackground?: string
  isLoading?: boolean
  noClose?: boolean
  onFullScreenChange?: (state: boolean) => void
  showFullScreenButton?: boolean
}

const ModalBase: ForwardRefRenderFunction<HTMLDivElement, ModalProps> = (
  {
    title,
    titleIsCenter = false,
    onClose,
    toggle,
    children,
    footer,
    footerBackground,
    isLoading = false,
    noClose = false,
    onFullScreenChange,
    showFullScreenButton,
    ...rest
  }: ModalProps,
  ref
) => {
  const [isFullScreen, setFullScreen] = useState<boolean>(false)

  const toggleFullScreen = () => {
    setFullScreen((prevState) => !prevState)
  }

  useEffect(() => {
    if (onFullScreenChange) {
      onFullScreenChange(isFullScreen)
    }
  }, [isFullScreen])

  return (
    <DialogUI
      fullScreen={isFullScreen}
      onClose={(e: any) => {
        if (!isLoading && !noClose) {
          toggle(e)
          onClose ? onClose() : null
        }
      }}
      sx={{
        '.MuiDialog-paper': {
          margin: 0,
          width: isFullScreen ? '100%' : 'calc(100% - 15px)'
        }
      }}
      ref={ref}
      {...rest}
    >
      <>
        <Header isCenter={titleIsCenter}>
          <DialogTitle alignSelf="center" id="alert-dialog-title">
            {title ?? ''}
          </DialogTitle>
          <HeaderButtons>
            {showFullScreenButton && (
              <MaximizeIcon
                id="maximizeModal"
                size="md"
                isFullScreen={isFullScreen}
                onClick={toggleFullScreen}
              />
            )}
            {!noClose ? (
              <Close
                id="closeModal"
                disabled={isLoading}
                size="md"
                onClick={(e) => {
                  toggle(e)
                  onClose ? onClose() : null
                }}
              />
            ) : null}
          </HeaderButtons>
        </Header>

        <DialogContent>
          {isLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          ) : (
            children
          )}
        </DialogContent>
        {footer ? (
          <DialogActions
            style={{
              backgroundColor:
                colors[
                  (footerBackground as keyof typeof colors) ?? 'transparent'
                ]
            }}
          >
            {footer}
          </DialogActions>
        ) : null}
      </>
    </DialogUI>
  )
}

const Modal = forwardRef(ModalBase)

export default Modal
