import {
  CircleMarkerOptions,
  CircleMarkerStyle,
  CircleOptions,
  Map,
  MapEventType,
  MapType,
  MarkerEventType,
  MarkerIcon,
  MarkerOptions,
  OverlayOptions,
  PolylineEventType,
  PolylineOptions,
  PopupOptions
} from '@inlog/inlog-maps/lib'
import { coordinatesToKm } from './mapDrawHooks'
import { colors } from '../assets/variables'

interface ObjectMarker {
  uuid: string
  groupUuid?: string
  latitude?: number
  longitude?: number
  color?: string
}

interface ObjectShape {
  uuid: string
  groupUuid?: string
  shape?: number[][]
  color?: string
  sequence?: number
}

interface renderPolylineI {
  map: Map | null
  object: ObjectShape
  lineWeight?: number
  type?: string
  opacity?: number
  config?: PolylineOptions
  zindex?: number
  event?: () => void
  rightClick?: (e: { latlng: number[] }) => void
  addMouseOver?: boolean
}
const leafletParams = {
  scriptsDependencies: [
    `${String(
      process.env.PUBLIC_URL
    )}/leaflet/leaflet-editable/src/Leaflet.Editable.js`,
    `${String(
      process.env.PUBLIC_URL
    )}/leaflet/leaflet.path.drag/src/Path.Drag.js`,
    `${String(
      process.env.PUBLIC_URL
    )}/leaflet/leaflet-gesture-handling/dist/leaflet-gesture-handling.js`,
    `${String(
      process.env.PUBLIC_URL
    )}/leaflet/leaflet.markercluster/dist/leaflet.markercluster.js`
  ],
  cssDependencies: [
    `${String(
      process.env.PUBLIC_URL
    )}/leaflet/leaflet-gesture-handling/dist/leaflet-gesture-handling.css`,
    `${String(
      process.env.PUBLIC_URL
    )}/leaflet/leaflet.markercluster/dist/MarkerCluster.Default.css`
  ],
  wikimedia: false
}

export const loadMap = async (id: string, apiKey: string | null) => {
  // eslint-disable-next-line prettier/prettier
  const currentMap = new Map()

  const GoogleParams = {
    libraries: ['drawing'],
    apiKey,
    gestureHandling: false,
    showTraffic: false,
    options: {
      streetViewControl: true,
      tilt: 0,
      styles: [
        {
          featureType: 'all',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        }
      ]
    }
  }

  let mapType = MapType.Leaflet
  let mapParams = {}

  if (apiKey !== null) {
    mapType = MapType.Google
    mapParams = GoogleParams
  } else {
    mapParams = leafletParams
  }

  return await new Promise<Map>((resolve, reject) => {
    currentMap
      .initialize(mapType, mapParams, id)
      .then(() => {
        resolve(currentMap)
      })
      .catch((err: any) => {
        reject(err)
      })
  })
}

const markerColor = (color: string) => {
  switch (color) {
    case 'primary':
      return require('../assets/icons/markers/primary.png')
    case 'danger':
      return require('../assets/icons/markers/danger.png')
    case 'success':
      return require('../assets/icons/markers/success.png')
    case 'warning':
      return require('../assets/icons/markers/warning.png')
    case 'gray':
      return require('../assets/icons/markers/gray.png')
    case 'system':
      return require('../assets/icons/markers/system.png')
    default:
      return require('../assets/icons/markers/default.png')
  }
}

export const removeMarkers = (map: Map | null, uuid: string) => {
  map?.removeMarkers('marker', (obj: ObjectMarker) => obj.uuid === uuid)
}

export const removeGroupMarkers = (
  map: Map | null,
  groupUuid: string,
  type = 'marker'
) => {
  map?.removeMarkers(type, (obj: ObjectMarker) => obj.groupUuid === groupUuid)
}

export const removeAllMarkers = (map: Map | null) => {
  map?.removeAllMarkers()
}

export const renderMarker = (
  map: Map | null,
  object: ObjectMarker,
  config?: MarkerOptions | null,
  color?: string | null,
  event?: () => void
) => {
  const icon = new MarkerIcon(markerColor(color ?? ''), [22, 30.55])
  let options = new MarkerOptions([
    Number(object.latitude),
    Number(object.longitude)
  ])
  options.object = { uuid: object.uuid, groupUuid: object?.groupUuid }
  options.icon = icon
  options.addToMap = true
  options = {
    ...options,
    ...config
  }
  map?.drawMarker('marker', options, event)
}

export const renderMarkerDraggable = (
  map: Map | null,
  object: ObjectMarker,
  config?: MarkerOptions | null,
  color?: string | null,
  event?: () => void,
  eventDrag?: (value: any) => void
) => {
  let options = new MarkerOptions([
    Number(object.latitude),
    Number(object.longitude)
  ])
  const icon = new MarkerIcon(markerColor(color ?? ''), [22, 30.55])
  options.object = { uuid: object.uuid, groupUuid: object?.groupUuid }
  options.icon = icon
  options.draggable = true
  options.addToMap = true
  options = {
    ...options,
    ...config
  }
  map?.drawMarker('marker', options, event)
  map?.addMarkerEvent(
    'marker',
    MarkerEventType.AfterDrag,
    (e: { latlng: number[] }) =>
      eventDrag?.({ latitude: e.latlng[0], longitude: e.latlng[1] })
  )
}

export const removePolygons = (map: Map | null, uuid: string) => {
  map?.removePolygons('polygon', (object: ObjectShape) => object.uuid === uuid)
}

export const removeGroupPolygons = (map: Map | null, groupUuid: string) => {
  map?.removePolygons(
    'polygon',
    (object: ObjectShape) => object.groupUuid === groupUuid
  )
}

export const removeAllPolygons = (map: Map | null) => {
  map?.removeAllPolygons()
}

export const renderPolygon = (
  map: Map | null,
  object: ObjectShape,
  config: PolylineOptions,
  event: () => void
) => {
  let options: any = new PolylineOptions()
  options.path = object.shape ?? []
  options.object = { uuid: object.uuid }
  options.color = '#0000005f'
  options.addToMap = true
  options.fitBounds = false
  options = {
    ...options,
    ...config
  }
  map?.drawPolygon('polygon', options, event)
}

export const removePolylines = (
  map: Map | null,
  uuid: string,
  type = 'polyline'
) => {
  map?.removePolylines(type, (object: ObjectShape) => object.uuid === uuid)
}

export const removePolylinesByGroupUuid = (
  map: Map | null,
  groupUuid: string,
  type = 'popyline'
) => {
  map?.removePolylines(
    type,
    (object: ObjectShape) => object.groupUuid === groupUuid
  )
}

export const removePolylineByType = (map: Map | null, type: string) => {
  map?.removePolylines(type)
}

export const removeGroupPolylines = (map: Map | null, groupUuid: string) => {
  map?.removePolylines(
    'polyline',
    (object: ObjectShape) => object.groupUuid === groupUuid
  )
}

export const removeAllPolylines = (map: Map | null) => {
  map?.removeAllPolylines()
}

export const renderPolyline = ({
  map,
  object,
  lineWeight = 4,
  type = 'polyline',
  config,
  event
}: renderPolylineI) => {
  let options = new PolylineOptions(object.shape)
  options.object = { uuid: object.uuid }
  options.color = object.color ? object.color : '#000000'
  options.addToMap = true
  options.fitBounds = false
  options.weight = lineWeight
  options.zIndex = 99
  options = {
    ...options,
    ...config
  }

  map?.drawPolyline(type, options, event)
}

const distanceToLine = (pt1: any, pt2: any, pt: any) => {
  const deltaX = pt2[1] - pt1[1]
  const deltaY = pt2[0] - pt1[0]
  let incIntersect = (pt[1] - pt1[1]) * deltaX
  const deltaSum = deltaX * deltaX + deltaY * deltaY

  incIntersect += (pt[0] - pt1[0]) * deltaY
  if (deltaSum > 0) {
    incIntersect /= deltaSum
  } else {
    incIntersect = -1
  }

  if (incIntersect < 0) {
    return coordinatesToKm(pt, pt1)
  } else if (incIntersect > 1) {
    return coordinatesToKm(pt, pt2)
  }

  const intersect = [
    pt1[0] + incIntersect * deltaY,
    pt1[1] + incIntersect * deltaX
  ]

  return coordinatesToKm(pt, intersect)
}

export const findIndex = (path: any, point: any) => {
  let distance = 0
  let minDistance = Number.MAX_VALUE
  let returnValue = -1

  for (let i = 0; i < path.length - 1; i++) {
    distance = distanceToLine(path[i], path[i + 1], point)

    if (distance < minDistance) {
      minDistance = distance
      returnValue = i
    }
  }
  return returnValue
}

export const handleHoverPolyline = (
  map: Map | null,
  latlng: number[],
  object: ObjectShape,
  event?: () => void,
  rightClick?: (e: { latlng: number[] }) => void
) => {
  const type = 'hover-polyline'
  const index = findIndex(object.shape, latlng)

  const options = new PolylineOptions([
    object.shape![index],
    object.shape![index + 1]
  ])
  options.object = { uuid: object.uuid, type: type }
  options.color = object.color ? object.color : '#000000'
  options.addToMap = true
  options.fitBounds = false
  options.weight = 10
  options.zIndex = 999
  map?.removePolylines(type)
  map?.drawPolyline(type, options, () => {
    options.color = colors.primary
    event ? event() : null
    map?.removePolylines(type)
    map?.removePolylines('fixed-hover')
    map?.drawPolyline('fixed-hover', options, () => {
      map?.removePolylines('fixed-hover')
    })
    map?.addPolylineEvent(
      'fixed-hover',
      PolylineEventType.RightClick,
      rightClick
    )
  })
  map?.addPolylineEvent(type, PolylineEventType.MouseOut, () =>
    map?.removePolylines(type)
  )
  map?.addPolylineEvent(type, PolylineEventType.RightClick, rightClick)
}

export const renderPolylineWithNavigation = ({
  map,
  object,
  lineWeight = 3,
  type = 'polyline',
  opacity = undefined,
  config,
  zindex,
  event,
  rightClick
}: renderPolylineI) => {
  let options = new PolylineOptions(object.shape)
  options.object = {
    uuid: object.uuid,
    groupUuid: object?.groupUuid,
    sequence: object?.sequence
  }
  options.color = object.color ? object.color : '#000000'
  options.addToMap = true
  options.fitBounds = false
  options.weight = lineWeight
  options.opacity = opacity ?? 10
  options.zIndex = zindex ?? 1
  options = {
    ...options,
    ...config
  }
  map?.drawPolyline(type, options, event)
  map?.addPolylineEvent(
    type,
    PolylineEventType.MouseOver,
    (e: { latlng: number[] }) => {
      handleHoverPolyline(map, e.latlng, object, event, rightClick)
    },
    (obj: any) => obj.uuid === object.uuid
  )
}

export const togglePolyline = (
  map: Map | null,
  uuid: string,
  show: boolean,
  type = 'polyline'
) => {
  map?.togglePolylines(
    show,
    type,
    (object: { uuid: string }) => object.uuid === uuid
  )
}

export const alterPolylineColor = (
  map: Map | null,
  uuid: string,
  color?: string,
  zIndex = 1,
  type = 'polyline'
) => {
  const options = new PolylineOptions()
  options.color = color
  options.addToMap = true
  options.fitBounds = false
  options.weight = 5
  options.zIndex = zIndex ?? 1
  map?.alterPolylineOptions(
    type,
    options,
    (object: ObjectShape) => object.uuid === uuid
  )
}

export const alterPolylineColorByType = (
  map: Map | null,
  color?: string,
  zIndex = 1,
  type = 'polyline'
) => {
  const options = new PolylineOptions()
  options.color = color
  options.zIndex = zIndex
  map?.alterPolylineOptions(type, options)
}

export const alterPolylineColorByGroupUuid = (
  map: Map | null,
  groupUuid: string,
  color?: string,
  zIndex = 1,
  type = 'polyline'
) => {
  const options = new PolylineOptions()
  options.color = color
  options.zIndex = zIndex
  const conditional = (object: ObjectShape) => {
    return object.groupUuid === groupUuid
  }
  map?.alterPolylineOptions(type, options, conditional)
}

export const resetShapeColor = (
  map: Map | null,
  color: string,
  type = 'polyline'
) => {
  const options = new PolylineOptions()
  options.color = color
  options.zIndex = 1

  map?.alterPolylineOptions(type, options)
}

export const removePoints = (map: Map | null, uuid: string) => {
  map?.removeMarkers(
    'circleMarker',
    (object: ObjectMarker) => object.uuid === uuid
  )
}

export const removeGroupPoints = (
  map: Map | null,
  groupUuid: string,
  type = 'circleMarker'
) => {
  map?.removeMarkers(
    type,
    (object: ObjectMarker) => object.groupUuid === groupUuid
  )
}

export const removeAllPoints = (map: Map | null) => {
  map?.removeAllMarkers()
}

export const toggleGroupPoints = (
  map: Map | null,
  groupUuid: string,
  show: boolean,
  type = 'circleMarker'
) => {
  map?.toggleMarkers(
    show,
    type,
    (object: ObjectMarker) => object.groupUuid === groupUuid
  )
}

export const togglePoint = (
  map: Map | null,
  uuid: string,
  show: boolean,
  type = 'circleMarker'
) => {
  map?.toggleMarkers(show, type, (object: ObjectMarker) => object.uuid === uuid)
}

export const removeCircleByType = (map: Map | null, type = 'radius') => {
  map?.removeCircles(type)
}

export const renderPoint = (
  map: Map | null,
  object: ObjectMarker,
  config?: CircleMarkerOptions | null,
  event?: () => void,
  color = '#1da023',
  type = 'circleMarker'
) => {
  const style = new CircleMarkerStyle(7, 1, '#ffffff', color, 0.9)
  let options = new CircleMarkerOptions(
    [Number(object.latitude), Number(object.longitude)],
    style
  )
  options.object = { uuid: object.uuid, groupUuid: object?.groupUuid }
  options.addToMap = true
  options.fitBounds = false
  options = {
    ...options,
    ...config
  }

  map?.drawCircleMarker(type, options, event)
}

export const renderPopup = (
  map: Map | null,
  popup: string,
  latlng: number[]
) => {
  const options = new PopupOptions(latlng, popup, 'simple')
  map?.drawPopup('marker', options)
}

export const removePopup = (map: Map | null) => {
  map?.closePopup('marker')
}

interface SimpleRadioI {
  map: Map | null
  object: ObjectMarker
  radius: number
  handleClick?: (event: any) => void
}

export const removeGroupCircle = (
  map: Map | null,
  groupUuid: string,
  type = 'radius'
) => {
  map?.removeCircles(type, (object: ObjectMarker) => {
    return object.groupUuid === groupUuid
  })
}

export const renderCircle = ({
  map,
  object,
  radius,
  handleClick
}: SimpleRadioI) => {
  const options = new CircleOptions(
    [Number(object.latitude), Number(object.longitude)],
    radius,
    2
  )
  options.object = { ...object }
  options.fillOpacity = 0.5
  options.fillColor = object.color
  options.color = object.color + '0f'
  options.addToMap = true
  options.fitBounds = false

  map?.drawCircle('radius', options, handleClick)
}

export const renderRadio = (
  map: Map | null,
  latlng: number[],
  handleClick?: (event: any) => void
) => {
  const options = new CircleOptions(latlng, 0.01, 2)
  options.object = { uuid: 'radio' }
  options.fillOpacity = 0.01
  options.addToMap = true
  options.fitBounds = false

  map?.drawCircle('radio', options, handleClick)
}

export const updateRadio = (
  map: Map | null,
  color: string,
  latlng: number[],
  radius: number,
  show: boolean
) => {
  const options = new CircleOptions(
    latlng,
    show ? (radius === 0 ? 0.01 : radius) : 0.01,
    2
  )
  options.fillOpacity = show ? 0.5 : 0.01
  options.fillColor = color
  options.color = color + '0f'
  options.addToMap = true

  map?.alterCircleOptions(
    'radio',
    options,
    (obj: { uuid: string }) => obj.uuid === 'radio'
  )
}

export const fitBoundsRadio = (map: Map | null) => {
  map?.fitBoundsCircles('radio')
}

interface renderPointWithRadioI {
  map: Map | null
  object: ObjectMarker
  config?: CircleMarkerOptions | null
  event?: (() => void) | null
  rightClick?: (() => void) | null
  mouseOver?: (() => void) | null
  mouseOut?: (() => void) | null
  size?: number
  color: string
  borderColor?: string
  borderWidth?: number
  type: string
}

export const renderPointWithRadio = ({
  map,
  object,
  config,
  event,
  rightClick,
  mouseOver,
  mouseOut,
  size = 8,
  color = '#1da023',
  borderColor = '#ffffff',
  borderWidth = 3,
  type = 'circleMarker'
}: renderPointWithRadioI) => {
  const style = new CircleMarkerStyle(size, borderWidth, borderColor, color, 1)
  let options = new CircleMarkerOptions(
    [Number(object.latitude), Number(object.longitude)],
    style
  )
  options.object = {
    ...object,
    uuid: object.uuid,
    groupUuid: object?.groupUuid
  }
  options.addToMap = true
  options.fitBounds = false
  options = {
    ...options,
    ...config
  }

  map?.drawCircleMarker(type, options, event)
  map?.addMarkerEvent(
    type,
    MarkerEventType.RightClick,
    rightClick,
    (obj: ObjectMarker) => obj.uuid === object.uuid
  )
  map?.addMarkerEvent(
    type,
    MarkerEventType.MouseOver,
    mouseOver,
    (obj: ObjectMarker) => obj.uuid === object.uuid
  )
  map?.addMarkerEvent(
    type,
    MarkerEventType.MouseOut,
    mouseOut,
    (obj: ObjectMarker) => obj.uuid === object.uuid
  )
}

export const RenderOverlay = (
  map: Map | null,
  html: HTMLDivElement,
  latlng: number[],
  type = 'overlay'
) => {
  const options = new OverlayOptions(html, true, latlng)
  map?.drawOverlay(type, options)
}

export const RemoveAllOverlay = (map: Map | null, type = 'overlay') => {
  map?.removeOverlays(type)
}

export const AddMapEventClick = (
  map: Map | null,
  event: (e: { latlng: number[] }) => void
) => {
  map?.addEventMap(MapEventType.Click, event)
}

export const RemoveMapEventClick = (map: Map | null) => {
  map?.removeEventMap(MapEventType.Click)
}

export const AddMapEventZoomChangeMap = (
  map: Map | null,
  event: (e: any) => void
) => {
  map?.addEventMap(MapEventType.ZoomChanged, event)
}
