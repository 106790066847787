import { colors } from './../../assets/variables'
import styled from 'styled-components'

export const ResizeGrid = styled.div`
  position: absolute;
  height: 25px;
  width: 150px;
  left: calc(50% - 75px);
  top: -25px;
  z-index: 99;
  background-color: white;
  border: 1px solid ${colors.borderColor};
  border-bottom: transparent;
  border-radius: 0.3rem 0.3rem 0 0;
  overflow: hidden;
  display: flex;
`

export const ResizeButton = styled.button`
  height: 25px;
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    color: ${colors.grayLight};
    height: 20px;
    width: 20px;
  }
  &:nth-child(1) {
    border-right: 1px solid ${colors.borderColor};
  }
  &:hover {
    background-color: ${colors.grayPastel};
  }
  &:disabled {
    cursor: not-allowed;
    svg {
      color: ${colors.gray100};
    }
    &:hover {
      background-color: transparent;
    }
  }
`
