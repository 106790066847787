import { useEffect, useState } from 'react'

import { Box, Typography } from '@mui/material'
import { ErrorOutline } from '@mui/icons-material'
import { Button, ModalBase, Grid } from '../index'
import { GridColDef } from '@mui/x-data-grid'
import * as XLSX from 'xlsx'
import * as FileSaver from 'file-saver'

interface OverlapModalProps {
  open: boolean
  close: () => void
  overlap?: boolean
  readDetails?: boolean
  isAlertMessage?: boolean
  quantityImport?: number
  messageAlert?: string
  rows: any[] | []
  columns?: GridColDef<any, any, any>[]
  continueImport?: (selectedIds: any[], overlap: boolean) => void
}

const ExceptionModal = ({
  open,
  close,
  overlap = false,
  readDetails = false,
  isAlertMessage = false,
  messageAlert,
  quantityImport,
  rows,
  columns,
  continueImport
}: OverlapModalProps) => {
  const [loading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedIds, setSelectedIds] = useState<any[]>([])

  const handleDownloadExcel = () => {
    const exportColumns = columns?.map((item) => item.field)
    const exportRows = rows.map((row) => {
      let currentRow = {}
      exportColumns?.map((column) => {
        currentRow = { ...currentRow, [column]: row[column] }
      })
      return currentRow
    })

    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    const ws = XLSX.utils.json_to_sheet(exportRows)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(
      data,
      location.pathname.split('/')[1] + '-exception' + fileExtension
    )
  }

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  return (
    <ModalBase
      title={readDetails ? 'Detalhes da excessão' : 'Excessão'}
      titleIsCenter
      open={isOpen}
      toggle={() => {
        setIsOpen(false)
        close()
      }}
      fullWidth
      maxWidth={isAlertMessage ? 'md' : 'xl'}
      footer={
        <Box
          display="flex"
          justifyContent="flex-end"
          gap={1}
          width="100%"
          marginX={2}
          marginY={2}
        >
          {readDetails || !overlap ? (
            <Box display="flex" flexDirection="row" gap={2}>
              <Button
                color="info"
                backgroundColor="primaryPastel"
                onClick={handleDownloadExcel}
              >
                Baixar excel
              </Button>
              <Button
                color="info"
                backgroundColor="primaryPastel"
                onClick={() => {
                  setIsOpen(false)
                  if (!overlap) {
                    close()
                  }
                  close()
                }}
              >
                {isAlertMessage ? 'Entendi' : 'Voltar'}
              </Button>
            </Box>
          ) : (
            <Box display="flex" flexDirection="row" gap={2}>
              <Button
                fullWidth
                color="info"
                backgroundColor="primaryPastel"
                sx={{
                  marginX: 2,
                  marginTop: 0.5
                }}
                onClick={handleDownloadExcel}
              >
                Baixar excel
              </Button>
              <Button
                color="gray"
                backgroundColor="gray100"
                onClick={() => {
                  setIsOpen(false)
                  close()
                }}
              >
                Cancelar
              </Button>
              <Button
                disabled={loading}
                color="info"
                backgroundColor="primaryPastel"
                onClick={() => {
                  setLoading(true)
                  continueImport
                    ? continueImport(
                        selectedIds.length === 0
                          ? rows.map((item) => item.id)
                          : selectedIds,
                        true
                      )
                    : null
                }}
                sx={{
                  paddingX: 2,
                  paddingY: 2
                }}
                fullWidth
              >
                {overlap &&
                rows?.filter((row) => row?.mensagem).length === quantityImport
                  ? 'Importar e substituir'
                  : selectedIds.length === 0
                  ? 'Importar sem substituir'
                  : 'Importar e substituir'}
              </Button>
            </Box>
          )}
        </Box>
      }
    >
      <Box>
        {readDetails ? (
          <></>
        ) : (
          <Box mb={3}>
            {overlap && quantityImport ? (
              <Typography className="d-flex align-items-start mb-3">
                <ErrorOutline color={'warning'} className="me-3" />
                {`Você está tentando importar ${quantityImport} itens, mas ${
                  rows?.filter((row) => row?.mensagem).length
                } deles já estão cadastrados. \n`}
                {`Para concluir a importação, selecione os itens que deseja substituir ou clique em "Importar sem substituir" para importar os demais itens.`}
              </Typography>
            ) : (
              <Typography className="d-flex align-items-start mb-3">
                <ErrorOutline color={'warning'} className="me-3" />
                {`Você está tentando importar ${quantityImport} itens, mas ${
                  rows?.length ?? 0
                } deles são inválidos. \n`}
                {`Importação cancelada após identificar inconsistência de dados! Verifique os registros inválidos e localize um novo arquivo para importação.`}
              </Typography>
            )}
          </Box>
        )}

        {isAlertMessage && (
          <Box justifyContent="center">
            <Typography className="d-flex align-items-center mb-3">
              <ErrorOutline color={'warning'} className="me-3" />
              {messageAlert}
            </Typography>
          </Box>
        )}

        {columns && (
          <Grid
            onlyGrid
            autoHeight
            archiveColumn={false}
            checkboxSelection={overlap}
            disableSelectionOnClick
            pageSize={10}
            paginationMode="client"
            onSelectionModelChange={(rowId) => setSelectedIds(rowId)}
            selectionModel={selectedIds}
            columns={columns ?? []}
            rows={rows ?? []}
            sortingMode="client"
            initialState={{
              sorting: {
                sortModel: [{ field: 'identificadorSetor', sort: 'desc' }]
              }
            }}
          />
        )}
      </Box>
    </ModalBase>
  )
}
export default ExceptionModal
