import { maxDateByDates } from '../../../utils/getDateByArrayDates'
import { utcToLocal } from '../../../utils/utcDateTime'
import { v4 as uuidv4 } from 'uuid'
import formatTime from './formatTime'
import { mapHooks } from '../../../map'
import { Map } from '@inlog/inlog-maps/lib'
import { SweepOverviewPoint } from '../../../types/SweepOverview'
import { sortByDate } from '../../../utils/sortByDate'

export const clearMap = (map: Map | null) => {
  mapHooks.removeAllMarkers(map)
  mapHooks.RemoveMapEventClick(map)
  mapHooks.RemoveAllOverlay(map)
}

interface createElementI {
  dateTime: string | number | Date
  averageSpeed: number
  timeBetweenPoints: number
}
export function createElement({
  averageSpeed,
  dateTime,
  timeBetweenPoints
}: createElementI) {
  const element = document.createElement('div')
  element.innerHTML = `\n${utcToLocal(
    new Date(dateTime),
    'HH:mm:ss'
  )}\n${averageSpeed.toFixed(2).replace('.', ',')} / ${formatTime(
    timeBetweenPoints
  )}`
  element.style.cssText =
    'font-size: 12px;font-weight: 600; letter-spacing: 1px;color: black;height: 30px; width: 120px;text-align: center;display: flex;justify-content: center;align-items: center;transform: translateX(-60px) translateY(-45px); white-space: pre-line; line-height: 1'
  element.style.position = 'absolute'

  return element
}

export function filterPoints(
  tracker: any,
  currentRangeTime: any,
  rangeTimeSorted: any,
  isImpersonate: any,
  onlyPointOfMoving: any,
  onlyPointOfStoped: any,
  specialService?: boolean
) {
  const allPoints: any[] = []
  tracker.pontosHistorico.sort(sortByDate)
  tracker.pontosHistorico.forEach((point: SweepOverviewPoint, index: any) => {
    const date = new Date(point.dataHora)
    point.raio = point.raio === null ? 0.01 : point.raio
    const startFilterDate = rangeTimeSorted[currentRangeTime[0]].date
    let endFilterDate = rangeTimeSorted[currentRangeTime[1]].date

    if (!specialService) {
      const datesRoadsnap = tracker.pontosHistorico
        .filter(
          (item: { latitudeRoadSnap: any; longitudeRoadSnap: any }) =>
            item.latitudeRoadSnap && item.longitudeRoadSnap
        )
        .map(
          (item: { dataHora: string | number | Date }) =>
            new Date(item.dataHora)
        )
      const maxDateRoadsnap = maxDateByDates(datesRoadsnap)
      if (endFilterDate > maxDateRoadsnap && !isImpersonate) {
        endFilterDate = maxDateRoadsnap
      }
    }
    if (date >= startFilterDate && date <= endFilterDate) {
      if (specialService) {
        const uuid = uuidv4()
        const object = {
          index,
          tracker: tracker.label,
          time: utcToLocal(new Date(point.dataHora), 'HH:mm:ss'),
          stopedTime: formatTime(point.tempoParado),
          timeBetweenPoints: point.tempoParado,
          averageSpeed: point.velocidadeMedia,
          dateTime: point.dataHora,
          radius: point.raio ?? 0,
          color: tracker.color,
          uuid,
          groupUuid: `${('0000' + String(point.codigoRastreador)).slice(-4)}`,
          latitude: point.latitude,
          longitude: point.longitude
        }
        const element = createElement({
          averageSpeed: point.velocidadeMedia,
          dateTime: point.dataHora,
          timeBetweenPoints: point.tempoParado
        })
        allPoints.push({
          object,
          element,
          tracker,
          point,
          latitudePlot: point.latitude,
          longitudePlot: point.longitude
        })
      } else {
        const latitudePlot =
          tracker.isRoadSnap &&
          point.latitudeRoadSnap &&
          point.longitudeRoadSnap
            ? point.latitudeRoadSnap ?? null
            : point.latitude
        const longitudePlot =
          tracker.isRoadSnap &&
          point.latitudeRoadSnap &&
          point.longitudeRoadSnap
            ? point.longitudeRoadSnap ?? null
            : point.longitude

        if (latitudePlot && longitudePlot) {
          if (
            (onlyPointOfMoving && onlyPointOfStoped) ||
            (onlyPointOfMoving && point.tipoPonto === 'InicioDeDeslocamento') ||
            (onlyPointOfStoped && point.tipoPonto === 'PontoDeParada')
          ) {
            const uuid = uuidv4()
            const object = {
              index,
              tracker: tracker.label,
              time: utcToLocal(new Date(point.dataHora), 'HH:mm:ss'),
              stopedTime: formatTime(point.tempoParado),
              timeBetweenPoints: point.tempoParado,
              averageSpeed: point.velocidadeMedia,
              dateTime: point.dataHora,
              radius: point.raio ?? 0,
              color: tracker.color,
              uuid,
              groupUuid: `${('0000' + String(point.codigoRastreador)).slice(
                -4
              )}`,
              latitude: latitudePlot,
              longitude: longitudePlot
            }
            const element = createElement({
              averageSpeed: point.velocidadeMedia,
              dateTime: point.dataHora,
              timeBetweenPoints: point.tempoParado
            })
            allPoints.push({
              object,
              element,
              tracker,
              point,
              latitudePlot,
              longitudePlot
            })
          }
        }
      }
    }
  })
  return allPoints
}
