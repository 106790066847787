import { Option } from '../../types/Option'
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack'
import { listAllSpecialServices } from '../../app/api/specialServices'
import formatSpecialServiceShift from '../formatSpecialServiceShift'

interface readSpecialServiceI {
  contractId: number | null | undefined
  paginationSpecialService: number
  searchSpecialService: string
  setLoadingSpecialService: (loading: boolean) => void
  enqueueSnackbar?: (
    message: SnackbarMessage,
    options?: OptionsObject | undefined
  ) => SnackbarKey
}

const readSpecialServices = async ({
  contractId,
  paginationSpecialService,
  searchSpecialService,
  setLoadingSpecialService,
  enqueueSnackbar
}: readSpecialServiceI) => {
  if (contractId) {
    try {
      setLoadingSpecialService(true)

      const response = await listAllSpecialServices({
        codigoContrato: contractId,
        take: 30,
        skip: 30 * paginationSpecialService,
        arquived: false,
        query: searchSpecialService
      })

      let specialServiceList = [] as Option[]

      if (response.success && response.data) {
        response.data.map((item) => {
          const { startTimeFormat, endTimeFormat } = formatSpecialServiceShift(
            item.horaInicioTurno,
            item.horaFimTurno
          )
          if (
            !specialServiceList
              .map((shift: Option) => shift.label)
              .includes(
                `${item.descricao} (de ${startTimeFormat} hrs até ${endTimeFormat} hrs)`
              )
          ) {
            specialServiceList = [
              ...specialServiceList,
              {
                value: item.codigo,
                label: `${item.descricao} (de ${startTimeFormat} hrs até ${endTimeFormat} hrs)`
              }
            ]
          }
        })
        setLoadingSpecialService(false)
        return {
          specialServiceList,
          totalSpecialService: response.count!
        }
      }

      enqueueSnackbar &&
        enqueueSnackbar(
          response.message ??
            'Ocorreu um erro interno. Comunique o administrador do sistema!',
          {
            variant: 'error'
          }
        )
    } catch (error) {
      enqueueSnackbar &&
        enqueueSnackbar(
          'Ocorreu um erro interno. Comunique o administrador do sistema!',
          {
            variant: 'error'
          }
        )
      return null
    }
  }
  return null
}

export default readSpecialServices
